import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProductOutputFunctionIds";
const FetchRequestType = "GAAProductOutputFunctionIdFetchRequest";

export const GetProductOutputFunctionId = async (Ref) => {
  let payload = { GAAProductOutputFunctionIdRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveProductOutputFunctionId = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const DeleteProductOutputFunctionId = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetProductOutputFunctionIdList = async () => {
  let payload = { GAAProductOutputFunctionIdRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Value - b.Value);
  return result;
};
