
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider'
import { CloseCircle } from 'iconsax-react'

function CancelButton({ to, onClick, title, className, state }) {
    const { theme } = useStateContext()

    return (
        <Fragment>
            <Link to={to} id='cancel' name='cancel' state={state}  className={(theme ? 'text-default-color' : 'text-orange-500') + " group relative flex justify-center rounded-md bg-none border-2 border-orange-500 w-[12vw] p-2 tracking-wider transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color " + (className)} title={title} onClick={onClick}>
                <span className="absolute inset-y-0 left-0 flex items-center pl-3  "><CloseCircle variant="Bulk" className="h-5 w-5" /></span>
                <span id='cancel' name='cancel' className='ml-5'>Cancel</span>
            </Link>
        </Fragment>
    )
}

export default CancelButton