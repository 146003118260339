import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProjectKeyContacts";
const FetchRequestType = "GAAProjectKeyContactFetchRequest";

// Save All Data To Database //
export const SaveHotelKeyContact = async (ref, payload, images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

// Get All Data From Database //
export const GetHotelKeyContactList = async () => {
  let payload = {GAAProjectKeyContactRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

// Get Single Data From Database //
export const GetHotelKeyContact = async (Ref) => {
  let payload = {GAAProjectKeyContactRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteHotelKeyContact  = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetProjectKeyContactListByProject = async (projectRef) => {
  let payload = { GAAProjectRefs: [projectRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};
