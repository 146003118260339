import React, { Fragment } from 'react'
import { useStateContext } from '../../context/ContextProvider'

function Button({ children, className, title, onClick }) {
    const { theme } = useStateContext()
    return (
        <Fragment>
            <button type="submit" className={(theme ? 'text-gray-800' : 'text-default-color') + "group relative flex justify-center rounded-md bg-gradient-to-r w-[12vw] mt-6 from-[#F42B03] to-[#FFBE0B] hover:bg-gradient-to-bl p-2 tracking-wider font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 " + (className)} title={title} onClick={onClick}>
                {children}
            </button>
        </Fragment>
    )
}

export default Button