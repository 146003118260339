import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProductOutputFunctionTypes";
const FetchRequestType = "GAAProductOutputFunctionTypeFetchRequest";

export const GetGAAProductOutputFunctionType = async (Ref) => {
  let payload = { GAAProductOutputFunctionTypeRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGAAProductOutputFunctionType = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const DeleteGAAProductOutputFunctionType = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProductOutputFunctionTypeList = async () => {
  let payload = { GAAProductOutputFunctionTypeRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Value - b.Value);
  return result;
};
