import React, { Fragment, useEffect, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import { GetGAAProjectDepartmentList, GetGAAProjectDepartmentListByProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectDepartment";
import { DeleteGAAProjectJobTitle, GetGAAProjectJobTitleListByDepartment } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectJobTitle";
import { GetGAAProjectListByCustomer } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../../context/ContextProvider";

function GAAProjectJobTitle({ Rights }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Projects Job Title";
  const tableHeading = ["Sr.No", "Job Title Name", "Action"];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [customerList, setCustomerList] = useState([])
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([])
  const [GAAProjectJobTitleList, setGAAProjectJobTileList] = useState([]);
  const { CustomerRef,  GAAProjectRef, GAADepartmentRef, handleProjectRef,handleCustomerRef ,handleDepartmentRef} = useStateContext();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  }

  //Fetch Project List for Dropdowm By Customer//
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef && setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  const fetchDepartmentList = async () => {
    GAAProjectRef && setDepartmentList(await GetGAAProjectDepartmentListByProject(GAAProjectRef));
  }

  // fetch Job Title List According to Department Ref //
  const fetchJobTitleList = async () => {
    GAADepartmentRef &&
      setGAAProjectJobTileList(await GetGAAProjectJobTitleListByDepartment(GAADepartmentRef));
  };
  const deleteProjectJobTitle = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectJobTitle(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchJobTitleList();
        }
      }
    })
  }

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == '' && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  // Set Zero Index Project Name For Dropdown //
  useEffect(() => {
    if (GAAProjectRef == '' && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchDepartmentList();
  }, [GAAProjectRef])

  useEffect(() => {
    if (GAADepartmentRef == '' && DepartmentList.length > 0) {
      handleDepartmentRef(DepartmentList[0].Ref);
    }
  }, [DepartmentList]);

  useEffect(() => {
    fetchJobTitleList(); // Sort Job Title Table using GAADepartmentRef
  }, [GAADepartmentRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Project Job Titles"}
          button={
            <LinkButton to={"/project-job-title-master/add-project-job-title-master"} className={Rights.Add ? "opacity-100" : "hidden"} state={GAADepartmentRef}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Project Job Title</span>
            </LinkButton>
          }
        >
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
            <div>
              <Label htmlFor={'CustomerRef'} labelText={'Customer'} />
              <CustomSelect id={'CustomerRef'} name={'CustomerRef'} value={CustomerRef} onChange={(e)=> handleCustomerRef(e.target.value)}>
                <Option value={''}>-- Select --</Option>
                {
                  customerList && customerList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    )
                  })
                }
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={'GAAProjectRef'} labelText={'Project'} />
              <CustomSelect id={'GAAProjectRef'} name={'GAAProjectRef'} value={GAAProjectRef} onChange={(e)=> handleProjectRef(e.target.value)}>
                <Option value={''}>-- Select --</Option>
                {
                  GAAProjectList && GAAProjectList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    )
                  })
                }
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={'DepartmentRef'} labelText={'Department'} />
              <CustomSelect id={'DepartmentRef'} name={'DepartmentRef'} value={GAADepartmentRef} onChange={(e) => handleDepartmentRef(e.target.value)}>
                <Option value={''}>-- Select --</Option>
                {
                  DepartmentList && DepartmentList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    )
                  })
                }
              </CustomSelect>
            </div>
          </div>
          {GAAProjectJobTitleList && GAAProjectJobTitleList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {GAAProjectJobTitleList &&
                  GAAProjectJobTitleList.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  ).map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/project-job-title-master/edit-project-job-title-master/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton onClick={() => deleteProjectJobTitle(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Job Title Data Not Found"} />
          )}
          {GAAProjectJobTitleList && GAAProjectJobTitleList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={GAAProjectJobTitleList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectJobTitle;
