import React, { Fragment, useEffect, useState } from "react";
import flasher from "@flasher/flasher";
import { useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import Label from "../../helpers/Label";
import Card from "../../helpers/Card";
import Input from "../../helpers/Input";
import LinkButton from "../../helpers/LinkButton";
import Loader from "../../Common/Loader";
import Validation from "../../helpers/Validation";
import CustomSelect from "../../helpers/CustomSelect";
import { ArrowCircleLeft2 } from "iconsax-react";
import CancelButton from "../../helpers/CancelButton";
import SaveButton from "../../helpers/SaveButton";
import { GetGladianceJobTitle, SaveGladianceJobTitle } from "../../../Encapsulation/GladianceDomainEntities/GladianceJobTitle";
import { GetGladianceDepartmentList } from "../../../Encapsulation/GladianceDomainEntities/GladianceDepartment";
import { ClearInfo } from '../../../Encapsulation/Utils'
import Option from "../../helpers/Option";
function GladianceJobtitleMasterAddEdit() {
  let formMode = 'New';
  let { id } = useParams();

  if (id) {
    document.title = 'Gladiance - Edit Job Title';
    formMode = 'Edit'
  } else {
    document.title = 'Gladiance - Add Job Title'
  }

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const [formInfo, setFormInfo] = useState({});
  const [gladianceDepartmentMasterList, setGladianceDepartmentMasterList] =
    useState([]);

  const getDepartmentList = async () => {
    setGladianceDepartmentMasterList(await GetGladianceDepartmentList(setErrors));
  }

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGladianceJobTitle(id, formInfo);
    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      if (formMode == 'Edit') {
        Navigation('/gladiance-job-title-master');
      }
      else {
        setFormInfo(ClearInfo(formInfo))
      }
    }
  };

  const fetchJobTitle = async () => {
    let JobTitle = await GetGladianceJobTitle(id);
    setFormInfo(JobTitle);
  }

  useEffect(() => {
    if (id) {
      fetchJobTitle();
    }
    getDepartmentList();
  }, [])

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit Job Title" : "Add Job Title"}
          button={
            <LinkButton to={"/gladiance-job-title-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex">
            <Card className={"w-full  px-4 py-4 "}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 ">
                  <div className="mb-3 ">
                    <Label
                      htmlFor={"GladianceDepartmentRef"}
                      labelText={"Gladiance Department"}
                    />
                    <CustomSelect
                      inputFocus={true}
                      id={"GladianceDepartmentRef"}
                      name={"GladianceDepartmentRef"}
                      value={formInfo.GladianceDepartmentRef}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option
                        value={""}
                      >
                        --- Select Department ---
                      </Option>
                      {gladianceDepartmentMasterList.map((data, id) => {
                        return (
                          <Fragment key={id}>
                            <Option
                              value={data.Ref}
                            >
                              {data.Name}
                            </Option>
                          </Fragment>
                        );
                      })}
                    </CustomSelect>
                    <Validation error={errors.GladianceDepartmentRef} />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor={"Name"} labelText={"Job Title Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Name} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/gladiance-job-title-master"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GladianceJobtitleMasterAddEdit;
