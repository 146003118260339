import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProductOutputDriverChannels";
const FetchRequestType = "GAAProductOutputDriverChannelFetchRequest";

export const GetGAAProductOutputDriverChannel = async (Ref) => {
  let payload = { GAAProductOutputDriverChannelRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};
 
export const SaveGAAProductOutputDriverChannel = async (
  ref,
  payload,
  images
) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

export const GetGAAProductOutputDriverChannelList = async () => {
  let payload = { GAAProductOutputDriverChannelRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Ref - b.Ref);
  return result;
};

export const DeleteGAAProductOutputDriverChannel = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProductOutputDriverChannelListByProduct = async (GAAProductRef) => {
    let payload = { GAAProductRefs: [GAAProductRef] };
    let td = await FetchFromServer(payload, FetchRequestType, []);
    let result = GetMasterTableEntries(td, MasterTableName);
    result = result.sort((a, b) => a.Ref - b.Ref);
    return result;
  };
  
