import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import Label from "../../helpers/Label";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProjectList } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { DeleteGAADoorCameraMaster, GetGAADoorCameraMasterListByArea, GetGAADoorCameraMasterListByProject, GetGAADoorCameraMasterListBySpace, GetGAADoorCameraMasterListBySpaceType } from "../../../Encapsulation/HotelDomainEntities/HotelDoorCamera";
import { GetSpaceGroupListByProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup";
import { GetGAAProjectSpaceListBySpaceGroup } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpace";
import { GetGAAProjectSpaceTypeListByProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { GetGAAProjectSpaceTypeAreaListBySpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function DoorCamera() {
    document.title = "Gladiance - DoorCamera";
    const tableHeading = [
        "Sr.No",
        "Name",
        "IP Address",
        "ONVIF Port",
        "Camera UserName",
        "Camera Password",
        "Action",
    ];
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [DoorCameraList, setDoorCameraList] = useState([]);
    const [GAAProjectRef, setGAAProjectRef] = useState();
    const [projectList, setProjectList] = useState([]);
    const [ProjectSpaceGroupList, setProjectSpaceGroupList] = useState([]);
    const [spaceGroupRef, setSpaceGroupRef] = useState();
    const [ProjectspaceList, setProjectSpaceList] = useState([]);
    const [ProjectSpaceRef, setProjectSpaceRef] = useState();
    const itemsPerPage = 7;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Fetch Project List For Dropdown //
    const fetchProjectList = async () => {
        setProjectList(await GetGAAProjectList());
    };

    // fetch Space Group List According to Project for Dropdown //
    const fetchProjectSpaceGroupList = async () => {
        GAAProjectRef && setProjectSpaceGroupList(await GetSpaceGroupListByProject(GAAProjectRef));
    };

    // fetch Space List By  Space Group for Dropdown //
    const fetchProjectSpaceList = async () => {
        GAAProjectRef && setProjectSpaceList(await GetGAAProjectSpaceListBySpaceGroup(spaceGroupRef));
    };

    const fetchDoorCameraListBySpace = async () => {
        ProjectSpaceRef && setDoorCameraList(await GetGAADoorCameraMasterListBySpace(ProjectSpaceRef))
      }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const deleteDoorCamera = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteGAADoorCameraMaster(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchDoorCameraListBySpace();
                }
            }
        })
    }

    useEffect(() => {
        fetchProjectList();
    }, []);

    useEffect(() => {
        fetchProjectSpaceGroupList();
    }, [GAAProjectRef]);

    useEffect(() => {
        fetchProjectSpaceList();
    }, [spaceGroupRef]);


    useEffect(() => {
        fetchDoorCameraListBySpace();
      }, [ProjectSpaceRef]);

      useEffect(() => {
        if (projectList.length > 0) {
            setGAAProjectRef(projectList[0].Ref);
        }
    }, [projectList]);

    useEffect(() => {
        if (ProjectSpaceGroupList.length > 0) {
            setSpaceGroupRef(ProjectSpaceGroupList[0].Ref);
        }
    }, [ProjectSpaceGroupList]);

    useEffect(() => {
        if (ProjectspaceList.length > 0) {
            setProjectSpaceRef(ProjectspaceList[0].Ref);
        }
    }, [ProjectspaceList]);

    return (
        <Fragment>
            {loader ? (
                <Loader />
            ) : (
                <AddComponent
                    title={"Door Camera List"}
                    button={
                        <LinkButton to={"/door-camera/add-door-camera"} state={{ GAAProjectRef: GAAProjectRef, spaceGroupRef: spaceGroupRef, ProjectSpaceRef: ProjectSpaceRef }}>
                            <AddCircle variant="Bold" className="w-5 h-5" />
                            <span>Add Door Camera</span>
                        </LinkButton>
                    }
                >
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <Label htmlFor={"GAAProjectRef"} labelText={"Project"} />
                            <CustomSelect
                                id={"GAAProjectRef"}
                                name={"GAAProjectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => setGAAProjectRef(e.target.value)}
                                required={true}
                            >
                                {projectList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option value={data.Ref}>{data.Name}</Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"Space Group"}
                                labelText={" Project Space Group"}
                            />
                            <CustomSelect
                                id={"spaceGroupRef"}
                                name={"spaceGroupRef"}
                                value={spaceGroupRef}
                                onChange={(e) => setSpaceGroupRef(e.target.value)}
                                required={true}
                            >
                                {ProjectSpaceGroupList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"Project Space"}
                                labelText={" Project Space "}
                            />
                            <CustomSelect
                                id={"ProjectSpaceRef"}
                                name={"ProjectSpaceRef"}
                                value={ProjectSpaceRef}
                                onChange={(e) => setProjectSpaceRef(e.target.value)}
                                required={true}
                            >
                                {ProjectspaceList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                        {/* <div>
                            <Label
                                htmlFor={"GAAProjectSpaceTypeRef"}
                                labelText={" Space Type"}
                            />
                            <CustomSelect
                                id={"GAAProjectSpaceTypeRef"}
                                name={"GAAProjectSpaceTypeRef"}
                                value={GAAProjectSpaceTypeRef}
                                onChange={(e) => setGAAProjectSpaceTypeRef(e.target.value)}
                                required={true}
                            >
                                {GAAProjectSpaceTypeList && GAAProjectSpaceTypeList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"GAAProjectSpaceTypeAreaRef"}
                                labelText={" Space Type Area"}
                            />
                            <CustomSelect
                                id={"GAAProjectSpaceTypeAreaRef"}
                                name={"GAAProjectSpaceTypeAreaRef"}
                                value={GAAProjectSpaceTypeAreaRef}
                                onChange={(e) => setGAAProjectSpaceTypeAreaRef(e.target.value)}
                                required={true}
                            >
                                {GAASpaceTypeAreaList && GAASpaceTypeAreaList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div> */}
                    </div>
                    {DoorCameraList && DoorCameraList.length > 0 ? (
                        <div className="m-2">
                            <Table tableHeading={tableHeading}>
                                {DoorCameraList
                                    .slice(
                                        (currentPage - 1) * itemsPerPage,
                                        currentPage * itemsPerPage
                                    )
                                    .map((tableData, tableDataID) => {
                                        return (
                                            <Fragment key={tableDataID}>
                                                <tr className="border-b border-opacity-30 border-white text-center">
                                                    <td className="px-4 py-3">{tableDataID + 1}</td>
                                                    <td className="px-4 py-3">{tableData.Name}</td>
                                                    <td className="px-4 py-3">{tableData.IPAddress}</td>
                                                    <td className="px-4 py-3">{tableData.ONVIFPort}</td>
                                                    <td className="px-4 py-3">{tableData.CameraUserName}</td>
                                                    <td className="px-4 py-3">{tableData.CameraPassword}</td>
                                                    <td className="px-4 py-3">
                                                        <div className="flex justify-center gap-3">
                                                            <Link
                                                                to={`/door-camera/edit-door-camera/${tableData.Ref}`}
                                                                title="Edit"
                                                            >
                                                                <ActionButton
                                                                    className={"p-1.5 text-white bg-yellow-400 "}
                                                                >
                                                                    <PencilSquareIcon className="w-4 h-4" />
                                                                </ActionButton>
                                                            </Link>
                                                            <ActionButton onClick={() => deleteDoorCamera(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                <TrashIcon className='w-4 h-4' />
                                                            </ActionButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                            </Table>
                        </div>
                    ) : (
                        <DataNotFound labelText={"Sorry! DoorCamera Data Not Found"} />
                    )}
                    {DoorCameraList && DoorCameraList.length > 7 ? (
                        <div className="flex justify-end">
                            <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={DoorCameraList.length}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </AddComponent>
            )}
        </Fragment>
    );
}

export default DoorCamera;
