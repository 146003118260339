import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import {
  DeleteGAAProjectUserRole,
  GetGAAProjectUserRoleListByProject,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectUserRole ";
import {
  GetGAAProjectList,
  GetGAAProjectListByCustomer,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";

function GAAProjectUserRole({ Rights }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Project User Role";
  const tableHeading = ["Sr.No", "User Role Name", "Action"];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [ProjectUserRoleList, setProjectUserRoleList] = useState([]);
  const [CustomerList, setCustomerList] = useState([]);

  const { CustomerRef, GAAProjectRef, handleProjectRef, handleCustomerRef } =
    useStateContext();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // fetch User Role List According to Project Ref //
  const fetchProjectUserRoleListByProject = async () => {
    GAAProjectRef &&
      setProjectUserRoleList(
        await GetGAAProjectUserRoleListByProject(GAAProjectRef)
      );
  };

  const deleteProjectUserRole = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectUserRole(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchProjectUserRoleListByProject();
        }
      }
    });
  };

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  //Fetch Project List for Dropdowm By Customer//
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef &&
      setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };
  useEffect(() => {
    fetchProjectUserRoleListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && CustomerList.length > 0) {
      handleCustomerRef(CustomerList[0].Ref);
    }
  }, [CustomerList]);

  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  //  Set Zero Index Project Name For Dropdown //
  useEffect(() => {
    if (GAAProjectRef == "" && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"User Roles"}
          button={
            <LinkButton
              to={"/project-user-role-master/add-project-user-role-master"}
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={GAAProjectRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add User Role</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                <Option value={""}>-- Select --</Option>
                {CustomerList &&
                  CustomerList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={"GAAProjectRef"} labelText={"Project"} />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
              >
                <Option value={""}>-- Select --</Option>
                {GAAProjectList &&
                  GAAProjectList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
          </div>
          {ProjectUserRoleList && ProjectUserRoleList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {ProjectUserRoleList &&
                  ProjectUserRoleList.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  ).map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/project-user-role-master/edit-project-user-role-master/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton
                                  onClick={() =>
                                    deleteProjectUserRole(tableData)
                                  }
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! User Role Data Not Found"} />
          )}
          {ProjectUserRoleList && ProjectUserRoleList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={ProjectUserRoleList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectUserRole;
