export function ClearInfo(Info) {
  let blankInfo = {};
  for (let k in Info) {
    blankInfo[k] = "";
  }
  return blankInfo;
}

export function FilterAction(tableHeading, Rights) {
  return tableHeading.filter((data) => (Rights.View == false ? data != 'Action' : data));
}



export const ProjectTypeList = [
  {
    Value: 1,
    Name: "Hotel",
  },
  {
    Value: 2,
    Name: "Home",
  },
  {
    Value: 3,
    Name: "Hospital",
  },
  {
    Value: 4,
    Name: "Office",
  },
  {
    Value: 500,
    Name: "Other",
  },
];

export const TemperatureConventionList = [
  {
    Value: 1,
    Name: "Centigrade",
  },
  {
    Value: 2,
    Name: "Fahrenhite",
  },
];
export const TimeConventionList = [
  {
    Value: 1,
    Name: "12 Hours",
  },
  {
    Value: 2,
    Name: "24 Hours",
  },
];

export const ProductOperationModesList = [
  {
    Value: 10,
    Name: "Input",
  },
  {
    Value: 20,
    Name: "Output",
  },
  {
    Value: 30,
    Name: "Input and Output ",
  },
  {
    Value: 40,
    Name: "Trigger ",
  },
];

export const FirmwareStatusList = [
  {
    Value: 1,
    Name: "Active",
  },
  {
    Value: 100,
    Name: "Discontinued",
  },
];

export const TelevisionOperatingSystem = [
  {
    Value: 1,
    Name: "Android",
  },
];

export const RBItemCategoryList = [
  {
    Value: 10,
    Name: "veg",
  },
  {
    Value: 20,
    Name: "Non-Veg",
  },
  {
    Value: 30,
    Name: "Hot Beverages",
  },
  {
    Value: 40,
    Name: "Cold Beverages",
  },
  {
    Value: 50,
    Name: "Mocktails",
  },
  {
    Value: 60,
    Name: "Ice-Creams",
  },
  {
    Value: 70,
    Name: "Liquors",
  },
  {
    Value: 80,
    Name: "Cocktails",
  },
  {
    Value: 500,
    Name: "Other",
  },
];

export const RBItemTasteList = [
  {
    Value: 10,
    Name: "Spicy",
  },
  {
    Value: 20,
    Name: "Sweet",
  },
  {
    Value: 500,
    Name: "Other",
  },
];

export const HardwareTypes = [
  {
    Value: 0,
    Name: "None",
  },
  {
    Value: 10,
    Name: "ESP32",
  },
  {
    Value: 20,
    Name: "ESP32S2",
  },
  {
    Value: 30,
    Name: "ESP32C3",
  },
  {
    Value: 40,
    Name: "nRF52",
  },
]
