import React, { Fragment } from 'react'

function CheckBox({ id, name, type, className, disabled, onClick, checked }) {
    return (
        <Fragment>
            <input id={id} name={name} type={type} className={"h-5 w-5 rounded border-gray-500 text-[#F42B03] bg-gradient-to-r from-[#F42B03] to-[#FFBE0B] focus:ring-[#ff6d0b] " + (className)} disabled={disabled} onClick={onClick} defaultChecked={checked} />
        </Fragment>
    )
}

export default CheckBox