import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GladianceUserRights";
const FetchRequestType = "GladianceUserRightFetchRequest";

export const SaveGladianceUserRights = async (payload) => {
  return await SaveToServer(payload, MasterTableName, [], true);
};

export const GetGladianceUserRightsList = async () => {
  let payload = { UserRoleRefs: [], ActivityRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  return GetMasterTableEntries(td, MasterTableName);
};
