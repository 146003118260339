import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link, useNavigate } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import Button from "../../../helpers/Button";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import { GetGAAProjectListByCustomer } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";
import { DeleteProjectSpaceGroup, GetSpaceGroupListByProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup";
import flasher from "@flasher/flasher";
import Swal from "sweetalert2";
import { useStateContext } from "../../../../context/ContextProvider";

function GAAProjectSpaceGroup({ Rights }) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }
    document.title = "Gladiance - Project Space Group";
    const tableHeading = ["Sr.No", "Name", 'Action'];
    const [loader, setLoader] = useState(false);
    const [projectSpaceGroupList, setProjectSpaceGroupList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [customerList, setCustomerList] = useState([])
    const [ProjectList, setProjectList] = useState([]);
    const itemsPerPage = 7;
    const [errors, setErrors] = useState([]);
    const Navigation = useNavigate()
    const { CustomerRef, GAAProjectRef, handleCustomerRef, handleProjectRef } = useStateContext();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //Fetch Customer List For Dropdown//
    const fetchCustomerList = async () => {
        setCustomerList(await GetGladianceCustomerList());
    }

    //Fetch Project List for Dropdowm By Customer//
    const fetchGAAProjectListByCustomer = async () => {
        CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
    };

    //Fetch Project Space Group List By Project//
    const fetchSpaceGroupListByProject = async () => {
        GAAProjectRef && setProjectSpaceGroupList(await GetSpaceGroupListByProject(GAAProjectRef));
    };

    const deleteProjectSpaceGroup = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteProjectSpaceGroup(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchSpaceGroupListByProject();
                }
            }
        })
    }

    useEffect(() => {
        fetchCustomerList();
    }, []);

    useEffect(() => {
        if (CustomerRef == '' && customerList.length > 0) {
            handleCustomerRef(customerList[0].Ref);
        }
    }, [customerList]);

    useEffect(() => {
        fetchGAAProjectListByCustomer();
    }, [CustomerRef]);

    useEffect(() => {
        if (GAAProjectRef == '' && ProjectList.length > 0) {
            handleProjectRef(ProjectList[0].Ref);
        }
    }, [ProjectList]);

    useEffect(() => {
        fetchSpaceGroupListByProject();
    }, [GAAProjectRef]);

    return (
        <Fragment>
            {loader ? (
                <Loader />
            ) : (
                <AddComponent
                    title={"Project Space Groups"}
                    button={
                        <LinkButton to={'/gaa-project-space-group/add-gaa-project-space-group'} className={Rights.Add ? "opacity-100" : "hidden"} state={GAAProjectRef} >
                            <AddCircle variant="Bold" className="w-5 h-5" />
                            <span>Add Project Space Group</span>
                        </LinkButton>
                    }
                >
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                        <div>
                            <Label htmlFor={'CustomerRef'} labelText={'Customer'} />
                            <CustomSelect id={'CustomerRef'} name={'CustomerRef'} value={CustomerRef} onChange={(e)=> handleCustomerRef(e.target.value)} >
                                <Option value={''}>-- Select --</Option>
                                {
                                    customerList && customerList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </div>
                        <div>
                            <Label htmlFor={'GAAProjectRef'} labelText={'Project'} />
                            <CustomSelect id={'GAAProjectRef'} name={'GAAProjectRef'} value={GAAProjectRef} onChange={(e)=> handleProjectRef(e.target.value)}>
                                <Option value={''}>-- Select --</Option>
                                {
                                    ProjectList && ProjectList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </div>
                    </div>
                    {
                        projectSpaceGroupList && projectSpaceGroupList.length > 0 ? (
                            <div ref={componentRef} className="m-2">
                                <Table tableHeading={tableHeading}>
                                    {projectSpaceGroupList &&
                                        projectSpaceGroupList
                                            .slice(
                                                (currentPage - 1) * itemsPerPage,
                                                currentPage * itemsPerPage
                                            )
                                            .map((tableData, tableDataID) => {
                                                return (
                                                    <Fragment key={tableDataID}>
                                                        <tr className="border-b border-opacity-30 border-white">
                                                            <td className="px-4 py-3">{tableDataID + 1}</td>
                                                            <td className="px-4 py-3">{tableData.Name}</td>
                                                            <td className="px-4 py-3">
                                                                <div className="flex justify-center gap-3">
                                                                    <Link
                                                                        to={`/gaa-project-space-group/edit-gaa-project-space-group/${tableData.Ref}`}
                                                                        title="Edit"
                                                                    >
                                                                        <ActionButton
                                                                            className={
                                                                                "p-1.5 text-white bg-yellow-400 "
                                                                            }
                                                                        >
                                                                            <PencilSquareIcon className="w-4 h-4" />
                                                                        </ActionButton>
                                                                    </Link>
                                                                    {Rights.Delete && (
                                                                        <ActionButton onClick={() => deleteProjectSpaceGroup(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                            <TrashIcon className='w-4 h-4' />
                                                                        </ActionButton>
                                                                    )}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                );
                                            })}
                                </Table>
                            </div>
                        ) : (
                            <DataNotFound labelText={"Sorry! Project Space Group Data Not Found"} />
                        )}
                    {projectSpaceGroupList && projectSpaceGroupList.length > 7 ? (
                        <div className="flex justify-end">
                            <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={projectSpaceGroupList.length}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </AddComponent>
            )}
        </Fragment>
    );
}

export default GAAProjectSpaceGroup;
