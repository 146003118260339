import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import { GetGAAProductInputDriverChannels, SaveGAAProductInputDriverChannels } from '../../../Encapsulation/GladianceDomainEntities/GAAProductInputDriverChannel '
import { GetGAAProductModesMaster } from '../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster'

function GAAProductInputDriverChannelAddEdit() {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Gladiance - Edit Product Input Channel";
      formMode = "Edit";
    } else {
      document.title = "Gladiance - Add Product Input Channel";
    }
  }
  const [loader, setLoader] = useState(false);
  const Navigation = useNavigate();
  const Location = useLocation()
  const [formInfo, setFormInfo] = useState({});

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGAAProductInputDriverChannels(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/product-input-driver-channel-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProduct();                   // Save ProjectRef 
      }
      console.log("result :", result);
    }
  };

  // Fetch Product Details Using Location State //
  const fetchProduct = async () => {
    let Product = await GetGAAProductModesMaster(Location.state);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProductRef: Product.Ref,
      GAAProductName: Product.Name
    }))
  };


  const fetchInputDriverChannel = async () => {
    let data = await GetGAAProductInputDriverChannels(id)
    setFormInfo(data);
  }

  // Fetch Product Details Using Location State //
  useEffect(() => {
    if (Location.state) {
      fetchProduct();
    }
    if (id) {
      fetchInputDriverChannel();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit Product Input Channel" : "Add Product Input Channel"}
          button={
            <LinkButton to={"/product-input-driver-channel-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"GAAProductRef"} labelText={"Product Name"} />
                    {formInfo.GAAProductRef && (<Input
                      id={"GAAProductRef"}
                      type={"text"}
                      name={"GAAProductRef"}
                      value={formInfo.GAAProductName}
                      required={true}
                      readOnly={true}
                    />
                    )}
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Channel Name"} />
                    <Input
                      inputFocus={true}
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                  <div>
                    <Label htmlFor={"DisplayOrder"} labelText={"Button No"} />
                    <Input
                      id={"DisplayOrder"}
                      type={"number"}
                      name={"DisplayOrder"}
                      value={formInfo.DisplayOrder}
                      onChange={handleChange}
                      min={0}
                    />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/product-input-driver-channel-master"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}
export default GAAProductInputDriverChannelAddEdit
