import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../../components/BackComponent";
import Label from "../../../helpers/Label";
import Card from "../../../helpers/Card";
import Input from "../../../helpers/Input";
import LinkButton from "../../../helpers/LinkButton";
import Loader from "../../../Common/Loader";
import Validation from "../../../helpers/Validation";
import { ArrowCircleLeft2 } from "iconsax-react";
import CancelButton from "../../../helpers/CancelButton";
import SaveButton from "../../../helpers/SaveButton";
import { ClearInfo } from "../../../../Encapsulation/Utils";
import flasher from "@flasher/flasher";
import CheckBox from "../../../helpers/CheckBox";
import { GetGAAProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import {
  GetGAAProjectDepartment,
  GetGAAProjectDepartmentListByProject,
  SaveGAAProjectDepartment,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectDepartment";
import {
  GetGAAProjectUser,
  SaveGAAProjectUser,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectUser";
import CustomSelect from "../../../helpers/CustomSelect";
import Option from "../../../helpers/Option";
import { GetGAAProjectJobTitleList } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectJobTitle";
import { GetGAAProjectUserRoleList } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectUserRole ";

function GAAProjectUserAddEdit({ Rights }) {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Hotel - Edit User";
      formMode = "Edit";
    } else {
      document.title = "Hotel - Add User";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation();
  const [formInfo, setFormInfo] = useState({});
  const [GAAProjectJobTitleList, setGAAProjectJobTitleList] = useState([]);
  const [GAAProjectUserRoleList, setGAAProjectUserRoleList] = useState([]);
  const [GAAProjectDepartmentList, setGAAProjectDepartmentList] = useState([]);

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGAAProjectUser(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/project-user-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchDepartment(); // Save ProjectRef
      }
      console.log("result :", result);
    }
  };

  // Fetch project Details Using Location State //
  const fetchDepartment = async () => {
    let Department = await GetGAAProjectDepartment(
      Location.state.GAADepartmentRef
    );
    let Project = await GetGAAProject(Location.state.GAAProjectRef);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectDepartmentRef: Department.Ref,
      GAAProjectDepartmentName: Department.Name,
    }));
  };

  const fetchGAADepartmentListByProject = async () => {
    Location.state.GAAProjectRef &&
      setGAAProjectDepartmentList(
        await GetGAAProjectDepartmentListByProject(Location.state.GAAProjectRef)
      );
  };

  // Fetch User //
  const fetchUser = async () => {
    let data = await GetGAAProjectUser(id);
    setFormInfo(data);
  };

  // Fetch Job Title //
  const fetchProjectJobTitle = async () => {
    setGAAProjectJobTitleList(
      (await GetGAAProjectJobTitleList()).filter(
        (data) =>
          data.GAAProjectDepartmentRef == formInfo.GAAProjectDepartmentRef
      )
    );
  };

  // Fetch User Role//
  const fetchProjectUserRole = async () => {
    setGAAProjectUserRoleList(
      (await GetGAAProjectUserRoleList()).filter((data) => data.GAAProjectRef === formInfo.GAAProjectRef )
    );
  };

  useEffect(() => {
    fetchGAADepartmentListByProject();
  }, [Location.state.GAAProjectRef]);

  // Fetch project Details Using Location State //
  useEffect(() => {
    if (Location.state.GAADepartmentRef) {
      fetchDepartment();
    }
    if (id) {
      // Fetch Project Space Type By Id //
      fetchUser();
    }
  }, []);

  useEffect(() => {
    fetchProjectJobTitle();
  }, [formInfo.GAAProjectDepartmentRef]);

  useEffect(() => {
    fetchProjectUserRole();
  }, [formInfo.GAAProjectRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit User" : "Add User"}
          button={
            <LinkButton to={"/project-user-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProjectDepartmentRef"}
                      labelText={"Department"}
                    />
                    {formInfo.GAAProjectDepartmentRef && (
                      <CustomSelect
                        id={"GAAProjectDepartmentRef"}
                        name={"GAAProjectDepartmentRef"}
                        value={formInfo.GAAProjectDepartmentRef}
                        onChange={handleChange}
                        readOnly={!Rights.Edit}
                      >
                        <Option value={""}>- - Select - -</Option>
                        {GAAProjectDepartmentList &&
                          GAAProjectDepartmentList.map((data, id) => {
                            return (
                              <Fragment key={id}>
                                <Option value={data.Ref}>{data.Name}</Option>
                              </Fragment>
                            );
                          })}
                      </CustomSelect>
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectJobTitleRef"}
                      labelText={"Job Title"}
                    />
                    <CustomSelect
                      id={"GAAProjectJobTitleRef"}
                      name={"GAAProjectJobTitleRef"}
                      value={formInfo.GAAProjectJobTitleRef}
                      inputFocus={true}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option value={""}>-- Select --</Option>
                      {GAAProjectJobTitleList &&
                        GAAProjectJobTitleList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option value={data.Ref}>{data.Name}</Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectUserRoleRef"}
                      labelText={"User Role"}
                    />
                    <CustomSelect
                      id={"GAAProjectUserRoleRef"}
                      name={"GAAProjectUserRoleRef"}
                      value={formInfo.GAAProjectUserRoleRef}
                      onChange={handleChange}
                    >
                      <Option value={""}>-- Select --</Option>
                      {GAAProjectUserRoleList &&
                        GAAProjectUserRoleList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option value={data.Ref}>{data.Name}</Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"User Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={"Password"} labelText={"Password"} />
                    <Input
                      id={"Password"}
                      type={"text"}
                      name={"Password"}
                      value={formInfo.Password}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Password} />
                  </div>
                  <div>
                    <Label htmlFor={"FirstName"} labelText={"First Name"} />
                    <Input
                      id={"FirstName"}
                      type={"text"}
                      name={"FirstName"}
                      value={formInfo.FirstName}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.FirstName} />
                  </div>
                  <div>
                    <Label htmlFor={"MiddleName"} labelText={"Middle Name"} />
                    <Input
                      id={"MiddleName"}
                      type={"text"}
                      name={"MiddleName"}
                      value={formInfo.MiddleName}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.MiddleName} />
                  </div>
                  <div>
                    <Label htmlFor={"LastName"} labelText={"Last Name"} />
                    <Input
                      id={"LastName"}
                      type={"text"}
                      name={"LastName"}
                      value={formInfo.LastName}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.LastName} />
                  </div>
                  <div>
                    <Label htmlFor={"PhoneNo"} labelText={"Phone No"} />
                    <Input
                      id={"PhoneNo"}
                      type={"text"}
                      name={"PhoneNo"}
                      value={formInfo.PhoneNo}
                      onChange={handleChange}
                      minLength={10}
                      maxLength={10}
                      onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.PhoneNo} />
                  </div>
                  <div>
                    <Label htmlFor={"EMailId"} labelText={"Email Id"} />
                    <Input
                      id={"EMailId"}
                      type={"email"}
                      name={"EMailId"}
                      value={formInfo.EMailId}
                      onChange={handleChange}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.EMailId} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/project-user-master"} />
                  {Rights.Edit && <SaveButton text="Save" />}
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectUserAddEdit;
