import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import { DeleteHotelLaundry, GetGAAHotelLaundryListByProject } from "../../../Encapsulation/HotelDomainEntities/HotelLaundry";
import Label from "../../helpers/Label";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProjectList } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Laundry({ Rights }) {
  document.title = "Gladiance - Laundry List";
  const tableHeading = [
    "Sr.No",
    "Name",
    "Press Charges",
    "Drywash Charge",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [laundryList, setLaundryList] = useState([]);
  const [projectRef, setProjectRef] = useState();
  const [projectList, setProjectList] = useState([]);

  const itemsPerPage = 7;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchLaundryListByProject = async () => {
    projectRef &&
      setLaundryList(await GetGAAHotelLaundryListByProject(projectRef));
  };

  // Fetch Project List For Dropdown //
  const fetchProjectList = async () => {
    setProjectList(await GetGAAProjectList());
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const deleteLaundry = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteHotelLaundry(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchLaundryListByProject();
        }
      }
    })
  }

  useEffect(() => {
    fetchLaundryListByProject();
  }, [projectRef]);

  useEffect(() => {
    if (projectList.length > 0) {
      setProjectRef(projectList[0].Ref);
    }
  }, [projectList]);

  useEffect(() => {
    fetchProjectList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Laundry List"}
          button={
            <LinkButton to={"/laundry/add-laundry"} className={Rights.Add ? 'opacity-100' : 'hidden'} state={projectRef}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Laundry Details</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"projectRef"} labelText={"Project"} />
              <CustomSelect
                id={"projectRef"}
                name={"projectRef"}
                value={projectRef}
                onChange={(e) => setProjectRef(e.target.value)}
                required={true}
              >
                {projectList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option value={data.Ref}>{data.Name}</Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {laundryList && laundryList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {laundryList
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white text-center">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            {tableData.PressCharges}
                          </td>
                          <td className="px-4 py-3">
                            {tableData.DryWashCharges}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/laundry/edit-laundry/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                              <ActionButton onClick={() => deleteLaundry(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                                 )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Laundry Data Not Found"} />
          )}
          {laundryList && laundryList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={laundryList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default Laundry;
