import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GladianceJobTitleMaster";
const FetchRequestType = "GladianceJobTitleFetchRequest";

export const GetGladianceJobTitle = async (Ref) => {
  let payload = { GladianceJobTitleRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGladianceJobTitle = async (
  ref,
  payload
) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const DeleteGladianceJobTitle = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGladianceJobTitleList = async () => {
  let payload = { GladianceJobTitleRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};
