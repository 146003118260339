import axiosClient from "./Axios";
const Post = async (payload, meta, images, isCollection = false) => {
  try {
    const loginToken = window.location.hostname.includes('gladiance.one') ? 50000102 : 50000002;
    
    const formData = new FormData();
    formData.append("Meta_LoginToken", loginToken.toString());
    formData.append("Meta_RequestType", meta.RequestType);

    if (meta.RequestType == "Save" || meta.RequestType == "Deletion") {
      if (
        meta.MasterTableName != null &&
        meta.MasterTableName != undefined &&
        meta.MasterTableName != ""
      ) {
        formData.append("Meta_MasterTableName", meta.MasterTableName);
      }
    } else if (meta.RequestType == "Fetch") {
      if (
        meta.FetchRequestType != null &&
        meta.FetchRequestType != undefined &&
        meta.FetchRequestType != ""
      ) {
        formData.append("Meta_FetchRequestType", meta.FetchRequestType);
      }
    } else if (meta.RequestType == "CustomProcess") {
      if (
        meta.CustomProcessRequestType != null &&
        meta.CustomProcessRequestType != undefined &&
        meta.CustomProcessRequestType != ""
      ) {
        formData.append(
          "Meta_CustomProcessRequestType",
          meta.CustomProcessRequestType
        );
      }
    } else {
      throw "Invalid Request Type";
    }

    if (!isCollection) {
      formData.append("Data", JSON.stringify(payload));
    } else {
      formData.append("DataArray", JSON.stringify(payload));
    }

    for (let [imageName, img] of images) {
      formData.append(imageName, img);
    }

    let tr = (await axiosClient.post(`/request/acceptrequest2`, formData)).data;
    if (!tr.Successful) {
      throw tr;
    } else {
      return tr;
    }
  } catch (errors) {
    return errors;
  }
};

// ============================================================================================

export const SaveToServer = async (
  payload,
  masterTableName,
  images,
  isCollection = false
) => {
  let meta = {
    RequestType: "Save",
    MasterTableName: masterTableName,
  };

  return await Post(payload, meta, images, isCollection);
};

export const DeleteFromServer = async (payload, masterTableName) => {
  let meta = {
    RequestType: "Deletion",
    MasterTableName: masterTableName,
  };

  let tr = await Post(payload, meta, []);
  return tr;
};

export const FetchFromServer = async (payload, fetchRequestType, images) => {
  let meta = {
    RequestType: "Fetch",
    FetchRequestType: fetchRequestType,
  };

  let tr = await Post(payload, meta, images);
  return JSON.parse(tr.Tag);
};

export const ExecuteCustomProcessOnServer = async (
  payload,
  customProcessRequestType,
  images
) => {
  let meta = {
    RequestType: "CustomProcess",
    CustomProcessRequestType: customProcessRequestType,
  };

  return await Post(payload, meta, images);
};
