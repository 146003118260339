import React, { Fragment, useEffect, useState } from 'react'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import Label from './helpers/Label'

import Input from './helpers/Input'
import Button from './helpers/Button'
import LinkButton from './helpers/LinkButton'
import Loader from './Common/Loader'
import Validation from './helpers/Validation'
import CustomSelect from './helpers/CustomSelect'
import Card from './helpers/Card'
import { ArrowCircleLeft2 } from 'iconsax-react'
import AddComponent from '../components/AddComponent'
import { useStateContext } from '../context/ContextProvider'

function BackgroundImages() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'Gladiance - Edit Background Theme'
        ) : (
            document.title = 'Gladiance - Add Background Theme'
        )
    }
    const { FRONTEND_URL, setTheme, theme } = useStateContext()

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        user_name: '',
        password: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        phone_no: '',
        email: "",
        gladiance_dept: '',
        gladiance_job_title: '',
        user_role: '',
    })

    const handleTheme = (e) => {
        localStorage.setItem('theme', e.target.checked);
        setTheme(e.target.checked);
    }

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('user_name :', formInfo.user_name);
        console.log('password :', formInfo.password);
        console.log('last_name :', formInfo.last_name);
        console.log('first_name :', formInfo.first_name);
        console.log('middle_name :', formInfo.middle_name);
        console.log('phone_no :', formInfo.phone_no);
        console.log('email :', formInfo.email);
        console.log('gladiance_dept :', formInfo.gladiance_dept);
        console.log('gladiance_job_title :', formInfo.gladiance_job_title);
        console.log('user_role :', formInfo.user_role);
    }

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={theme ? 'Dark Theme' : 'Light Theme'}>
                    <Card className={'w-full px-4 py-4'}>

                        <div className="flex items-end mx-2">
                            <div className="flex justify-end">
                                <label
                                    title="theme"
                                    className="relative inline-flex items-center justify-center cursor-pointer"
                                    onClick={(e) => handleTheme(e)}
                                >
                                    <input
                                        type="checkbox"
                                        value={theme}
                                        className="sr-only peer"
                                    />
                                    <div className={"w-7 h-4 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-black after:content-[''] after:absolute after:top-[2px] after:left-[2px]  after:border after:rounded-full after:h-3 after:w-3 after:transition-all " + (theme ? "after:bg-red-500 after:border-gray-300 ring-pink-400 peer-checked:bg-white " : " bg-[#222222] before:bg-white")}></div>
                                </label>
                            </div>
                        </div>
                        <br />
                        <div className='flex items-center justify-center'>
                            <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                                <div>
                                    <img className="h-[30vh] w-[35vw] rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image.jpg" alt="" />
                                </div>
                                <div>
                                    <img className="h-[30vh] w-[35vw] rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg" alt="" />
                                </div>
                                <div>
                                    <img className="h-[30vh] w-[35vw] rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </Card>
                </AddComponent>
            )}
            {/* {loader ? (<Loader />) : (
                <AddComponent title={'Dark Theme'}>
                    <Card className={'w-full px-4 py-4'}>
                        <div className='flex items-center justify-center'>
                            <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                                <div>
                                    <img className="h-[30vh] w-[35vw] rounded-lg" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdo_aIP8y4ZbyaXKIkKS2lSOV9bfni8-YK0Q&usqp=CAU" alt="" />
                                </div>
                                <div>
                                    <img className="h-[30vh] w-[35vw] rounded-lg" src={FRONTEND_URL + "/assets/images/dbg2.jpg"} alt="" />
                                </div>
                                <div>
                                    <img className="h-[30vh] w-[35vw] rounded-lg" src={FRONTEND_URL + "/assets/images/dbg1.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                    </Card>
                </AddComponent>
            )} */}
        </Fragment>
    )
}


export default BackgroundImages
