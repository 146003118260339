import { createBrowserRouter } from 'react-router-dom'
import Dashboard from './views/Dashboard';
import Login from './views/auth/Login';
import Register from './views/auth/Register';
import ForgetPassword from './views/auth/ForgetPassword';
import ResetPassword from './views/auth/ResetPassword';
import ChangePassword from './views/auth/ChangePassword';
import GuestLayout from './components/GuestLayout';
import MainLayout from './components/MainLayout';
import YourProfile from './views/YourProfile';
import UserMasterAddEdit from './views/Master/Users/UserMasterAddEdit';
import GladianceUserRights from './views/Master/User Rights/GladianceUserRights';
import GladianceJobtitleMasterAddEdit from './views/Master/Job Titles/GladianceJobtitleMasterAddEdit';
import BackgroundImages from './views/BackgroundImages';
import VerticalsAddEdit from './views/Company/VerticalsAddEdit';
import GladianceDepartmentMaster from './views/Master/Departments/GladianceDepartmentMaster';
import GladianceDepartmentMasterAddEdit from './views/Master/Departments/GladianceDepartmentMasterAddEdit';
import SolutionsAddEdit from './views/Company/SolutionsAddEdit';
import GladianceJobtitleMaster from './views/Master/Job Titles/GladianceJobtitleMaster';
import UserMaster from './views/Master/Users/UserMaster';
import PageNotFound from './views/auth/PageNotFound';
import GAAComboMaster from './views/Master/Combos/GAAComboMaster';
import GAAComboMasterAddEdit from './views/Master/Combos/GAAComboMasterAddEdit';
import ControlBouquetMaster from './views/Master/Control Bouquet/ControlBouquetMaster';
import CommercialInventoryMaster from './views/Master/Commercial Inventory/CommercialInventoryMaster';
import CommercialInventoryAddEdit from './views/Master/Commercial Inventory/CommercialInventoryAddEdit';
import ProgrammingRequisitionMaster from './views/Master/Programming Requisition/ProgrammingRequisitionMaster';
import ProgrammingRequisitionAddEdit from './views/Master/Programming Requisition/ProgrammingRequisitionAddEdit';
import DeviceProgrammingMaster from './views/Master/Device Programming/DeviceProgrammingMaster';
import DeviceProgrammingAddEdit from './views/Master/Device Programming/DeviceProgrammingAddEdit';
import CountryMaster from './views/Master/Country Master/CountryMaster';
import CountryMasterAddEdit from './views/Master/Country Master/CountryMasterAddEdit';
import RightsRegistration from './views/Hotel/User Management/RightsRegistration';
import DoorCamera from './views/Hotel/Door Lock & Camera/DoorCamera';
import DoorCameraAddEdit from './views/Hotel/Door Lock & Camera/DoorCameraAddEdit';
import TelevisionMaster from './views/Hotel/Television Master/TelevisionMaster';
import TelevisionMasterAddEdit from './views/Hotel/Television Master/TelevisionMasterAddEdit';
import Laundry from './views/Hotel/Laundry/Laundry';
import LaundryAddEdit from './views/Hotel/Laundry/LaundryAddEdit';
import Surveillance from './views/Hotel/Surveillance/Surveillance';
import SurveillanceAddEdit from './views/Hotel/Surveillance/SurveillanceAddEdit';
import MiscellaneousParameter from './views/Hotel/Miscellaneous Parameter/MiscellaneousParameter';
import MiscellaneousParameterAddEdit from './views/Hotel/Miscellaneous Parameter/MiscellaneousParameterAddEdit';
import Amenities from './views/Hotel/Information Master/Amenities';
import AmenitiesAddEdit from './views/Hotel/Information Master/AmenitiesAddEdit';
import Telephone from './views/Hotel/Information Master/Telephone';
import TelephoneAddEdit from './views/Hotel/Information Master/TelephoneAddEdit';
import Feedback from './views/Hotel/Information Master/Feedback';
import FeedbackAddEdit from './views/Hotel/Information Master/FeedbackAddEdit';
import KeyContact from './views/Hotel/Information Master/KeyContact';
import KeyContactAddEdit from './views/Hotel/Information Master/KeyContactAddEdit';
import Reservation from './views/Hotel/Guest Management/Reservation';
import ReservationAddEdit from './views/Hotel/Guest Management/ReservationAddEdit';
import CheckIn from './views/Hotel/Guest Management/CheckIn';
import CheckInAddEdit from './views/Hotel/Guest Management/CheckInAddEdit';
import CheckOut from './views/Hotel/Guest Management/CheckOut';
import CheckOutAddEdit from './views/Hotel/Guest Management/CheckOutAddEdit';
import BillViewManagement from './views/Hotel/Bill View Management/BillViewManagement';
import BillViewManagementAddEdit from './views/Hotel/Bill View Management/BillViewManagementAddEdit';
import PromotionsManagement from './views/Hotel/Promotions Management/PromotionsManagement';
import PromotionsManagementAddEdit from './views/Hotel/Promotions Management/PromotionsManagementAddEdit';
import MessageManagement from './views/Hotel/Message Management/MessageManagement';
import MessageManagementAddEdit from './views/Hotel/Message Management/MessageManagementAddEdit';
import RoomScene from './views/Hotel/Room Scene/RoomScene';
import Navbar from './views/Common/Navbar';
import GladianceUserRoleMaster from '../src/views/Master/UserRole/GladianceUserRoleMaster'
import GladianceUserRoleMasterAddEdit from '../src/views/Master/UserRole/GladianceUserRoleMasterAddEdit'
import GladianceSolutionMasterAddEdit from './views/Master/Solution Master/GladianceSolutionMasterAddEdit';
import GladianceSolutionMaster from './views/Master/Solution Master/GladianceSolutionMaster';
import Customer from './views/Master/Customer/Customer';
import CustomerAddEdit from './views/Master/Customer/CustomerAddEdit';
import GAAProjectAddEdit from './views/Hotel/Hotel Master/GAA Project/GAAProjectAddEdit';
import GAAProject from './views/Hotel/Hotel Master/GAA Project/GAAProject';
import GAAProjectSpaceType from './views/Hotel/Hotel Master/Project Space Type/GAAProjectSpaceType';
import GAAProjectSpaceTypeAddEdit from './views/Hotel/Hotel Master/Project Space Type/GAAProjectSpaceTypeAddEdit';
import GAAProjectSpaceGroup from './views/Hotel/Hotel Master/Project Space Group/GAAProjectSpaceGroup';
import GAAProjectSpaceGroupAddEdit from './views/Hotel/Hotel Master/Project Space Group/GAAProjectSpaceGroupAddEdit';
import GAAProjectSpaceAddEdit from './views/Hotel/Hotel Master/GAA Project Space/GAAProjectSpaceAddEdit';
import GAAProjectSpace from './views/Hotel/Hotel Master/GAA Project Space/GAAProjectSpace';
import GAAProjectSpaceTypeArea from './views/Hotel/Hotel Master/Project Space Type Area/GAAProjectSpaceTypeArea';
import GAAProjectSpaceTypeAreaAddEdit from './views/Hotel/Hotel Master/Project Space Type Area/GAAProjectSpaceTypeAreaAddEdit';
import GAAAreaWifiConfiguration from './views/Hotel/Hotel Master/Area Wifi Configuration/GAAAreaWifiConfiguration';
import GAAAreaWifiConfigurationAddEdit from './views/Hotel/Hotel Master/Area Wifi Configuration/GAAAreaWifiConfigurationAddEdit';
import GAAProjectDepartment from './views/Hotel/Hotel Master/Project Department/GAAProjectDepartment';
import GAAProjectDepartmentAddEdit from './views/Hotel/Hotel Master/Project Department/GAAProjectDepartmentAddEdit';
import GAAProjectJobTitle from './views/Hotel/Hotel Master/Project Job Title/GAAProjectJobTitle';
import GAAProjectJobTitleAddEdit from './views/Hotel/Hotel Master/Project Job Title/GAAProjectJobTitleAddEdit';
import GAAProjectUser from './views/Hotel/Hotel Master/Project User/GAAProjectUser';
import GAAProjectUserAddEdit from './views/Hotel/Hotel Master/Project User/GAAProjectUserAddEdit';
import GAAProjectUserRole from './views/Hotel/Hotel Master/Project User Role/GAAProjectUserRole';
import GAAProjectUserRoleAddEdit from './views/Hotel/Hotel Master/Project User Role/GAAProjectUserRoleAddEdit';
import GAAProductOutputFunctionType from './views/Master/Product Output Function Type/GAAProductOutputFunctionType';
import GAAProductOutputFunctionTypeAddEdit from './views/Master/Product Output Function Type/GAAProductOutputFunctionTypeAddEdit';
import GAAProductInputChannel from './views/Master/Product Input Driver Channel/GAAProductInputDriverChannel';
import GAAProductInputChannelAddEdit from './views/Master/Product Input Driver Channel/GAAProductInputDriverChannelAddEdit';
import GAAProductOutputFunctionId from './views/Master/GAA Product Output Function Id/GAAProductOutputFunctionId';
import GAAProductOutputFunctionIdAddEdit from './views/Master/GAA Product Output Function Id/GAAProductOutputFunctionIdAddEdit';
import GAAProductVersion from './views/Master/Ambiance Automation Products/GAAProductVersion';
import GAAProductVersionAddEdit from './views/Master/Ambiance Automation Products/GAAProductVersionAddEdit';
import GAAProductOutputDriverChannel from './views/Master/Product Output Driver Channel/GAAProductOutputDriverChannel';
import GAAProductOutputDriverChannelAddEdit from './views/Master/Product Output Driver Channel/GAAProductOutputDriverChannelAddEdit';
import GAAProductModesMaster from './views/Master/GAAProductMaster/GAAProductModesMaster';
import GAAProductModesMasterAddEdit from './views/Master/GAAProductMaster/GAAProductModesMasterAddEdit';
import GAAProjectSpaceTypePlannedDevice from './views/Hotel/Hotel Master/Project Space Type Planned Device/GAAProjectSpaceTypePlannedDevice';
import GAAProjectSpaceTypePlannedDeviceAddEdit from './views/Hotel/Hotel Master/Project Space Type Planned Device/GAAProjectSpaceTypePlannedDeviceAddEdit';
import GAAProjectSpaceTypePlannedDeviceConnections from './views/Hotel/Hotel Master/Project Space Type Planned Device Connections/GAAProjectSpaceTypePlannedDeviceConnections';
import GAAProjectSpaceTypePlannedDeviceConnectionsAddEdit from './views/Hotel/Hotel Master/Project Space Type Planned Device Connections/GAAProjectSpaceTypePlannedDeviceConnectionsAddEdit';
import GAAProductOutputDriverChannelDeviceType from './views/Master/Output Driver Channel Device Type/GAAProductOutputDriverChannelDeviceType';
import GAAProductOutputDriverChannelsAddEdit from './views/Master/Output Driver Channel Device Type/GAAProductOutputDriverChannelsAddEdit';
import RoomSceneAddEdit from './views/Hotel/Room Scene/RoomSceneAddEdit';
import GAAProjectRBItemMaster from './views/Hotel/In Room Dining Master/ProjectRBItemMaster/GAAProjectRBItemMaster';
import GAAProjectRBItemMasterAddEdit from './views/Hotel/In Room Dining Master/ProjectRBItemMaster/GAAProjectRBItemMasterAddEdit';
import GAAProjectRBItemApplicableServingSlots from './views/Hotel/In Room Dining Master/Project RBI tem Applicable Serving Slots/GAAProjectRBItemApplicableServingSlots';
import GAAProjectRBItemApplicableServingSlotsAddEdit from './views/Hotel/In Room Dining Master/Project RBI tem Applicable Serving Slots/GAAProjectRBItemApplicableServingSlotsAddEdit';
import ProjectRBItemServingSlotMaster from './views/Hotel/In Room Dining Master/Project RB Item Serving Slot Master/ProjectRBItemServingSlotMaster';
import ProjectRBItemServingSlotAddEdit from './views/Hotel/In Room Dining Master/Project RB Item Serving Slot Master/ProjectRBItemServingSlotAddEdit';
import { fa } from 'faker/lib/locales';
import RoomSceneConfiguration from './views/Hotel/Room Scene/RoomSceneConfiguration';
import RoomSceneConfigurationAddEdit from './views/Hotel/Room Scene/RoomSceneConfigurationAddEdit';

export const user_roll = localStorage.getItem('USER_ROLE');

const GladianceUserMapping = {
    Department: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
}

const HotelUserMapping = {
    UserManagement: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    RoomInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    DeviceInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    RoomSceneInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    GladianceOneAppInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    MiscellaneousInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    TelevisionInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    InRoomDiningInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    LaundryInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    SurveillanceInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    InformationInventory: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    GuestManagement: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    BillViewManagement: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    PromotionsManagement: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    MessagingManagement: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    RoomLiveStatus: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    AmbianceAutomationLiveStatus: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
    FeedbackLiveStatus: {
        Add: true,
        Edit: true,
        Delete: true,
        View: true,
    },
}

const Router = createBrowserRouter([
    {
        path: '/',
        element: <GuestLayout />,
        children: [
            { path: '/', element: <Login /> },
            { path: '/register', element: <Register /> },
            { path: '/forget-password', element: <ForgetPassword /> },
            { path: '/reset-password/:emailID', element: <ResetPassword /> },
        ]

    }, {
        element: <MainLayout HotelUserMapping={HotelUserMapping} GladianceUserMapping={GladianceUserMapping} />,
        children: [
            { path: '/dashboard', element: <Dashboard /> },

            { path: '/navbar', element: <Navbar /> },

            { path: '/your-profile', element: <YourProfile /> },
            { path: '/change-password', element: <ChangePassword /> },
            { path: '/background-image', element: <BackgroundImages /> },

            // ========================= Gladiance Part Started =========================

            { path: '/solutions', element: <SolutionsAddEdit /> },
            { path: '/verticals', element: <VerticalsAddEdit /> },

            { path: '/user-master-add', element: <UserMasterAddEdit /> },

            { path: '/gladiance-department-master', element: <GladianceDepartmentMaster Rights={GladianceUserMapping.Department} /> },
            { path: '/gladiance-department-master/add-gladiance-department-master', element: <GladianceDepartmentMasterAddEdit Rights={{ Edit: true }} /> },
            { path: '/gladiance-department-master/edit-gladiance-department-master/:id', element: <GladianceDepartmentMasterAddEdit  Rights={GladianceUserMapping.Department} /> },

            { path: '/gladiance-job-title-master', element: <GladianceJobtitleMaster /> },
            { path: '/gladiance-job-title-master/add-gladiance-job-title-master', element: <GladianceJobtitleMasterAddEdit /> },
            { path: '/gladiance-job-title-master/edit-gladiance-job-title-master/:id', element: <GladianceJobtitleMasterAddEdit /> },

            { path: '/gladiance-solution-master', element: < GladianceSolutionMaster /> },
            { path: '/gladiance-solution-master/add-gladiance-solution-master', element: <GladianceSolutionMasterAddEdit /> },
            { path: '/gladiance-solution-master/edit-gladiance-solution-master/:id', element: <GladianceSolutionMasterAddEdit /> },

            { path: '/gladiance-user-master', element: <UserMaster /> },
            { path: '/gladiance-user-master/add-user-master', element: < UserMasterAddEdit /> },
            { path: '/gladiance-user-master/edit-gladiance-user-master/:id', element: <UserMasterAddEdit /> },

            { path: '/gladiance-userrole-master', element: <GladianceUserRoleMaster /> },
            { path: '/gladiance-userrole-master/add-userrole-master', element: < GladianceUserRoleMasterAddEdit /> },
            { path: '/gladiance-userrole-master/edit-userrole-master/:id', element: < GladianceUserRoleMasterAddEdit /> },

            { path: 'gladiance-user-rights', element: <GladianceUserRights /> },

            { path: '/gladiance-product-version', element: <GAAProductVersion /> },
            { path: '/gladiance-product-version/add-gladiance-product-version', element: <GAAProductVersionAddEdit /> },
            { path: '/gladiance-product-version/edit-gladiance-product-version/:id', element: <GAAProductVersionAddEdit /> },

            { path: '/gladiance-combo', element: <GAAComboMaster /> },
            { path: '/gladiance-combo/add-gladiance-combo', element: <GAAComboMasterAddEdit /> },

            { path: '/country-master', element: <CountryMaster /> },
            { path: '/country-master/add-country-master', element: <CountryMasterAddEdit /> },
            { path: '/country-master/edit-country-master/:id', element: <CountryMasterAddEdit /> },

            { path: 'gladiance-control-bouquet', element: <ControlBouquetMaster /> },

            { path: '/commercial-inventory', element: <CommercialInventoryMaster /> },
            { path: '/commercial-inventory/add-commercial-inventory', element: <CommercialInventoryAddEdit /> },
            { path: '/commercial-inventory/edit-commercial-inventory/:id', element: <CommercialInventoryAddEdit /> },

            { path: '/programming-requisition', element: <ProgrammingRequisitionMaster /> },
            { path: '/programming-requisition/add-programming-requisition', element: <ProgrammingRequisitionAddEdit /> },
            { path: '/programming-requisition/edit-programming-requisition/:id', element: <ProgrammingRequisitionAddEdit /> },

            { path: '/device-programming', element: <DeviceProgrammingMaster /> },
            { path: '/device-programming/add-device-programming', element: <DeviceProgrammingAddEdit /> },
            { path: '/device-programming/edit-device-programming/:id', element: <DeviceProgrammingAddEdit /> },

            { path: '/customer', element: <Customer /> },
            { path: '/customer/add-customer', element: <CustomerAddEdit /> },
            { path: '/customer/edit-customer/:id', element: <CustomerAddEdit /> },

            { path: '/product-output-function-id-master', element: <GAAProductOutputFunctionId /> },
            { path: '/product-output-function-id-master/add-product-output-function-id-master', element: <GAAProductOutputFunctionIdAddEdit /> },
            { path: '/product-output-function-id-master/edit-product-output-function-id-master/:id', element: <GAAProductOutputFunctionIdAddEdit /> },

            { path: '/product-output-function-type-master', element: <GAAProductOutputFunctionType /> },
            { path: '/product-output-function-type-master/add-product-output-function-type-master', element: <GAAProductOutputFunctionTypeAddEdit /> },
            { path: '/product-output-function-type-master/edit-product-output-function-type-master/:id', element: <GAAProductOutputFunctionTypeAddEdit /> },

            { path: '/product-operation-modes-master', element: <GAAProductModesMaster /> },
            { path: '/product-operation-modes-master/add-product-operation-modes-master', element: <GAAProductModesMasterAddEdit /> },
            { path: '/product-operation-modes-master/edit-product-operation-modes-master/:id', element: <GAAProductModesMasterAddEdit /> },

            { path: '/product-input-driver-channel-master', element: <GAAProductInputChannel /> },
            { path: '/product-input-driver-channel-master/add-product-input-driver-channel-master', element: <GAAProductInputChannelAddEdit /> },
            { path: '/product-input-driver-channel-master/edit-product-input-driver-channel-master/:id', element: <GAAProductInputChannelAddEdit /> },

            { path: '/product-input-driver-channel-device-type-master', element: <GAAProductOutputDriverChannelDeviceType /> },
            { path: '/product-input-driver-channel-device-type-master/add-product-input-driver-channel-device-type-master', element: <GAAProductOutputDriverChannelsAddEdit /> },
            { path: '/product-input-driver-channel-device-type-master/edit-product-input-driver-channel-device-type-master/:id', element: <GAAProductOutputDriverChannelsAddEdit /> },

            { path: '/product-output-driver-channel-master', element: <GAAProductOutputDriverChannel /> },
            { path: '/product-output-driver-channel-master/add-product-output-driver-channel-master', element: <GAAProductOutputDriverChannelAddEdit /> },
            { path: '/product-output-driver-channel-master/edit-product-output-driver-channel-master/:id', element: <GAAProductOutputDriverChannelAddEdit /> },

            // ########################### Gladiance Part Ended ###########################


            // ########################### Hotel Routes Started ###########################

            // =========================  Super Admin Management Started =========================
            { path: '/gaa-project', element: <GAAProject /> },
            { path: '/gaa-project/add-gaa-project', element: <GAAProjectAddEdit /> },
            { path: '/gaa-project/edit-gaa-project/:id', element: <GAAProjectAddEdit /> },

            { path: '/rights-registration', element: <RightsRegistration /> },
            // =========================  Super Admin Management Started =========================


            // =========================  User Management Started =========================
            { path: '/project-department-master', element: <GAAProjectDepartment Rights={HotelUserMapping.UserManagement} /> },
            { path: '/project-department-master/add-project-department-master', element: <GAAProjectDepartmentAddEdit Rights={{ Edit: true }} /> },
            { path: '/project-department-master/edit-project-department-master/:id', element: <GAAProjectDepartmentAddEdit Rights={HotelUserMapping.UserManagement} /> },

            { path: '/project-job-title-master', element: <GAAProjectJobTitle Rights={HotelUserMapping.UserManagement} /> },
            { path: '/project-job-title-master/add-project-job-title-master', element: <GAAProjectJobTitleAddEdit Rights={{ Edit: true }} /> },
            { path: '/project-job-title-master/edit-project-job-title-master/:id', element: <GAAProjectJobTitleAddEdit Rights={HotelUserMapping.UserManagement} /> },

            { path: '/project-user-role-master', element: <GAAProjectUserRole Rights={HotelUserMapping.UserManagement} /> },
            { path: '/project-user-role-master/add-project-user-role-master', element: <GAAProjectUserRoleAddEdit Rights={{ Edit: true }}/> },
            { path: '/project-user-role-master/edit-project-user-role-master/:id', element: <GAAProjectUserRoleAddEdit Rights={HotelUserMapping.UserManagement} /> },

            { path: '/project-user-master', element: <GAAProjectUser Rights={HotelUserMapping.UserManagement} /> },
            { path: '/project-user-master/add-project-user-master', element: <GAAProjectUserAddEdit Rights={{ Edit: true }} /> },
            { path: '/project-user-master/edit-project-user-master/:id', element: <GAAProjectUserAddEdit Rights={HotelUserMapping.UserManagement} /> },
            // =========================  User Management Ended =========================


            // =========================  Room Inventory Started =========================
            { path: '/gaa-project-space', element: <GAAProjectSpace Rights={HotelUserMapping.RoomInventory} /> },
            { path: '/gaa-project-space/add-gaa-project-space', element: <GAAProjectSpaceAddEdit Rights={{ Edit: true }} /> },
            { path: '/gaa-project-space/edit-gaa-project-space/:id', element: <GAAProjectSpaceAddEdit Rights={HotelUserMapping.RoomInventory} /> },

            { path: '/gaa-project-space-group', element: <GAAProjectSpaceGroup Rights={HotelUserMapping.RoomInventory} /> },
            { path: '/gaa-project-space-group/add-gaa-project-space-group', element: <GAAProjectSpaceGroupAddEdit Rights={{ Edit: true }} /> },
            { path: '/gaa-project-space-group/edit-gaa-project-space-group/:id', element: <GAAProjectSpaceGroupAddEdit Rights={HotelUserMapping.RoomInventory} /> },

            { path: '/gaa-project-space-type', element: <GAAProjectSpaceType Rights={HotelUserMapping.RoomInventory} /> },
            { path: '/gaa-project-space-type/add-gaa-project-space-type', element: <GAAProjectSpaceTypeAddEdit Rights={{ Edit: true }} /> },
            { path: '/gaa-project-space-type/edit-gaa-project-space-type/:id', element: <GAAProjectSpaceTypeAddEdit Rights={HotelUserMapping.RoomInventory} /> },

            { path: '/gaa-project-space-type-area', element: < GAAProjectSpaceTypeArea Rights={HotelUserMapping.RoomInventory} /> },
            { path: '/gaa-project-space-type-area/add-gaa-project-space-type-area', element: < GAAProjectSpaceTypeAreaAddEdit Rights={{ Edit: true }} /> },
            { path: '/gaa-project-space-type-area/edit-gaa-project-space-type-area/:id', element: <GAAProjectSpaceTypeAreaAddEdit Rights={HotelUserMapping.RoomInventory} /> },

            { path: '/gaa-area-wifi-configuration', element: <GAAAreaWifiConfiguration Rights={HotelUserMapping.RoomInventory} /> },
            { path: '/gaa-area-wifi-configuration/edit-gaa-area-wifi-configuration', element: <GAAAreaWifiConfigurationAddEdit Rights={HotelUserMapping.RoomInventory}/>  },
            // =========================  Room Inventory Ended =========================


            // =========================  Device Inventory Started =========================
            { path: '/gaa-project-space-type-planned-device', element: <GAAProjectSpaceTypePlannedDevice Rights={HotelUserMapping.DeviceInventory} /> },
            { path: '/gaa-project-space-type-planned-device/add-gaa-project-space-type-planned-device', element: <GAAProjectSpaceTypePlannedDeviceAddEdit Rights={{ Edit: true }} /> },
            { path: '/gaa-project-space-type-planned-device/edit-gaa-project-space-type-planned-device/:id', element: <GAAProjectSpaceTypePlannedDeviceAddEdit Rights={HotelUserMapping.DeviceInventory} /> },

            { path: '/gaa-project-space-type-planned-device-Connections', element: <GAAProjectSpaceTypePlannedDeviceConnections Rights={HotelUserMapping.DeviceInventory} /> },
            { path: '/gaa-project-space-type-planned-device-Connections/add-gaa-project-space-type-planned-device-Connections', element: <GAAProjectSpaceTypePlannedDeviceConnectionsAddEdit Rights={{ Edit: true }} /> },
            { path: '/gaa-project-space-type-planned-device-Connections/edit-gaa-project-space-type-planned-device-Connections/:id', element: <GAAProjectSpaceTypePlannedDeviceConnectionsAddEdit Rights={HotelUserMapping.DeviceInventory} /> },
            // =========================  Device Inventory Ended =========================


            // =========================  Room Scene Inventory Started =========================
            { path: '/room-scene', element: <RoomScene Rights={HotelUserMapping.RoomSceneInventory} /> },
            { path: '/room-scene/add-room-scene', element: <RoomSceneAddEdit Rights={{ Edit: true }} /> },
            { path: '/room-scene/edit-room-scene/:id', element: <RoomSceneAddEdit Rights={HotelUserMapping.RoomSceneInventory} /> },

            { path: '/scene-configurations', element: <RoomSceneConfiguration Rights={HotelUserMapping.RoomSceneInventory} /> },
            { path: '/scene-configurations/add-scene-configurations', element: <RoomSceneConfigurationAddEdit Rights={{ Edit: true }} /> },
            { path: '/scene-configurations/edit-scene-configurations/:GAAProjectSceneRef/:GAAProjectSpaceTypeRef/:GAAProjectSpaceTypePlannedDeviceConnectionRef', element: <RoomSceneConfigurationAddEdit Rights={HotelUserMapping.RoomSceneInventory} /> },
            // =========================  Room Scene Inventory Ended =========================


            // =========================  Miscellaneous Inventory  Ended =========================
            { path: '/miscellaneous-parameter', element: <MiscellaneousParameter Rights={HotelUserMapping.MiscellaneousInventory} /> },
            { path: '/miscellaneous-parameter/add-miscellaneous-parameter', element: <MiscellaneousParameterAddEdit Rights={{ Edit: true }} /> },
            { path: '/miscellaneous-parameter/add-miscellaneous-parameter', element: <MiscellaneousParameterAddEdit Rights={HotelUserMapping.MiscellaneousInventory} /> },
            // =========================  Miscellaneous Inventory  Ended =========================


            // =========================  Television Inventory  Ended =========================
            { path: '/television-master', element: <TelevisionMaster Rights={HotelUserMapping.TelevisionInventory} /> },
            { path: '/television-master/add-television', element: <TelevisionMasterAddEdit Rights={{ Edit: true }} /> },
            { path: '/television-master/edit-television/:id', element: <TelevisionMasterAddEdit Rights={HotelUserMapping.TelevisionInventory} /> },
            // =========================  Television Inventory  Ended =========================


            // ========================= Dining Routes Started =========================         
            { path: '/RB-item-serving-slot-master', element: <ProjectRBItemServingSlotMaster Rights={HotelUserMapping.InRoomDiningInventory} /> },
            { path: '/RB-item-serving-slot-master/add-RB-item-serving-slot-master', element: <ProjectRBItemServingSlotAddEdit Rights={{ Edit: true }} /> },
            { path: '/RB-item-serving-slot-master/edit-RB-item-serving-slot-master/:id', element: <ProjectRBItemServingSlotAddEdit Rights={HotelUserMapping.InRoomDiningInventory} /> },

            { path: '/rb-item-master', element: <GAAProjectRBItemMaster Rights={HotelUserMapping.InRoomDiningInventory} /> },
            { path: '/rb-item-master/add-rb-item-master', element: <GAAProjectRBItemMasterAddEdit Rights={{ Edit: true }} /> },
            { path: '/rb-item-master/edit-rb-item-master/:id', element: <GAAProjectRBItemMasterAddEdit Rights={HotelUserMapping.InRoomDiningInventory} /> },

            { path: '/rb-item-applicable-serving-slots', element: <GAAProjectRBItemApplicableServingSlots Rights={HotelUserMapping.InRoomDiningInventory} /> },
            { path: '/rb-item-applicable-serving-slots/add-/rb-item-applicable-serving-slots', element: <GAAProjectRBItemApplicableServingSlotsAddEdit Rights={{ Edit: true }} /> },
            { path: '/rb-item-applicable-serving-slots/edit-/rb-item-applicable-serving-slots/:GAAProjectRBItemRef/:GAAProjectRBItemServingSlotRef', element: <GAAProjectRBItemApplicableServingSlotsAddEdit Rights={HotelUserMapping.InRoomDiningInventory} /> },
            // ========================= Dining Routes Ended =========================            


            // =========================  Laundry Inventory Started =========================
            { path: '/laundry', element: <Laundry Rights={HotelUserMapping.LaundryInventory} /> },
            { path: '/laundry/add-laundry', element: <LaundryAddEdit Rights={{ Edit: true }} /> },
            { path: '/laundry/edit-laundry/:id', element: <LaundryAddEdit Rights={HotelUserMapping.LaundryInventory} /> },
            // =========================  Laundry Inventory  Ended =========================


            // =========================  Information Inventory  Started =========================
            { path: '/amenities', element: <Amenities Rights={HotelUserMapping.InformationInventory} /> },
            { path: '/amenities/add-amenities', element: <AmenitiesAddEdit Rights={{ Edit: true }} /> },
            { path: '/amenities/edit-amenities/:id', element: <AmenitiesAddEdit Rights={HotelUserMapping.InformationInventory} /> },

            { path: '/telephone', element: <Telephone Rights={HotelUserMapping.InformationInventory} /> },
            { path: '/telephone/add-telephone', element: <TelephoneAddEdit Rights={{ Edit: true }} /> },
            { path: '/telephone/edit-telephone/:id', element: <TelephoneAddEdit Rights={HotelUserMapping.InformationInventory} /> },

            { path: '/key-contact', element: <KeyContact Rights={HotelUserMapping.InformationInventory} /> },
            { path: 'key-contact/add-key-contact', element: <KeyContactAddEdit Rights={{ Edit: true }} /> },
            { path: '/key-contact/edit-key-contact/:id', element: <KeyContactAddEdit Rights={HotelUserMapping.InformationInventory} /> },

            { path: '/feedback', element: <Feedback Rights={HotelUserMapping.InformationInventory} /> },
            { path: '/feedback/add-feedback', element: <FeedbackAddEdit Rights={{ Edit: true }} /> },
            { path: '/feedback/edit-feedback/:id', element: <FeedbackAddEdit Rights={HotelUserMapping.InformationInventory} /> },
            // =========================  Information Inventory  Ended =========================


            // =========================  Guest Management Started =========================
            { path: '/reservation', element: <Reservation Rights={HotelUserMapping.GuestManagement} /> },
            { path: '/reservation/add-reservation', element: <ReservationAddEdit Rights={{ Edit: true }} /> },
            { path: '/reservation/edit-reservation/:id', element: <ReservationAddEdit Rights={HotelUserMapping.GuestManagement} /> },

            { path: '/check-in', element: <CheckIn Rights={HotelUserMapping.GuestManagement} /> },
            { path: '/check-in/add-check-in', element: <CheckInAddEdit Rights={{ Edit: true }} /> },
            { path: '/check-in/edit-check-in/:id', element: <CheckInAddEdit Rights={HotelUserMapping.GuestManagement} /> },

            { path: '/check-out', element: <CheckOut Rights={HotelUserMapping.GuestManagement} /> },
            { path: '/check-out/add-check-out', element: <CheckOutAddEdit Rights={{ Edit: true }} /> },
            { path: '/check-out/edit-check-out/:id', element: <CheckOutAddEdit Rights={HotelUserMapping.GuestManagement} /> },

            { path: '/bill-view-management', element: <BillViewManagement Rights={HotelUserMapping.GuestManagement} /> },
            { path: '/bill-view-management/add-bill-view-management', element: <BillViewManagementAddEdit Rights={{ Edit: true }} /> },
            { path: '/bill-view-management/edit-bill-view-management/:id', element: <BillViewManagementAddEdit Rights={HotelUserMapping.GuestManagement} /> },
            // =========================  Guest Management Ended =========================


            // =========================  Door Camera Started =========================
            { path: '/door-camera', element: <DoorCamera Rights={HotelUserMapping.DoorCamera} /> },
            { path: '/door-camera/add-door-camera', element: <DoorCameraAddEdit Rights={{ Edit: true }} /> },
            { path: '/door-camera/edit-door-camera/:id', element: <DoorCameraAddEdit Rights={HotelUserMapping.DoorCamera} /> },
            // =========================  Door Camera Ended =========================


            // =========================  Surveillance Started =========================
            { path: '/surveillance', element: <Surveillance Rights={HotelUserMapping.SurveillanceInventory} /> },
            { path: '/surveillance/add-surveillance', element: <SurveillanceAddEdit Rights={{ Edit: true }} /> },
            { path: '/surveillance/edit-surveillance/:id', element: <SurveillanceAddEdit Rights={HotelUserMapping.SurveillanceInventory} /> },
            // =========================  Surveillance Ended =========================


            // =========================  Promotion Management Started =========================
            { path: '/promotion-management', element: <PromotionsManagement Rights={HotelUserMapping.PromotionsManagement} /> },
            { path: '/promotion-management/add-promotion-management', element: <PromotionsManagementAddEdit Rights={{ Edit: true }} /> },
            { path: '/promotion-management/edit-promotion-management/:id', element: <PromotionsManagementAddEdit Rights={HotelUserMapping.PromotionsManagement} /> },
            // =========================  Promotion Management Ended =========================


            // =========================  Message Management Started =========================
            { path: '/message-management', element: <MessageManagement Rights={HotelUserMapping.MessagingManagement} /> },
            { path: '/message-management/add-message-management', element: <MessageManagementAddEdit Rights={{ Edit: true }} /> },
            { path: '/message-management/edit-message-management/:id', element: <MessageManagementAddEdit Rights={HotelUserMapping.MessagingManagement} /> },
            // =========================  Message Management Ended =========================


            // ################################# Hotel Routes Ended #################################
        ]
    }, {
        path: '*',
        element: <PageNotFound />
    }
]);

export default Router