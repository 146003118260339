import React, { useEffect, Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetGAAProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import {ClearInfo,} from "../../../../Encapsulation/Utils";
import Loader from "../../../Common/Loader";
import BackComponent from "../../../../components/BackComponent";
import { ArrowCircleLeft2 } from "iconsax-react";
import Card from "../../../helpers/Card";
import Input from "../../../helpers/Input";
import Label from "../../../helpers/Label";
import Validation from "../../../helpers/Validation";
import SaveButton from "../../../helpers/SaveButton";
import CancelButton from "../../../helpers/CancelButton";
import LinkButton from "../../../helpers/LinkButton";
import flasher from "@flasher/flasher";
import { GetRBItemServingSlot, SaveRBItemServingSlot } from "../../../../Encapsulation/HotelDomainEntities/RBItemServingSlot";

function ProjectRBItemServingSlotAddEdit({Rights}) {
  let formMode = "New";
  const { id } = useParams();
  if (id) {
    document.title = "Gladiance - Edit RB item serving slot";
    formMode = "Edit";
  } else {
    document.title = "Gladiance - Add RB item serving slot";
  }
  const Navigation = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const Location = useLocation();

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveRBItemServingSlot(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/RB-item-serving-slot-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProject();
      }
    }
  };
  const fetchProject = async () => {
    let Project = await GetGAAProject(Location.state);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name,
    }));
  };

  const fetchRBItemServingSlot = async () => {
    setFormInfo (await GetRBItemServingSlot(id));
  };

  useEffect(() => {
    if (Location.state) {
      fetchProject();
    }
    if (id) {
      fetchRBItemServingSlot();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit RB item serving slot" : "Add RB item serving slot"}
          button={
            <LinkButton to={"/RB-item-serving-slot-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProjectName"}
                      labelText={"Project Name"}
                    />
                    {formInfo.GAAProjectRef && (
                      <Input
                        id={"GAAProjectName"}
                        type={"text"}
                        name={"GAAProjectName"}
                        value={formInfo.GAAProjectName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"Name"}
                      labelText={"Name"}
                    />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      inputFocus={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"FromTime"}
                      labelText={"From Time"}
                    />
                    <Input
                      id={"FromTime"}
                      type={"time"}
                      name={"FromTime"}
                      value={formInfo.FromTime}
                      onChange={handleChange}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.FromTime} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"ToTime"}
                      labelText={"To Time"}
                    />
                    <Input
                      id={"ToTime"}
                      type={"time"}
                      name={"ToTime"}
                      value={formInfo.ToTime}
                      onChange={handleChange}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.ToTime} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/RB-item-serving-slot-master"} />
                  {Rights.Edit && <SaveButton text="Save" />}
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default ProjectRBItemServingSlotAddEdit;
