import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import Label from "../../helpers/Label";
import Card from "../../helpers/Card";
import Input from "../../helpers/Input";
import LinkButton from "../../helpers/LinkButton";
import Loader from "../../Common/Loader";
import Validation from "../../helpers/Validation";
import { useStateContext } from "../../../context/ContextProvider";
import { AddCircle, ArrowCircleLeft2 } from "iconsax-react";
import CancelButton from "../../helpers/CancelButton";
import CheckBox from "../../helpers/CheckBox";
import SaveButton from "../../helpers/SaveButton";
import { GetComboList, SaveCombo, } from "../../../Encapsulation/GladianceDomainEntities/Combo";
import { ClearInfo } from "../../../Encapsulation/Utils";
import flasher from "@flasher/flasher";

function GAAComboMasterAddEdit() {
  let formMode = "New";
  let { id } = useParams();
  if (id) {
    document.title = "Gladiance - Edit Ambiance Automation Combo";
    formMode = "Edit";
  } else {
    document.title = "Gladiance - Add Ambiance Automation Combo";
  }
  const { theme } = useStateContext();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const { FRONTEND_URL, IMG_URL } = useStateContext();
  const [formInfo, setFormInfo] = useState({})
  const [imgInfo, setImgInfo] = useState({})

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveCombo(
      id, formInfo,
      [
        ["ComboImage", imgInfo.ComboImage],
        ["CompositionImage", imgInfo.CompositionImage],
      ]
    );
    console.log('result :', result);

    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      if (formMode == 'Edit') {
        Navigation("/gladiance-combo");
      }
      else {
        setFormInfo(ClearInfo(formInfo))
        setImgInfo(ClearInfo(imgInfo))
      }
    }
  };

  const fetchCombo = async () => {
    let Combo = await GetComboList(id);
    setFormInfo(Combo);
  };

  useEffect(() => {
    if (id) {
      fetchCombo();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={
            id
              ? "Edit Ambiance Automation Combo"
              : "Add Ambiance Automation Combo"
          }
          button={
            <LinkButton to={"/gladiance-combo"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div className="md:relative">
                    <div className="md:absolute w-full bottom-0">
                      <Label htmlFor={"Code"} labelText={"Combo Code"} />
                      <Input
                        inputFocus={true}
                        id={"Code"}
                        type={"text"}
                        name={"Code"}
                        value={formInfo.Code}
                        onChange={handleChange}
                        onKeyPress={(event) => {
                          if (!/[A-Za-z]|\ /.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <Validation error={errors.Code} />
                    </div>
                  </div>
                  <div className="md:relative">
                    <div className="md:absolute w-full bottom-0">
                      <Label htmlFor={"Name"} labelText={"Name"} />
                      <Input
                        id={"Name"}
                        inputFocus={true}
                        type={"text"}
                        name={"Name"}
                        value={formInfo.Name}
                        onChange={handleChange}
                      
                      />
                      <Validation error={errors.Name} />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor={"ComboImage"} labelText={"Combo Image"} />
                    <img
                      src={
                        imgInfo.ComboImage
                          ? imgInfo.ComboImage.name
                            ? URL.createObjectURL(imgInfo.ComboImage)
                            : IMG_URL +
                            "/assets/images/Userimages/" +
                            imgInfo.ComboImage
                          : FRONTEND_URL + "/assets/images/noimage.png"
                      }
                      alt="profile-img"
                      className="w-32 h-32 rounded"
                    />
                    <Input
                      id={"ComboImage"}
                      type={"file"}
                      name={"ComboImage"}
                      onChange={(e) => setImgInfo({ ...imgInfo, ComboImage: e.target.files[0] })}
                      className={"p-[2px!important] mt-2"}
                    />
                    <Validation error={errors.ComboImage} />
                  </div>
                </div>
                <div>
                  <Label
                    htmlFor={"Verticals"}
                    labelText={"Verticals"}
                    className={"font-bold"}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-8 gap-4 mb-4">
                    <div className="flex justify-center">
                      <CheckBox
                        id={"Hotel"}
                        name={"Hotel"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({ ...formInfo, Hotel: e.target.checked })
                        }
                        checked={formInfo.Hotel}
                      />
                      <Label htmlFor={"Hotel"} labelText={"Hotel"} />
                    </div>
                    <div className="flex justify-center">
                      <CheckBox
                        id={"Home"}
                        name={"Home"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({ ...formInfo, Home: e.target.checked })
                        }
                        checked={formInfo.Home}
                      />
                      <Label htmlFor={"Home"} labelText={"Home"} />
                    </div>
                    <div className="flex justify-center">
                      <CheckBox
                        id={"Office"}
                        name={"Office"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({ ...formInfo, Office: e.target.checked })
                        }
                        checked={formInfo.Office}
                      />
                      <Label htmlFor={"Office"} labelText={"Office"} />
                    </div>
                    <div className="flex justify-center">
                      <CheckBox
                        id={"Retail"}
                        name={"Retail"}
                        type={"checkbox"}
                        onClick={(e) =>
                          setFormInfo({ ...formInfo, Retail: e.target.checked })
                        }
                        checked={formInfo.Retail}
                      />
                      <Label htmlFor={"Retail"} labelText={"Retail"} />
                    </div>
                  </div>
                </div>
                <h1
                  className={
                    "font-bold mb-2" +
                    (theme ? "text-default-color" : "text-gray-800")
                  }
                >
                  Composition -
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-3">
                  <div>
                    <Label htmlFor={"ProductName"} labelText={"Product Name"} />
                    <Input
                      id={"ProductName"}
                      type={"text"}
                      name={"ProductName"}
                      value={formInfo.ProductName}
                      onChange={handleChange}
                      onKeyPress={(event) => {
                        if (!/[A-Za-z]|\ /.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <Validation error={errors.ProductName} />
                  </div>
                  <div>
                    <Label htmlFor={"Quantity"} labelText={"Quantity"} />
                    <Input
                      id={"Quantity"}
                      type={"text"}
                      name={"Quantity"}
                      value={formInfo.Quantity}
                      onChange={handleChange}
                    />
                    <Validation error={errors.Quantity} />
                  </div>
                  <div className="md:relative">
                    <div className="md:absolute w-full bottom-0">
                      <Label htmlFor={"Rate"} labelText={"Rate"} />
                      <Input
                        id={"Rate"}
                        type={"text"}
                        name={"Rate"}
                        value={formInfo.Rate}
                        onChange={handleChange}

                      />

                    
                      <Validation error={errors.Rate} />
                    </div>
                  </div>
                  <div>
                    <Label
                      htmlFor={"CompositionImage"}
                      labelText={"Composition Image"}
                    />
                    <img
                      src={
                        imgInfo.CompositionImage
                          ? imgInfo.CompositionImage.name
                            ? URL.createObjectURL(imgInfo.CompositionImage)
                            : IMG_URL +
                            "/assets/images/Userimages/" +
                            imgInfo.CompositionImage
                          : FRONTEND_URL + "/assets/images/noimage.png"
                      }
                      alt="profile-img"
                      className="w-32 h-32 rounded"
                    />
                    <Input
                      id={"CompositionImage"}
                      type={"file"}
                      name={"CompositionImage"}
                      onChange={(e) => setImgInfo({ ...imgInfo, CompositionImage: e.target.files[0] })}
                      className={"p-[2px!important] mt-2"}
                    />
                    <Validation error={errors.CompositionImage} />
                  </div>
                  <div>
                    <button className="group relative flex justify-centet rounded-md bg-transparent text-orange-500 w-[12vw]  p-2 gap-5 tracking-wider font-normal duration-300">
                      {" "}
                      Add Product
                      <AddCircle variant="Outline" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/gladiance-combo"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAComboMasterAddEdit;
