import React, { Fragment } from 'react'
import { useStateContext } from '../context/ContextProvider'


function AddComponent({ children, title, button, className }) {
    const { FRONTEND_URL, BACKEND_URL, IMG_URL, theme } = useStateContext()

    return (
        <Fragment>
            <div className={'h-[100%] md:w-[99%] w-[95%] mx-auto rounded-lg border-[rgba(255,255,255,0.5)] border-[1px] ' + (theme ? "text-white bg-[#222222] " : "text-black bg-gray-200 ")}>
                <header>
                    <div  className={"mx-auto px-4 py-3 sm:px-6lg:px-8 flex justify-between items-center" + (className)} >
                        <h1 id='OpenForm' name='OpenForm' className={"text-[4vw] md:text-2xl font-bold tracking-tight "+ (theme ? "text-default-color " : " text-gray-800 ")}>{title}</h1>
                        {button}
                    </div>
                </header>
                <main className='overflow-x-auto journal-scroll h-[85%]'>
                    {
                        children && (
                            <div className="mx-auto px-4 md:px-4 pb-3">
                                {children}
                            </div>
                        )
                    }
                </main>
            </div>
        </Fragment>
    )
}

export default AddComponent