import React, { Fragment, useEffect, useState } from "react";
import { DeleteRBItemServingSlot, GetRBItemServingSlotListByProject } from "../../../../Encapsulation/HotelDomainEntities/RBItemServingSlot";
import { GetGAAProjectList, GetGAAProjectListByCustomer } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import Loader from "../../../Common/Loader";
import AddComponent from "../../../../components/AddComponent";
import Label from "../../../helpers/Label";
import CustomSelect from "../../../helpers/CustomSelect";
import Table from "../../../helpers/Table";
import DataNotFound from "../../../helpers/DataNotFound";
import Pagination from "../../../helpers/Pagination";
import LinkButton from "../../../helpers/LinkButton";
import { AddCircle } from "iconsax-react";
import Option from "../../../helpers/Option";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useStateContext } from "../../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProjectRBItemServingSlotMaster({ Rights }) {
  document.title = "Gladiance - RB item serving slot";
  const tableHeading = [
    "Sr.No",
    "Name",
    "From Time",
    "To Time",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rbitemservingslotList, setRBItemServingList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const { GAAProjectRef, handleProjectRef,CustomerRef, handleCustomerRef } =   useStateContext();

   //Fetch Customer List For Dropdown//
const fetchCustomerList = async () => {
  setCustomerList(await GetGladianceCustomerList());
};

// Fetch Project List For Dropdown //
const fetchProjectListByCustomer = async () => {
    CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
};
  const itemsPerPage = 7;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchRBItemServingListByProject = async () => {
    GAAProjectRef &&
      setRBItemServingList(await GetRBItemServingSlotListByProject(GAAProjectRef));
  };


  const deleteRBItemServing = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteRBItemServingSlot(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchRBItemServingListByProject();
        }
      }
    })
  }

  
  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
      fetchProjectListByCustomer();
    }, [CustomerRef]);

useEffect(() => {
    if (GAAProjectRef == '' && ProjectList.length > 0) {
        handleProjectRef(ProjectList[0].Ref);
    }
}, [ProjectList]);

  useEffect(() => {
    fetchRBItemServingListByProject();
  }, [GAAProjectRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"RB Item Serving Slots"}
          button={
            <LinkButton to={"/RB-item-serving-slot-master/add-RB-item-serving-slot-master"}  className={Rights.Add ? 'opacity-100' : 'hidden'} state={GAAProjectRef}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Serving Slots</span>
            </LinkButton>
          }
        >
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                    <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
                        <div>
                            <Label
                                htmlFor={"GAAprojectRef"}
                                labelText={"Project"}
                            />
                            <CustomSelect
                                id={"GAAprojectRef"}
                                name={"GAAprojectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => handleProjectRef(e.target.value)}
                                required={true}
                            >
                                {ProjectList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                    </div>
          {rbitemservingslotList && rbitemservingslotList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {rbitemservingslotList
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white text-center">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">{tableData.FromTime}</td>
                          <td className="px-4 py-3">{tableData.ToTime}</td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/RB-item-serving-slot-master/edit-RB-item-serving-slot-master/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                              <ActionButton onClick={() => deleteRBItemServing(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                                  )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Serving Slot Data Not Found"} />
          )}
          {rbitemservingslotList && rbitemservingslotList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={rbitemservingslotList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default ProjectRBItemServingSlotMaster;
