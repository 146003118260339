import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../../components/BackComponent'
import Label from '../../../helpers/Label'
import Card from '../../../helpers/Card'
import Input from '../../../helpers/Input'
import LinkButton from '../../../helpers/LinkButton'
import Loader from '../../../Common/Loader'
import Validation from '../../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../../helpers/CancelButton'
import SaveButton from '../../../helpers/SaveButton'
import { ClearInfo } from '../../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import { GetProjectSpaceTypeArea, SaveProjectSpaceTypeArea } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea'
import { GetProjectSpaceType } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType'


function GAAProjectSpaceTypeAreaAddEdit({Rights}) {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Gladiance - Edit GAA Project Space Type Area";
      formMode = "Edit";
    } else {
      document.title = "Gladiance - Add GAA Project Space Type Area";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation()
  const [formInfo, setFormInfo] = useState({});
  const [spaceTypeName, setSpaceTypeName] = useState([])


  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

// Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveProjectSpaceTypeArea(id, formInfo);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/gaa-project-space-type-area");
      } else {
        setFormInfo(ClearInfo(formInfo)); 
        fetchSpaceType();                 // Save GAAProjectSpaceTypeRef 
      }
      console.log("result :", result);
    }
  };

      // Fetch project Details Using Location State Space Type Name//
  const fetchSpaceType = async () => {
    let SpaceType = await GetProjectSpaceType(Location.state);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectSpaceTypeRef: SpaceType.Ref,
      GAAProjectSpaceTypeName: SpaceType.Name,
      
    }))
    setSpaceTypeName(SpaceType.Name);
  };
 
   // Fetch Project Space Type Area By Id //
   const fetchProjectSpaceTypeArea = async () => {
    let data = await GetProjectSpaceTypeArea(id)
    setFormInfo(data);
 //   setSpaceTypeName(data.GAAProjectSpaceTypeName); 
}

  useEffect(() => {
    if (Location.state) {
      fetchSpaceType();
    }
    if(id)
    {                              // Fetch Project Space Type Area By Id //
      fetchProjectSpaceTypeArea();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit GAA Project Space Type Area" : "Add GAA Project Space Type Area"}
          button={
            <LinkButton to={"/gaa-project-space-type-area"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"GAAProjectSpaceTypeName"} labelText={"Project Space Type Name"} />
                    {formInfo.GAAProjectSpaceTypeRef && (<Input
                      id={"GAAProjectSpaceTypeName"}
                      type={"text"}
                      name={"GAAProjectSpaceTypeName"}
                      value={formInfo.GAAProjectSpaceTypeName}
                      required={true}
                      readOnly={true}
                    />
                    )}
                    <Validation error={errors.Address} />
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      inputFocus={true}
                      readOnly={!Rights.Edit}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Name} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/gaa-project-space-type-area"} />
                  {
                    Rights.Edit && <SaveButton text="Save" />
                  }
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceTypeAreaAddEdit;
