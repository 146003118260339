import React, { Fragment, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BackComponent from '../../../../components/BackComponent'
import Label from '../../../helpers/Label'
import Card from '../../../helpers/Card'
import Input from '../../../helpers/Input'
import LinkButton from '../../../helpers/LinkButton'
import Loader from '../../../Common/Loader'
import Validation from '../../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../../helpers/CancelButton'
import SaveButton from '../../../helpers/SaveButton'
import flasher from '@flasher/flasher'
import { SaveGAAWifiConfiguration } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAWifiConfiguration'

function GAAAreaWifiConfigurationAddEdit({Rights}) {

  document.title = 'Gladiance - Edit Wifi Configuration'


  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const Navigation = useNavigate()
  const Location = useLocation()
  const [formInfo, setFormInfo] = useState(Location.state)

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGAAWifiConfiguration(formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      Navigation('/gaa-area-wifi-configuration');
    }
  }


  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={'Edit Wifi Configuration'} button={
          <LinkButton to={'/gaa-area-wifi-configuration'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'GAAProjectSpaceName'} labelText={'Space'} />
                    <Input id={'GAAProjectSpaceName'} type={'text'} name={'GAAProjectSpaceName'} value={formInfo.GAAProjectSpaceName} required={true} readOnly={true} />
                    <Validation error={errors.GAAProjectSpaceName} />
                  </div>
                  <div>
                    <Label htmlFor={'GAAProjectSpaceTypeAreaName'} labelText={'Area'} />
                    <Input id={'GAAProjectSpaceTypeAreaName'} type={'text'} name={'GAAProjectSpaceTypeAreaName'} value={formInfo.GAAProjectSpaceTypeAreaName} required={true} readOnly={true} />
                    <Validation error={errors.GAAProjectSpaceTypeAreaName} />
                  </div>
                  <div>
                    <Label htmlFor={'WifiSSID'} labelText={'Wifi SSID'} />
                    <Input id={'WifiSSID'} type={'text'} name={'WifiSSID'} value={formInfo.WifiSSID} readOnly={!Rights.Edit} required={true} onChange={handleChange} />
                    <Validation error={errors.WifiSSID} />
                  </div>
                  <div>
                    <Label htmlFor={'WifiPassword'} labelText={'Wifi Password'} />
                    <Input id={'WifiPassword'} type={'text'} name={'WifiPassword'} value={formInfo.WifiPassword}  readOnly={!Rights.Edit} required={true} onChange={handleChange} />
                    <Validation error={errors.WifiPassword} />
                  </div>
                </div>

                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/gaa-area-wifi-configuration'} />
                  {
                    Rights.Edit && <SaveButton text="Save" />
                  }
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  )
}




export default GAAAreaWifiConfigurationAddEdit
