import React, { Fragment, useState } from 'react'
import AddComponent from '../../components/AddComponent'
import Card from '../helpers/Card'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import axiosClient from '../../Encapsulation/Axios'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import flasher from '@flasher/flasher'
import { useNavigate } from 'react-router-dom'
import { DirectInbox } from 'iconsax-react'

function ChangePassword() {
    document.title = 'Gladiance - Change Password'
    const [formInfo, setFormInfo] = useState({
        old_password: '',
        new_password: '',
    })
    const [loader, setLoader] = useState(false);
    const handleChange = (e) => { setFormInfo(formInfo => ({ ...formInfo, [e.target.name]: e.target.value })) }
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('old_password', formInfo.old_password)
        formData.append('new_password', formInfo.new_password)
        axiosClient.post(`/change_password/${localStorage.getItem('CURRENT_USERID')}`, formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                navigate('/')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Your Profile'}>
                    <div className='flex justify-center items-center h-[20vh] md:h-[70vh]'>
                        <Card className={'w-full md:w-96 p-4'}>
                            <form onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    <Label htmlFor={'old_password'} labelText={'Old Password'} />
                                    <Input id={'old_password'} type={'text'} name={'old_password'} value={formInfo.old_password} onChange={handleChange} />
                                    <Validation error={errors.old_password} />
                                </div>
                                <div className='mb-3'>
                                    <Label htmlFor={'new_password'} labelText={'New Password'} />
                                    <Input id={'new_password'} type={'text'} name={'new_password'} value={formInfo.new_password} onChange={handleChange} />
                                    <Validation error={errors.new_password} />
                                </div>
                                <Button className={'w-full mt-4'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><DirectInbox variant='Bulk' className="h-5 w-5" /></span>
                                    <span>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </AddComponent>
            )}
        </Fragment>
    )
}

export default ChangePassword