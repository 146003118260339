import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProjectTelephoneNumbers";
const FetchRequestType = "GAAProjectTelephoneNumberFetchRequest";

// Save All Data To Database //
export const SaveHotelTelephone = async (ref, payload, images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};
// Get All Data From Database //
export const GetHotelTelephoneList = async () => {
  let payload = {GAAProjectTelephoneNumberRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

// Get Single Data From Database //
export const GetHotelTelephone = async (Ref) => {
  let payload = {GAAProjectTelephoneNumberRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteHotelTelephone = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetProjectTelephoneListByProject = async (projectRef) => {
  let payload = { GAAProjectRefs: [projectRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};
