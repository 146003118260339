import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft2 } from "iconsax-react";
import flasher from "@flasher/flasher";
import BackComponent from "../../../../components/BackComponent";
import Label from "../../../helpers/Label";
import Card from "../../../helpers/Card";
import Input from "../../../helpers/Input";
import LinkButton from "../../../helpers/LinkButton";
import Loader from "../../../Common/Loader";
import Validation from "../../../helpers/Validation";
import CancelButton from "../../../helpers/CancelButton";
import SaveButton from "../../../helpers/SaveButton";
import Option from "../../../helpers/Option";
import CustomSelect from "../../../helpers/CustomSelect";
import { ClearInfo } from "../../../../Encapsulation/Utils";
import { GetProductOutputFunctionIdList } from "../../../../Encapsulation/GladianceDomainEntities/GAAProductOutputFunctionId";
import { GetGAAProductOutputFunctionTypeList } from "../../../../Encapsulation/GladianceDomainEntities/GAAProductOutputFunctionType";
import {
  GAAProductInputDriverChannelListByProduct,
  GetGAAProductInputDriverChannelsList,
} from "../../../../Encapsulation/GladianceDomainEntities/GAAProductInputDriverChannel ";
import {
  GetGAAProductOutputDriverChannelList,
  GetGAAProductOutputDriverChannelListByProduct,
} from "../../../../Encapsulation/GladianceDomainEntities/GAAProductOutputDriverChannel";
import {
  GetGAAProjectSpaceTypePlannedDevice,
  GetGAAProjectSpaceTypePlannedDeviceByArea,
  GetGAAProjectSpaceTypePlannedDeviceBySpaceType,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDevice";
import {
  GetGAAProjectSpaceTypePlannedDeviceConnection,
  SaveGAAProjectSpaceTypePlannedDeviceConnection,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDeviceConnections";
import { GetGAAProjectSceneList } from "../../../../Encapsulation/HotelDomainEntities/HotelRoomScene";

function GAAProjectSpaceTypePlannedDeviceConnectionsAddEdit({ Rights }) {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Hotel - Edit Connection";
      formMode = "Edit";
    } else {
      document.title = "Hotel - Add Connection";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation();
  const [formInfo, setFormInfo] = useState({
    BindToGAAProjectSpaceTypePlannedDeviceRef: 0,
    GAAProjectSpaceTypePlannedDeviceRef: "",
    GAAProjectSpaceTypePlannedDeviceName: "",
    BindFrom: "input",
    BindTo: "input",
  });
  
  const [RoomSceneListList, setRoomSceneListList] =useState([]);
  const [GAAProductOutputFunctionIdList, setProductOutputFunctionIdList] =
  useState([]);
  const [
    GAAProductOutputFunctionTypeList,
    setGAAProductOutputFunctionTypeList,
  ] = useState([]);
  const [
    ProjectSpaceTypePlannedDeviceList,
    setProjectSpaceTypePlannedDeviceList,
  ] = useState([]);
  const [FromInputDriverChannelList, setFromInputDriverChannelList] = useState(
    []
    );
  const [ToInputDriverChannelList, setToInputDriverChannelList] = useState([]);
  const [FromOutputDriverChannelList, setFromOutputDriverChannelList] =
  useState([]);
  const [ToOutputDriverChannelList, setToOutputDriverChannelList] = useState(
    []
    );
    const [FromGAAProductRef, setFromGAAProductRef] = useState();
    const [ToGAAProductRef, setToGAAProductRef] = useState();
    
    const handleChange = (e) => {
      setFormInfo((formInfo) => ({
        ...formInfo,
        [e.target.name]: e.target.value,
    }));
  };
  
  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('formInfo :', formInfo);
    // let result = await SaveGAAProjectSpaceTypePlannedDeviceConnection(
      //   id,
    //   formInfo
    // );
    // if (result.Successful == false) {
    //   flasher.warning(result.Message);
    // } else {
    //   flasher.success("Data Added Successfully");
    //   if (formMode == "Edit") {
    //     Navigation("/gaa-project-space-type-planned-device-Connections");
    //   } else {
    //     setFormInfo(ClearInfo(formInfo));
    //     fetchFromPlannedDevice(); // Save ProjectRef
    //   }
    // }
  };

  const handleType = (e) => {
    let result = GAAProductOutputFunctionTypeList.filter(
      (data) => data.Ref == e.target.value
    );
    setFormInfo((formInfo) => ({
      ...formInfo,
      BindToGAAProductOutputFunctionTypeRef: e.target.value,
      BindToGAAProductOutputFunctionTypeValue: result[0].Value,
    }));
  };

  const handleId = (e) => {
    let result = GAAProductOutputFunctionIdList.filter(
      (data) => data.Ref == e.target.value
    );
    setFormInfo((formInfo) => ({
      ...formInfo,
      BindToGAAProductOutputFunctionIdRef: e.target.value,
      BindToGAAProductOutputFunctionIdValue: result[0].Value,
    }));
  };

  // Fetch Planned Device Using Location State //
  const fetchFromPlannedDevice = async () => {
    let Device = await GetGAAProjectSpaceTypePlannedDevice(
      Location.state.GAAProjectSpaceTypePlannedDeviceRef
    );
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectSpaceTypePlannedDeviceRef: Device.Ref,
      GAAProjectSpaceTypePlannedDeviceName: Device.Name,
    }));
    setFromGAAProductRef(Device.GAAProductRef);
    setToGAAProductRef(Device.GAAProductRef);
  };

  // Fetch Planned Device Using Location State //
  const fetchToPlannedDevice = async () => {
    if (formInfo.BindToGAAProjectSpaceTypePlannedDeviceRef == 0) {
      setFormInfo((formInfo) => ({
        ...formInfo,
        BindToGAAProductInputDriverChannelRef: 0,
        BindToGAAProductOutputDriverChannelRef: 0,
      }));
    } else {
      let Device = await GetGAAProjectSpaceTypePlannedDevice(
        formInfo.BindToGAAProjectSpaceTypePlannedDeviceRef
      );
      setToGAAProductRef(Device.GAAProductRef);
    }
  };

  // Fetch Input Driver  List  //
  const fetchFromInputDriverChannelList = async () => {
    FromGAAProductRef &&
      setFromInputDriverChannelList(
        await GAAProductInputDriverChannelListByProduct(FromGAAProductRef)
      );
  };

  // Fetch Output Driver  List //
  const fetchFromOutputDriverChannel = async () => {
    FromGAAProductRef &&
      setFromOutputDriverChannelList(
        await GetGAAProductOutputDriverChannelListByProduct(FromGAAProductRef)
      );
  };

  // Fetch Input Driver  List  //
  const fetchToInputDriverChannelList = async () => {
    ToGAAProductRef &&
      setToInputDriverChannelList(
        await GAAProductInputDriverChannelListByProduct(ToGAAProductRef)
      );
  };

  // Fetch Output Driver  List //
  const fetchToOutputDriverChannel = async () => {
    ToGAAProductRef &&
      setToOutputDriverChannelList(
        await GetGAAProductOutputDriverChannelListByProduct(ToGAAProductRef)
      );
  };



  // Fetch Product Output Function ID List //
  const fetchGAAProductOutputFunctionIdList = async () => {
    setProductOutputFunctionIdList(await GetProductOutputFunctionIdList());
  };

  // Fetch Product Output Function Type List List //
  const fetchGAAProductOutputFunctionTypeList = async () => {
    setGAAProductOutputFunctionTypeList(
      await GetGAAProductOutputFunctionTypeList()
    );
  };

  // fetchProject Space Type Planned Device List According to Project Space Type Area Ref //
  const fetchProjectSpaceTypePlannedDeviceListByArea = async () => {
    Location.state.GAAProjectSpaceTypeAreaRef &&
      setProjectSpaceTypePlannedDeviceList(
        await GetGAAProjectSpaceTypePlannedDeviceByArea(
          Location.state.GAAProjectSpaceTypeAreaRef
        )
      );
  };

  // fetchProject Space Type Planned Device List According to Project Space Type Area Ref //
  const fetchProjectSpaceTypePlannedDeviceListBySpaceType = async () => {
    Location.state.GAAProjectSpaceTypeRef &&
      setProjectSpaceTypePlannedDeviceList(
        await GetGAAProjectSpaceTypePlannedDeviceBySpaceType(
          Location.state.GAAProjectSpaceTypeRef
        )
      );
  };

  // Edit Device Connection Records On ID //
  const fetchProjectSpaceTypePlannedDeviceConnection = async () => {
    let data = await GetGAAProjectSpaceTypePlannedDeviceConnection(id);
    console.log('data :', data);
    setFormInfo(data);
    setFromGAAProductRef(data.GAAProductRef);
    setToGAAProductRef(data.GAAProductRef);
  };

  // Fetch project Details Using Location State //
  useEffect(() => {
    if (Location.state) {
      fetchFromPlannedDevice();
    }
    if (Location.state) {
      fetchProjectSpaceTypePlannedDeviceListByArea();
      fetchProjectSpaceTypePlannedDeviceListBySpaceType();
    }
    if (id) {
      fetchProjectSpaceTypePlannedDeviceConnection(); // Fetch Device Connection Details Using ID //
    }
    fetchGAAProductOutputFunctionIdList();
    fetchGAAProductOutputFunctionTypeList();
  }, []);

  useEffect(() => {
    fetchFromInputDriverChannelList();
    fetchFromOutputDriverChannel();
  }, [FromGAAProductRef]);

  useEffect(() => {
    fetchToInputDriverChannelList();
    fetchToOutputDriverChannel();
  }, [ToGAAProductRef]);

  useEffect(() => {
    fetchToPlannedDevice();
  }, [formInfo.BindToGAAProjectSpaceTypePlannedDeviceRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit Connection" : "Add Connection"}
          button={
            <LinkButton
              to={"/gaa-project-space-type-planned-device-Connections"}
            >
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypePlannedDeviceName"}
                      labelText={"From Planned Device"}
                    />
                    {formInfo.GAAProjectSpaceTypePlannedDeviceRef && (
                      <Input
                        id={"GAAProjectSpaceTypePlannedDeviceName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypePlannedDeviceName"}
                        value={formInfo.GAAProjectSpaceTypePlannedDeviceName}
                        onChange={handleChange}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label labelText={"Bind From Channel Type"} />
                    <CustomSelect
                      value={formInfo.BindFrom}
                      id={"BindFrom"}
                      name={"BindFrom"}
                      inputFocus={true}
                      onChange={handleChange}
                    >
                      <Option value={"input"}>Input</Option>
                      <Option value={"output"}>Output</Option>
                    </CustomSelect>
                  </div>

                  <div>
                    <Label
                      htmlFor={"BindFromGAAProductInputDriverChannelRef"}
                      labelText={
                        formInfo.BindFrom == "input"
                          ? "Bind from Input Channel"
                          : "Bind from Output Channel"
                      }
                    />
                    {formInfo.BindFrom == "input" ? (
                      <CustomSelect
                        id={"BindFromGAAProductInputDriverChannelRef"}
                        name={"BindFromGAAProductInputDriverChannelRef"}
                        value={formInfo.BindFromGAAProductInputDriverChannelRef}
                        onChange={handleChange}
                        required={true}
                      >
                        <Option value={""}>-- Select --</Option>
                        {FromInputDriverChannelList &&
                          FromInputDriverChannelList.map((data, id) => {
                            return (
                              <Fragment key={id}>
                                <Option value={data.Ref}>{data.Name}</Option>
                              </Fragment>
                            );
                          })}
                      </CustomSelect>
                    ) : (
                      <CustomSelect
                        id={"BindFromGAAProductOutputDriverChannelRef"}
                        name={"BindFromGAAProductOutputDriverChannelRef"}
                        value={
                          formInfo.BindFromGAAProductOutputDriverChannelRef
                        }
                        onChange={handleChange}
                      >
                        <Option value={""}>-- Select --</Option>
                        {FromOutputDriverChannelList &&
                          FromOutputDriverChannelList.map((data, id) => {
                            return (
                              <Fragment key={id}>
                                <Option value={data.Ref}>{data.Name}</Option>
                              </Fragment>
                            );
                          })}
                      </CustomSelect>
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"BindToGAAProjectSpaceTypePlannedDeviceRef"}
                      labelText={"To Planned Device"}
                    />
                    <CustomSelect
                      id={"BindToGAAProjectSpaceTypePlannedDeviceRef"}
                      name={"BindToGAAProjectSpaceTypePlannedDeviceRef"}
                      value={formInfo.BindToGAAProjectSpaceTypePlannedDeviceRef}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option value={0}>-- LOAD --</Option>
                      {ProjectSpaceTypePlannedDeviceList &&
                        ProjectSpaceTypePlannedDeviceList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option value={data.Ref}>{data.Name}</Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                  </div>

                  <div>
                    {formInfo.BindToGAAProjectSpaceTypePlannedDeviceRef !=
                      0 && (
                      <div>
                        <Label labelText={"Bind To Channel Type"} />
                        <CustomSelect
                          value={formInfo.BindTo}
                          id={"BindTo"}
                          name={"BindTo"}
                          inputFocus={true}
                          onChange={handleChange}
                        >
                          <Option value={"input"}>Input</Option>
                          <Option value={"output"}>Output</Option>
                        </CustomSelect>
                      </div>
                    )}
                  </div>

                  <div>
                    {formInfo.BindToGAAProjectSpaceTypePlannedDeviceRef !=
                      0 && (
                      <div>
                        <Label
                          htmlFor={"BindFromGAAProductInputDriverChannelRef"}
                          labelText={
                            formInfo.BindTo == "input"
                              ? "Bind to Input Channel"
                              : "Bind to Output Channel"
                          }
                        />
                        {formInfo.BindTo == "input" ? (
                          <CustomSelect
                            id={"BindToGAAProductInputDriverChannelRef"}
                            name={"BindToGAAProductInputDriverChannelRef"}
                            value={
                              formInfo.BindToGAAProductInputDriverChannelRef
                            }
                            onChange={handleChange}
                            required={true}
                          >
                            <Option value={""}>-- Select --</Option>
                            {ToInputDriverChannelList &&
                              ToInputDriverChannelList.map((data, id) => {
                                return (
                                  <Fragment key={id}>
                                    <Option value={data.Ref}>
                                      {data.Name}
                                    </Option>
                                  </Fragment>
                                );
                              })}
                          </CustomSelect>
                        ) : (
                          <CustomSelect
                            id={"BindToGAAProductOutputDriverChannelRef"}
                            name={"BindToGAAProductOutputDriverChannelRef"}
                            value={
                              formInfo.BindToGAAProductOutputDriverChannelRef
                            }
                            onChange={handleChange}
                          >
                            <Option value={""}>-- Select --</Option>
                            {ToOutputDriverChannelList &&
                              ToOutputDriverChannelList.map((data, id) => {
                                return (
                                  <Fragment key={id}>
                                    <Option value={data.Ref}>
                                      {data.Name}
                                    </Option>
                                  </Fragment>
                                );
                              })}
                          </CustomSelect>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"Label"} labelText={"Label"} />
                    <Input
                      id={"Label"}
                      type={"text"}
                      name={"Label"}
                      value={formInfo.Label}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Label} />
                  </div>
                  <div>
                    <Label htmlFor={"Wattage"} labelText={"Wattage"} />
                    <Input
                      id={"Wattage"}
                      type={"text"}
                      name={"Wattage"}
                      value={formInfo.Wattage}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Wattage} />
                  </div>
                  <div>
                    <Label
                      htmlFor={"BindToGAAProductOutputFunctionIdRef"}
                      labelText={"Product Output Function Id"}
                    />
                    <CustomSelect
                      id={"BindToGAAProductOutputFunctionIdRef"}
                      name={"BindToGAAProductOutputFunctionIdRef"}
                      value={formInfo.BindToGAAProductOutputFunctionIdRef}
                      onChange={handleId}
                      required={true}
                    >
                      <Option value={""}>
                        --- Select Product Output Function Id ---
                      </Option>
                      {GAAProductOutputFunctionIdList &&
                        GAAProductOutputFunctionIdList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option value={data.Ref}>{data.Name}</Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                    <Validation
                      error={errors.BindToGAAProductOutputFunctionIdRef}
                    />
                  </div>
                  <div>
                    <Label
                      htmlFor={"BindToGAAProductOutputFunctionIdValue"}
                      labelText={"Product Output Function Id Value"}
                    />
                    <Input
                      id={"BindToGAAProductOutputFunctionIdValue"}
                      type={"number"}
                      name={"BindToGAAProductOutputFunctionIdValue"}
                      min={0}
                      value={formInfo.BindToGAAProductOutputFunctionIdValue}
                      readOnly={true}
                      required={true}
                    />
                    <Validation
                      error={errors.BindToGAAProductOutputFunctionIdValue}
                    />
                  </div>
                  <div>
                    <Label
                      htmlFor={"BindToGAAProductOutputFunctionTypeRef"}
                      labelText={"Product Output Function Type"}
                    />
                    <CustomSelect
                      id={"BindToGAAProductOutputFunctionTypeRef"}
                      name={"BindToGAAProductOutputFunctionTypeRef"}
                      value={formInfo.BindToGAAProductOutputFunctionTypeRef}
                      onChange={handleType}
                      required={true}
                    >
                      <Option value={""}>
                        --- Select Product Output Function Id ---
                      </Option>
                      {GAAProductOutputFunctionTypeList &&
                        GAAProductOutputFunctionTypeList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option value={data.Ref}>{data.Name}</Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                    <Validation
                      error={errors.GAAProductOutputFunctionTypeRef}
                    />
                  </div>
                  <div>
                    <Label
                      htmlFor={"BindToGAAProductOutputFunctionTypeValue"}
                      labelText={"Product Output Function Type Value"}
                    />
                    <Input
                      id={"BindToGAAProductOutputFunctionTypeValue"}
                      type={"number"}
                      name={"BindToGAAProductOutputFunctionTypeValue"}
                      min={0}
                      value={formInfo.BindToGAAProductOutputFunctionTypeValue}
                      readOnly={true}
                      required={true}
                    />
                    <Validation
                      error={errors.BindToGAAProductOutputFunctionTypeValue}
                    />
                  </div>
                  <div>
                    {formInfo.BindToGAAProductOutputFunctionIdValue == "2" && formInfo.BindToGAAProductOutputFunctionTypeValue == "11"
                       && (
                      <div>
                        <Label labelText={"Scene"} />
                        <CustomSelect
                      id={"Scene"}
                      name={"Scene"}
                      value={formInfo.Scene}
                      onChange={handleChange}
                      required={true}
                    >
                      <Option value={""}>
                        --- Select Scene ---
                      </Option>
                      {RoomSceneListList &&
                        RoomSceneListList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option value={data.Ref}>{data.Name}</Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton
                    to={"/gaa-project-space-type-planned-device-Connections"}
                  />
                  {Rights.Edit && <SaveButton text="Save" />}{" "}
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceTypePlannedDeviceConnectionsAddEdit;
