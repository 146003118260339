import React, { Fragment, useEffect, useState } from 'react'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import BackComponent from '../../../components/BackComponent'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { GetProductOutputFunctionId, SaveProductOutputFunctionId } from '../../../Encapsulation/GladianceDomainEntities/GAAProductOutputFunctionId'
import { ClearInfo } from '../../../Encapsulation/Utils'


function GAAProductOutputFunctionIdAddEdit() {
    let formMode = 'New';
    let { id } = useParams()

    if (id) {
        document.title = 'Gladiance - Edit Product Output Function';
        formMode = 'Edit'
    } else {
        document.title = 'Gladiance - Add Product Output Function'
    }


    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    console.log('errors :', errors);
    const Navigation = useNavigate()
    const [formInfo, setFormInfo] = useState({})
    console.log('formInfo :', formInfo);
    const [imgInfo, setImgInfo] = useState({})

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    // Save To Database //
    const handleSubmit = async (e) => {
        e.preventDefault()
        let result = await SaveProductOutputFunctionId(id, formInfo,
           );
        console.log('result :', result);

        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/product-output-function-id-master');
            }
            else {
                setFormInfo(ClearInfo(formInfo))        // Clear FormInfo After Data Submitted //
            }
        }

    }
        // Fetch Data From database Using Id //
    const fetchGAAProductOutputFunctionId = async () => {
        setFormInfo(await GetProductOutputFunctionId(id));
    }

    useEffect(() => {
        if (id) {
            fetchGAAProductOutputFunctionId();  
        }
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Product Output Function ' : 'Add Product Output Function'} button={
                    <LinkButton to={'/product-output-function-id-master'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'Solution Name'} />
                                        <Input inputFocus={true} id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} required={true} />
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Value'} labelText={'Value'} />
                                        <Input id={'Value'} type={'number'} name={'Value'} value={formInfo.Value} onChange={handleChange} required={true} min={0} />
                                        <Validation error={errors.Value} />
                                    </div>
                                   
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/product-output-function-id-master'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}
export default GAAProductOutputFunctionIdAddEdit
