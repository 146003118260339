import React, { Fragment, useEffect, useState } from "react";
import { PencilSquareIcon, TrashIcon, } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { AddCircle } from "iconsax-react";
import Loader from "../../Common/Loader";
import AddComponent from "../../../components/AddComponent";
import Table from "../../helpers/Table";
import LinkButton from "../../helpers/LinkButton";
import ActionButton from "../../helpers/ActionButton";
import Pagination from "../../helpers/Pagination";
import DataNotFound from "../../helpers/DataNotFound";
import { GetGAAProjectRoomSceneListByProject } from "../../../Encapsulation/HotelDomainEntities/HotelRoomScene";
import Label from "../../helpers/Label";
import CustomSelect from "../../helpers/CustomSelect";
import { GetGAAProjectList } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Option from "../../helpers/Option";
import Input from "../../helpers/Input";
import { GetGAAProjectSpaceTypeListByProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { GetGAAProjectSpaceTypePlannedDeviceByArea, GetGAAProjectSpaceTypePlannedDeviceBySpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDevice";
import { GetGAAProjectSpaceTypeAreaListBySpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { DeleteHotelRoomSceneConfiguration, GetProjectRoomSceneListByProjectScene } from "../../../Encapsulation/HotelDomainEntities/HotelRoomSceneConfiguration";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";

function RoomSceneConfiguration({ Rights }) {
    document.title = "Hotel - Room Scene Configuration";
    const tableHeading = [
        "Sr.No",
        "Scene Code",
        "Space Type Planned Device Name",
        "Label",
        "Output Driver Channel Name",
        "Action",
    ];

    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [GAAProjectList, setGAAProjectList] = useState([]);
    const [GAAProjectRef, setGAAProjectRef] = useState();
    const [GAAProjectSceneRef, setGAAProjectSceneRef] = useState();
    const [RoomSceneList, setRoomSceneList] = useState([]);
    const [RoomSceneConfigurationList, setRoomSceneConfigurationList] = useState([]);
    const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState([]);
    const [GAAProjectSpaceTypeRef, setGAAProjectSpaceTypeRef] = useState();
    const [GAAProjectSpaceTypeAreaList, setGAAProjectSpaceTypeAreaList] =
        useState([]);
    const [GAAProjectSpaceTypeAreaRef, setGAAProjectSpaceTypeAreaRef] =
        useState();

    // Fetch Project List For Dropdown //
    const fetchProjectList = async () => {
        setGAAProjectList(await GetGAAProjectList());
    };

    // Fetch Project Space Type List For Dropdown By Project //
    const fetchSpaceTypeListByProject = async () => {
        GAAProjectRef &&
            setGAAProjectSpaceTypeList(
                await GetGAAProjectSpaceTypeListByProject(GAAProjectRef)
            );
        setGAAProjectSpaceTypeAreaRef("");
    };

    // Fetch Area List For Dropdown By Space Type//
    const fetchAreaBySpaceType = async () => {
        GAAProjectSpaceTypeRef &&
            setGAAProjectSpaceTypeAreaList(
                await GetGAAProjectSpaceTypeAreaListBySpaceType(GAAProjectSpaceTypeRef)
            );
    };

    // fetch Room Scene List According to Project Ref //
    const fetchProjectRoomSceneListByProject = async () => {
        GAAProjectRef &&
            setRoomSceneList(
                await GetGAAProjectRoomSceneListByProject(GAAProjectRef)
            );
    };

    // fetch Room Scene Configuration List According to Scene Configuration Ref //
    const fetchProjectRoomSceneListByRoomSceneConfiguration = async () => {
        GAAProjectSceneRef &&
            setRoomSceneConfigurationList(
                await GetProjectRoomSceneListByProjectScene(GAAProjectSceneRef)
            );
    };

    const deleteSceneConfiguration = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteHotelRoomSceneConfiguration(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchProjectRoomSceneListByRoomSceneConfiguration();
                }
            }
        })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        fetchProjectList();
    }, []);

    useEffect(() => {
        if (GAAProjectList.length > 0) {
            setGAAProjectRef(GAAProjectList[0].Ref);
        }
    }, [GAAProjectList]);
    // =====================================================
    useEffect(() => {
        fetchSpaceTypeListByProject();
    }, [GAAProjectRef]);

    useEffect(() => {
        if (GAAProjectSpaceTypeList.length > 0) {
            setGAAProjectSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
        }
    }, [GAAProjectSpaceTypeList]);

    // =====================================================
    useEffect(() => {
        fetchAreaBySpaceType();
    }, [GAAProjectSpaceTypeRef]);

    useEffect(() => {
        if (GAAProjectSpaceTypeAreaList.length > 0) {
            setGAAProjectSpaceTypeAreaRef(GAAProjectSpaceTypeAreaList[0].Ref);
        }
    }, [GAAProjectSpaceTypeAreaList]);

    // =====================================================
    useEffect(() => {
        fetchProjectRoomSceneListByProject();
    }, [GAAProjectRef]);

    useEffect(() => {
        if (RoomSceneList.length > 0) {
            setGAAProjectSceneRef(RoomSceneList[0].Ref);
        }
    }, [RoomSceneList]);

    // =====================================================

    useEffect(() => {
        fetchProjectRoomSceneListByRoomSceneConfiguration();
    }, [GAAProjectSceneRef]);

    return (
        <Fragment>
            {loader ? (
                <Loader />
            ) : (
                <AddComponent
                    title={"Room Scenes Configuration"}
                    button={
                        <LinkButton
                            to={"/scene-configurations/add-scene-configurations"}
                            className={Rights.Add ? "opacity-100" : "hidden"}
                            state={{
                                GAAProjectSceneRef: GAAProjectSceneRef,
                                GAAProjectRef: GAAProjectRef,
                                GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef,
                                GAAProjectSpaceTypeAreaRef: GAAProjectSpaceTypeAreaRef,
                            }}
                        >
                            <AddCircle variant="Bold" className="w-5 h-5" />
                            <span>Add Scene</span>
                        </LinkButton>
                    }
                >
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                        <div>
                            <Label htmlFor={"GAAProjectRef"} labelText={" Project"} />
                            <CustomSelect
                                id={"GAAProjectRef"}
                                name={"GAAProjectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => setGAAProjectRef(e.target.value)}
                                required={true}
                            >
                                {GAAProjectList &&
                                    GAAProjectList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        );
                                    })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"GAAProjectSpaceTypeRef"}
                                labelText={" Space Type"}
                            />
                            {GAAProjectSpaceTypeList.length > 0 ? (
                                <div>
                                    <CustomSelect
                                        id={"GAAProjectSpaceTypeRef"}
                                        name={"GAAProjectSpaceTypeRef"}
                                        value={GAAProjectSpaceTypeRef}
                                        onChange={(e) => setGAAProjectSpaceTypeRef(e.target.value)}
                                        required={true}
                                    >
                                        {GAAProjectSpaceTypeList &&
                                            GAAProjectSpaceTypeList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option value={data.Ref}>{data.Name}</Option>
                                                    </Fragment>
                                                );
                                            })}
                                    </CustomSelect>
                                </div>
                            ) : (
                                <div>
                                    <Input
                                        placeHolder={"  No Space Types"}
                                        disabled={true}
                                    ></Input>
                                </div>
                            )}
                        </div>
                        <div>
                            <Label
                                htmlFor={"GAAProjectSpaceTypeAreaRef"}
                                labelText={" Space Type Area"}
                            />
                            {GAAProjectSpaceTypeAreaList.length > 0 ? (
                                <div>
                                    <CustomSelect
                                        id={"GAAProjectSpaceTypeAreaRef"}
                                        name={"GAAProjectSpaceTypeAreaRef"}
                                        value={GAAProjectSpaceTypeAreaRef}
                                        onChange={(e) =>
                                            setGAAProjectSpaceTypeAreaRef(e.target.value)
                                        }
                                        required={true}
                                    >
                                        {GAAProjectSpaceTypeAreaList &&
                                            GAAProjectSpaceTypeAreaList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option value={data.Ref}>{data.Name}</Option>
                                                    </Fragment>
                                                );
                                            })}
                                    </CustomSelect>
                                </div>
                            ) : (
                                <div>
                                    <Input placeHolder={"  No Areas"} disabled={true}></Input>
                                </div>
                            )}
                        </div>
                        <div>
                            <Label htmlFor={"GAAProjectSceneRef"} labelText={"Room Scene"} />
                            <CustomSelect
                                id={"GAAProjectSceneRef"}
                                name={"GAAProjectSceneRef"}
                                value={GAAProjectSceneRef}
                                onChange={(e) => setGAAProjectSceneRef(e.target.value)}
                                required={true}
                            >
                                {RoomSceneList &&
                                    RoomSceneList.map((data, id) => {
                                        return (
                                            <Fragment key={id}>
                                                <Option value={data.Ref}>{data.Name}</Option>
                                            </Fragment>
                                        );
                                    })}
                            </CustomSelect>
                        </div>
                    </div>
                    {RoomSceneConfigurationList &&
                        RoomSceneConfigurationList.length > 0 ? (
                        <div className="m-2">
                            <Table tableHeading={tableHeading}>
                                {RoomSceneConfigurationList.slice(
                                    (currentPage - 1) * itemsPerPage,
                                    currentPage * itemsPerPage
                                ).map((tableData, tableDataID) => {
                                    console.log('tableData :', tableData);
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">
                                                    {tableData.GAAProjectSceneCode}
                                                </td>
                                                <td className="px-4 py-3">
                                                    {tableData.GAAProjectSpaceTypePlannedDeviceName}
                                                </td>
                                                <td className="px-4 py-3">{tableData.Label}</td>
                                                <td className="px-4 py-3">
                                                    {tableData.OutputDriverChannelName}
                                                </td>
                                                <td className="px-4 py-3">
                                                    <div className="flex justify-center gap-3">
                                                        <Link
                                                            to={`/scene-configurations/edit-scene-configurations/${tableData.GAAProjectSceneRef}/${tableData.GAAProjectSpaceTypeRef}/${tableData.GAAProjectSpaceTypePlannedDeviceConnectionRef}`}
                                                            title="Edit"
                                                        >
                                                            <ActionButton
                                                                className={"p-1.5 text-white bg-yellow-400 "}
                                                            >
                                                                <PencilSquareIcon className="w-4 h-4" />
                                                            </ActionButton>
                                                        </Link>
                                                        {
                                                            Rights.Delete && (
                                                                <ActionButton onClick={() => deleteSceneConfiguration(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                    <TrashIcon className='w-4 h-4' />
                                                                </ActionButton>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    );
                                })}
                            </Table>
                        </div>
                    ) : (
                        <DataNotFound
                            labelText={"Sorry! Room Scene Configuration Data Not Found"}
                        />
                    )}
                    {RoomSceneConfigurationList &&
                        RoomSceneConfigurationList.length > 7 ? (
                        <div className="flex justify-end">
                            <Pagination
                                itemsPerPage={itemsPerPage}
                                totalItems={RoomSceneConfigurationList.length}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </AddComponent>
            )}
        </Fragment>
    );
}

export default RoomSceneConfiguration;
