import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import { DeleteProgrammingRequisition, GetProgrammingRequisitionList } from "../../../Encapsulation/GladianceDomainEntities/ProgrammingRequisition";
import flasher from "@flasher/flasher";
import Swal from "sweetalert2";

function ProgrammingRequisitionMaster() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Programming Requisition ";
  const tableHeading = [
    "Sr.No",
    "Product",
    "Design ID",
    "Hardware Type",
    "Firmware Id",
    "Firmware Status",
    " Quantity ",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [errors, setErrors] = useState([]);

  const [
    gladianceProgrammingRequisitionList,
    setGladianceProgrammingRequisitionList,
  ] = useState([]);

  const fetchGladianceProgrammingRequisitionList = async () => {
    setGladianceProgrammingRequisitionList(await GetProgrammingRequisitionList());
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const deleteProgrammingRequisition = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteProgrammingRequisition(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchGladianceProgrammingRequisitionList();
        }
      }
    })
  }


  useEffect(() => {
    fetchGladianceProgrammingRequisitionList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Programming Requisitions"}
          button={
            <LinkButton
              to={"/programming-requisition/add-programming-requisition"}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Programming Requisition </span>
            </LinkButton>
          }
        >
          {gladianceProgrammingRequisitionList &&
          gladianceProgrammingRequisitionList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {gladianceProgrammingRequisitionList &&
                  gladianceProgrammingRequisitionList
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((tableData, tableDataID) => {
                    console.log('tableData :', tableData);
                      return (
                        <Fragment key={tableDataID}>
                          <tr className="border-b border-opacity-30 border-white">
                            <td className="px-4 py-3">{tableDataID + 1}</td>
                            <td className="px-4 py-3">{tableData.GAAProductRef}</td>
                            <td className="px-4 py-3">{tableData.DesignId}</td>
                            <td className="px-4 py-3">
                              {tableData.HardwareType}
                            </td>
                            <td className="px-4 py-3">
                              {tableData.FirmwareId}
                            </td>
                            <td className="px-4 py-3">
                              {tableData.FirmwareStatusName}
                            </td>
                            <td className="px-4 py-3">{tableData.Quantity}</td>
                            <td className="px-4 py-3">
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/programming-requisition/edit-programming-requisition/${tableData.Ref}`}
                                  title="Edit"
                                >
                                  <ActionButton
                                    className={
                                      "p-1.5 text-white bg-yellow-400 "
                                    }
                                  >
                                    <PencilSquareIcon className="w-4 h-4" />
                                  </ActionButton>
                                </Link>
                                <ActionButton
                                  onClick={() =>
                                    deleteProgrammingRequisition(tableData)
                                  }
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </Table>
            </div>
          ) : (
            <DataNotFound
              labelText={"Sorry! Programming Requisition Data Not Found"}
            />
          )}
          {gladianceProgrammingRequisitionList &&
          gladianceProgrammingRequisitionList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={gladianceProgrammingRequisitionList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default ProgrammingRequisitionMaster;
