import React, { Fragment, useEffect, useState } from 'react'
import axiosClient from '../../Encapsulation/Axios'
import flasher from '@flasher/flasher'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddComponent from '../../components/AddComponent'
import Label from '../helpers/Label'
import Card from '../helpers/Card'
import Input from '../helpers/Input'
import Button from '../helpers/Button'
import LinkButton from '../helpers/LinkButton'
import Table from '../helpers/Table'
import Loader from '../Common/Loader'
import Validation from '../helpers/Validation'
import { useStateContext } from '../../context/ContextProvider'
import { ArrowCircleLeft2, DirectInbox } from 'iconsax-react'
import BackComponent from '../../components/BackComponent'
import SaveButton from '../helpers/SaveButton'
import Sidebar from './Sidebar'

function Navbar() {
    const [loader, setLoader] = useState(false)

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={''}>
                    <Sidebar />
                </BackComponent>
            )}
        </Fragment>
    )
}


export default Navbar
