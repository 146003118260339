import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import {  PencilSquareIcon,  TrashIcon,} from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import { DeleteGladianceInventory, GetGladianceInventoryList } from "../../../Encapsulation/GladianceDomainEntities/GladianceCommercialInventory";
import flasher from "@flasher/flasher";
import Swal from "sweetalert2";

function CommercialInventoryMaster() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Commercial Inventory";
  const tableHeading = [
    "Sr.No",
    "Product Name",
    "Country Name",
    "Price",
    "Dealer Price",
    " Discount %",
    "Festival Discount % ",
    "Transportation Charges ",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [CommercialInventory, setCommercialInventory] = useState();
  const [errors, setErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const fetchGladianceInventoryList = async () => {
    setCommercialInventory(await GetGladianceInventoryList(setErrors));
}

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const deleteCommercialInventory= (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGladianceInventory(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchGladianceInventoryList();
        }
      }
    })
  }

  useEffect(() => {
    fetchGladianceInventoryList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Commercial Inventorys"}
          button={
            <LinkButton to={"/commercial-inventory/add-commercial-inventory"}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Commercial Inventory</span>
            </LinkButton>
          }
        >
          {CommercialInventory && CommercialInventory.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {CommercialInventory &&
                  CommercialInventory.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  ).map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.GAAProductName}</td>
                          <td className="px-4 py-3">{tableData.CountryName}</td>
                          <td className="px-4 py-3">{tableData.Price}</td>
                          <td className="px-4 py-3">{tableData.DealerPrice}</td>
                          <td className="px-4 py-3">{tableData.DiscountPercentage}</td>
                          <td className="px-4 py-3">{tableData.FestivalDiscountPercentage}</td>
                          <td className="px-4 py-3">{tableData.TransportationCharges}</td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link to={`/commercial-inventory/edit-commercial-inventory/${tableData.Ref}`} title="Edit">
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              <ActionButton
                                onClick={() => deleteCommercialInventory(tableData)}
                                title={"Delete"}
                                className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                              >
                                <TrashIcon className="w-4 h-4" />
                              </ActionButton>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound
              labelText={"Sorry! Commercial Inventory Data Not Found"}
            />
          )}
          {CommercialInventory && CommercialInventory.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={CommercialInventory.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default CommercialInventoryMaster;
