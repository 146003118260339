import React, { Fragment, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import CheckBox from '../../helpers/CheckBox'
import { AddCircle, ArrowCircleLeft2 } from 'iconsax-react'
import Table from '../../helpers/Table'
import AddComponent from '../../../components/AddComponent'
import ActionButton from '../../helpers/ActionButton'
import { PencilSquareIcon } from '@heroicons/react/24/solid'


function RightsRegistration() {
  const { id } = useParams()
  {
    id ? (
      document.title = 'Gladiance - Rights Registration'
    ) : (
      document.title = 'Gladiance - Rights Registration'
    )
  }
  const tableHeading = ['Activity', 'Add', 'Edit', 'Delete', 'View', 'Action']
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  const [formInfo, setFormInfo] = useState({
    user_name: '',
    password: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    phone_no: '',
    email: "",
    gladiance_dept: '',
    gladiance_job_title: '',
    user_role: '',
  })

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('user_name :', formInfo.user_name);
    console.log('password :', formInfo.password);
    console.log('last_name :', formInfo.last_name);
    console.log('first_name :', formInfo.first_name);
    console.log('middle_name :', formInfo.middle_name);
    console.log('phone_no :', formInfo.phone_no);
    console.log('email :', formInfo.email);
    console.log('gladiance_dept :', formInfo.gladiance_dept);
    console.log('gladiance_job_title :', formInfo.gladiance_job_title);
    console.log('user_role :', formInfo.user_role);
  }

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <AddComponent title={id ? 'Rights Registrations' : 'Rights Registrations'}
        //  button={
        //   <LinkButton to={'/rights-registration/add-rights-registration'}>
        //     <AddCircle variant='Bold' className='w-5 h-5' />
        //     <span>Add New Right</span>
        //   </LinkButton>
        // }
        >
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6  '}>
              <div className="h-2/4">
                <Table tableHeading={tableHeading}>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> User Management </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Room Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Gladiance Device Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Room Scene Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Gladiance One App Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Miscellaneous Parameter Inventory</td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Television Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> In Room Dining Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Laundry Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Information Inventory </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3">Guest Management</td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Bill View Management </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Promotions Management </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Messaging Management </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Room Live Status </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Ambiance Automation Live Status </td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                  <tr className="border-b border-opacity-20 border-white">
                    <td className="px-6 py-3"> Feedback Live Status</td>
                    <td className="px-6 py-3 "><CheckBox id={'add'} name={'add'} type={'checkbox'} checked={''} /></td>
                    <td className="px-6 py-3"><CheckBox id={'modify'} name={'modify'} type={'checkbox'} checked={true} /></td>
                    <td className="px-6 py-3"><CheckBox id={'delete'} name={'delete'} type={'checkbox'} checked={false} /></td>
                    <td className="px-6 py-3"><CheckBox id={'view'} name={'view'} type={'checkbox'} checked={''} /></td>
                    <td className='px-6 py-3 flex justify-center'>
                      <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                        <PencilSquareIcon className='w-4 h-4' />
                      </ActionButton></Link>
                    </td>
                  </tr>
                </Table>
              </div>
            </Card>
          </div>
        </AddComponent>
      )}
    </Fragment>
  )
}


export default RightsRegistration

