import React, { Fragment, useEffect, useState } from 'react'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { useStateContext } from '../../../context/ContextProvider'
import { AddCircle, ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo } from '../../../Encapsulation/Utils'
import { GetHotelGuestReservation, SaveHotelGuestReservation } from '../../../Encapsulation/HotelDomainEntities/HotelGuestReservation'

function ReservationAddEdit() {
  let formMode = 'New';
  const { id } = useParams()
  if (id) {
    document.title = 'Gladiance - Edit Reservation'
    formMode = 'Edit'
  } else {
    document.title = 'Gladiance - Add Reservation'
  }
  const Navigation = useNavigate()
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const [image, setImage] = useState('')
  const [FirmwareFile, setFirmwareFile] = useState('')

  const [formInfo, setFormInfo] = useState({})

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let result = await SaveHotelGuestReservation(id, formInfo, []);
    console.log('result :', result);

    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      if (formMode == 'Edit') {
        Navigation('/reservation');
      }
      else {
        setFormInfo(ClearInfo(formInfo))
      }
    }
  }

  const fetchrReservation = async () => {
    setFormInfo(await GetHotelGuestReservation(id));
  }

  useEffect(() => {
    if (id) {
      fetchrReservation();
    }
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={id ? 'Edit Reservation' : 'Add Reservation'} button={
          <LinkButton to={'/reservation'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'ProductCode'} labelText={'Name'}  />
                    <Input id={'ProductCode'} type={'text'} name={'ProductCode'} value={formInfo.ProductCode} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.ProductCode} />
                  </div>
                  <div>
                    <Label htmlFor={'Name'} labelText={'Check In Date'}  />
                    <Input id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange}
                />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={'DesignId'} labelText={'Check In Time'}  />
                    <Input id={'DesignId'} type={'text'} name={'DesignId'} value={formInfo.DesignId} onChange={handleChange} />
                    <Validation error={errors.DesignId} />
                  </div>
                  <div>
                    <Label htmlFor={'HardwareId'} labelText={'Check Out Date'}  />
                    <Input id={'HardwareId'} type={'text'} name={'HardwareId'} value={formInfo.HardwareId} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.HardwareId} />
                  </div>
                  <div>
                    <Label htmlFor={'FirmwareId'} labelText={'Email'}  />
                    <Input id={'FirmwareId'} type={'text'} name={'FirmwareId'} value={formInfo.FirmwareId} onChange={handleChange}  />
                    <Validation error={errors.FirmwareId} />
                  </div>
                  <div>
                    <Label htmlFor={'HardwareId'} labelText={'Mobile'}  />
                    <Input id={'HardwareId'} type={'text'} name={'HardwareId'} value={formInfo.HardwareId} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.HardwareId} />
                  </div>
                  <div>
                    <Label htmlFor={'FirmwareId'} labelText={'Action'}  />
                    <Input id={'FirmwareId'} type={'text'} name={'FirmwareId'} value={formInfo.FirmwareId} onChange={handleChange}  />
                    <Validation error={errors.FirmwareId} />
                  </div>
                  <div>
                  <button className='group relative flex justify-center rounded-md bg-transparent text-orange-500 w-[16vw] mt-6  p-2 gap-5 tracking-wider font-normal duration-300'>
                  Add Member with same data<AddCircle variant="Outline"  className="h-5 w-5" /></button>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/reservation'} />
                  <SaveButton text='Save' />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  )
}


export default ReservationAddEdit
