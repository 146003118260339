import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { GetHotelPromotionManagementList } from '../../../Encapsulation/HotelDomainEntities/HotelPromotionManagement'

function PromotionsManagement() {
  document.title = 'Gladiance - Promotion Management'
  const tableHeading = ['Sr.No', 'Event', 'Image', 'Description', 'Action'];
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const [promotionList, setPromotionList] = useState([])

  const fetchPromotionList = async () => {
    setPromotionList(await GetHotelPromotionManagementList());
}

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  useEffect(() => {
    fetchPromotionList()
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <AddComponent title={'Promotion Managements'} button={
          <LinkButton to={'/promotion-management/add-promotion-management'}>
            <AddCircle variant='Bold' className='w-5 h-5' />
            <span>Add Promotion</span>
          </LinkButton>
        }>
          {promotionList && promotionList.length > 0 ? (
            <div className='m-2'>
              <Table tableHeading={tableHeading} >
                {promotionList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                  return (
                    <Fragment key={tableDataID}>
                      <tr className="border-b border-opacity-30 border-white text-center">
                        <td className="px-4 py-3">{tableData + 1}</td>
                        <td className="px-4 py-3">{tableData.Event}</td>
                        <td className="px-4 py-3">{tableData}</td>
                        <td className="px-4 py-3">{tableData.Description}</td>
                        <td className="px-4 py-3">
                          <div className='flex justify-center gap-3'>
                            <Link title='Edit' ><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                              <PencilSquareIcon className='w-4 h-4' />
                            </ActionButton></Link>
                            <ActionButton title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                              <TrashIcon className='w-4 h-4' />
                            </ActionButton>
                          </div>
                        </td>
                      </tr>

                    </Fragment>
                  )
                })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={'Sorry! Promotion Management Data Not Found'} />
          )}
          {promotionList && promotionList.length > 7 ? (<div className="flex justify-end">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={promotionList.length}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>) : (<div></div>)
          }
        </AddComponent>
      )}
    </Fragment>
  )
}

export default PromotionsManagement

