import React, { Fragment, useEffect, useState } from 'react'
import Card from '../views/helpers/Card'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
import SubDashboard from '../components/SubDashboard';
import { NewDashboard } from '../components/NewDashboard';
import axios from 'axios';
import Chart from '../components/Chart';
import BackComponent from '../components/BackComponent';

function Dashboard() {
    document.title = 'Gladiance - Dashboard'
   
    return (
        <Fragment>
            <BackComponent >
                <h1 className="text-2xl font-bold tracking-tight text-white">Dashboard</h1>
                {/* <div className='grid grid-cols-2 md:grid-cols-4 gap-6'>
                    <Card className={"bg-[#FFB240] text-center text-default-color p-4 relative h-40"}>
                        <h1 className='font-bold uppercase text-lg tracking-widest mt-2 mb-2'>Ongoing Projects</h1>
                        <h1 className='text-3xl font-bold mb-4'><CountUp end={90} duration={7} /></h1>
                        <Link to={'#'} className="bg-[rgba(0,0,0,0.6)] w-full absolute left-0 bottom-0 rounded-b-lg py-2.5 font-bold uppercase tracking-wider flex justify-center items-center gap-2">
                            <div className='bg-default-color rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className="bi bi-info text-secondary-color" viewBox="0 0 16 16"> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" /> </svg>
                            </div>
                            More Info
                        </Link>
                    </Card>
                    <Card className={"bg-[#60C29C] text-center text-default-color p-4 relative h-40"}>
                        <h1 className='font-bold uppercase text-lg tracking-widest mt-2 mb-2'>Completed Projects</h1>
                        <h1 className='text-3xl font-bold mb-4'><CountUp end={78} duration={7} /></h1>
                        <Link to={'#'} className="bg-[rgba(0,0,0,0.6)] w-full absolute left-0 bottom-0 rounded-b-lg py-2.5 font-bold uppercase tracking-wider flex justify-center items-center gap-2">
                            <div className='bg-default-color rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className="bi bi-info text-secondary-color" viewBox="0 0 16 16"> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" /> </svg>
                            </div>
                            More Info
                        </Link>
                    </Card>
                    <Card className={"bg-[#ED8F8F] text-center text-default-color p-4 relative h-40"}>
                        <h1 className='font-bold uppercase text-lg tracking-widest mt-2 mb-2'>Total Payments</h1>
                        <h1 className='text-3xl font-bold mb-4'><CountUp end={1030000} duration={7} /></h1>
                        <Link to={'#'} className="bg-[rgba(0,0,0,0.6)] w-full absolute left-0 bottom-0 rounded-b-lg py-2.5 font-bold uppercase tracking-wider flex justify-center items-center gap-2">
                            <div className='bg-default-color rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className="bi bi-info text-secondary-color" viewBox="0 0 16 16"> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" /> </svg>
                            </div>
                            More Info
                        </Link>
                    </Card>
                    <Card className={"bg-[#ABEF81] text-center text-default-color p-4 relative h-40"}>
                        <h1 className='font-bold uppercase text-lg tracking-widest mt-2 mb-2'>Total Sales</h1>
                        <h1 className='text-3xl font-bold mb-4'><CountUp end={10030000} duration={7} /></h1>
                        <Link to={'#'} className="bg-[rgba(0,0,0,0.6)] w-full absolute left-0 bottom-0 rounded-b-lg py-2.5 font-bold uppercase tracking-wider flex justify-center items-center gap-2">
                            <div className='bg-default-color rounded-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className="bi bi-info text-secondary-color" viewBox="0 0 16 16"> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" /> </svg>
                            </div>
                            More Info
                        </Link>
                    </Card>
                </div>  */}

                {/* <object data="http://africau.edu/images/default/sample.pdf" type="application/pdf" width="100%" height="500px">
                    <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
                </object> */}

                {/* <SubDashboard /> */}
                {
                    // userRole == 'Admin' && <NewDashboard />
                }
                {/* <div className="mt-5">
                    <Chart />
                </div> */}
            </BackComponent>
        </Fragment>
    )
}

export default Dashboard