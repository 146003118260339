import React, { Fragment, useEffect, useState } from 'react'
import Card from '../views/helpers/Card'
import Label from './helpers/Label'
import Input from './helpers/Input'
import { useStateContext } from '../context/ContextProvider'
import TextArea from './helpers/TextArea'
import Button from './helpers/Button'
import { DirectInbox } from 'iconsax-react'
import axiosClient from '../Encapsulation/Axios'
import Loader from './Common/Loader'
import Validation from './helpers/Validation'
import flasher from '@flasher/flasher'
import { useNavigate } from 'react-router-dom'
import AddComponent from '../components/AddComponent'

function YourProfile() {
    document.title = 'Gladiance - Your Profile'
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
    const [formInfo, setFormInfo] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
    })
    const [loader, setLoader] = useState(false);
    const handleChange = (e) => { setFormInfo(formInfo => ({ ...formInfo, [e.target.name]: e.target.value })) }
    const navigate = useNavigate()
    const [file, setFile] = useState('')
    const [errors, setErrors] = useState([])
    const handleFileChange = (e) => { setFile(e.target.files[0]) }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        const formData = new FormData()
        formData.append('name', formInfo.name)
        formData.append('email', formInfo.email)
        formData.append('mobile', formInfo.mobile)
        formData.append('address', formInfo.address)
        formData.append('profile_image', file)
        axiosClient.post(`/store_your_profile/${localStorage.getItem('CURRENT_USERID')}`, formData)
            .then(({ data }) => {
                setLoader(false)
                flasher.success(data.msg)
                localStorage.setItem('CURRENT_USERNAME', data.user_name)
                localStorage.setItem('CURRENT_EMAIL', data.user_email)
                localStorage.setItem('CURRENT_MOBILE', data.user_mobile)
                localStorage.setItem('CURRENT_ADDRESS', data.user_address)
                if (data.profile_image !== false) {
                    localStorage.setItem('CURRENT_PROFILEIMAGE', data.profile_image)
                }
                navigate('/')
            })
            .catch(({ response }) => {
                setLoader(false)
                setErrors(response.data.errors)
            })
    }
    useEffect(() => {
        setFormInfo({
            name: localStorage.getItem('CURRENT_USERNAME') || '',
            email: localStorage.getItem('CURRENT_EMAIL') || '',
            mobile: localStorage.getItem('CURRENT_MOBILE') || '',
            address: localStorage.getItem('CURRENT_ADDRESS') || '',
        })
        setFile(localStorage.getItem('CURRENT_PROFILEIMAGE'))
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Your Profile'}>
                    <div className='flex justify-center'>
                        <Card className={'w-full md:w-[55%]  p-4'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                    <div>
                                        <div>
                                            <Label htmlFor={'profile_image'} labelText={'Your Profile Image'} />
                                            <img src={file ? ((file.name) ? URL.createObjectURL(file) : (IMG_URL + '/assets/images/UserImages/' + file)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-[22vw] h-[31vh] rounded' />
                                            <Input id={'profile_image'} type={'file'} name={'profile_image'} value={formInfo.profile_image} onChange={handleFileChange} className={'p-[1px!important] mt-2'} />
                                            <Validation error={errors.profile_image} />
                                        </div>  
                                    </div>
                                    <div>
                                        <div className='mb-3'>
                                            <Label htmlFor={'name'} labelText={'Your Name'} />
                                            <Input id={'name'} type={'text'} name={'name'} value={formInfo.name} onChange={handleChange} />
                                            <Validation error={errors.name} />
                                        </div>
                                        <div className='mb-3'>
                                            <Label htmlFor={'email'} labelText={'Your Email-ID'} />
                                            <Input id={'email'} type={'text'} name={'email'} value={formInfo.email} onChange={handleChange} />
                                            <Validation error={errors.email} />
                                        </div>
                                        <div className='mb-3'>
                                            <Label htmlFor={'mobile'} labelText={'Your Mobile No.'} />
                                            <Input id={'mobile'} type={'text'} name={'mobile'} value={formInfo.mobile} maxLength={10} onChange={handleChange} />
                                            <Validation error={errors.mobile} />
                                        </div>
                                        <div className='mb-3'>
                                            <Label htmlFor={'address'} labelText={'Your Address'} />
                                            <TextArea id={'address'} name={'address'} value={formInfo.address} rows={4} onChange={handleChange} />
                                            <Validation error={errors.address} />
                                        </div>
                                    </div>
                                </div>
                                <Button className={'w-full mt-4'}>
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3"><DirectInbox variant='Bulk' className="h-5 w-5 " /></span>
                                    <span>Save Now</span>
                                </Button>
                            </form>
                        </Card>
                    </div>
                </AddComponent>
            )}
        </Fragment>
    )
}
export default YourProfile