import React, { Fragment, useEffect, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import { DeleteGAAProduct, GetGAAProjectListByCustomer } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGladianceCustomerList } from "../../../../Encapsulation/GladianceDomainEntities/Customer";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../../context/ContextProvider";

function GAAProject() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Projects";
  const tableHeading = ["Sr.No", "Project Name", "Address", "Country Name", "City Name", "Pin Code", "Email Id", "Website", "Telephone", "Language", "Currency", "Timezone", 'Time  Convention', "Temperature Convention", "IoT Data Retention Days", "Project Type",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [customerList, setCustomerList] = useState([]);
  const [GAAprojectList, setGAAProjectList] = useState([]);

  const { CustomerRef, handleCustomerRef } = useStateContext();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // fetch Project List According to Customer Ref //
  const fetchGAAProjectList = async () => {
    CustomerRef && setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  // Fetch Customer List For Dropdown //
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  const deleteProject = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProduct(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchGAAProjectList();
        }
      }
    })
  }

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == '' && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchGAAProjectList();
  }, [CustomerRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Projects"}
          button={
            <LinkButton to={"/gaa-project/add-gaa-project"} state={CustomerRef}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Project</span>
            </LinkButton>
          }
        >

          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
            <div>
              <Label
                htmlFor={"Customer"}
                labelText={"Gladiance Customer"}
              />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e)=> handleCustomerRef(e.target.value)}
                required={true}
              >
                {customerList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {GAAprojectList && GAAprojectList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {GAAprojectList &&
                  GAAprojectList
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((tableData, tableDataID) => {
                      // Ref, Name, Address, CityName, CountryName, Pincode, EmailId, Website, Telephone, CustomerRef : number, Language, Currency, Timezone, TimeConvention (12 hr or 24 hr) (Represented as Number), TemperatureConvention (Centigrade or Fahrenheit) (Represented as Number), IoTDataRetentionPeriodInDays : number, GAAProjectType (Equivalent to Hotel / Home) (Represented as Number)
                      return (
                        <Fragment key={tableDataID}>
                          <tr className="border-b border-opacity-30 border-white">
                            <td className="px-4 py-3">{tableDataID + 1}</td>
                            <td className="px-4 py-3">{tableData.Name}</td>
                            <td className="px-4 py-3">{tableData.Address}</td>
                            <td className="px-4 py-3">{tableData.CountryName}</td>
                            <td className="px-4 py-3"> {tableData.CityName}</td>
                            <td className="px-4 py-3">{tableData.Pincode}</td>
                            <td className="px-4 py-3">{tableData.EmailId}</td>
                            <td className="px-4 py-3">{tableData.Website}</td>
                            <td className="px-4 py-3">{tableData.Telephone}</td>
                            <td className="px-4 py-3">{tableData.Language}</td>
                            <td className="px-4 py-3">{tableData.Currency}</td>
                            <td className="px-4 py-3">{tableData.Timezone}</td>
                            <td className="px-4 py-3">{tableData.TimeConventionName}</td>
                            <td className="px-4 py-3">{tableData.TemperatureConventionName}</td>
                            <td className="px-4 py-3">{tableData.IoTDataRetentionPeriodInDays}</td>
                            <td className="px-4 py-3">{tableData.GAAProjectTypeName}</td>

                            <td className="px-4 py-3">
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/gaa-project/edit-gaa-project/${tableData.Ref}`}
                                  title="Edit"
                                >
                                  <ActionButton
                                    className={
                                      "p-1.5 text-white bg-yellow-400 "
                                    }
                                  >
                                    <PencilSquareIcon className="w-4 h-4" />
                                  </ActionButton>
                                </Link>
                                <ActionButton onClick={() => deleteProject(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Project Data Not Found"} />
          )}
          {GAAprojectList && GAAprojectList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={GAAprojectList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProject;
