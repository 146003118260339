import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import '../CSS/InputTime.css'
import { Link } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import moment from 'moment';
import Card from '../views/helpers/Card';
import Loader from '../views/Common/Loader';
import axiosClient from '../Encapsulation/Axios';

ChartJS.register(ArcElement, Tooltip, Legend);

const SubDashboard = ({ APPNAME, FURL, BURL }) => {
    document.title = 'Dashboard - ' + APPNAME
    const [eventData, setEventData] = useState([{
        id: '101',
        event_title: 'Morewadi Site',
        event_end_date: '2023-01-04',
        event_place: 'Kolhapur',
        total_peoples: '25'
    },
    {
        id: '102',
        event_title: 'Shree Infra',
        event_end_date: '2023-01-04',
        event_place: 'Kolhapur',
        total_peoples: '25'
    },
    {
        id: '103',
        event_title: 'Palash Residency',
        event_end_date: '2023-01-04',
        event_place: 'Kolhapur',
        total_peoples: '25'
    }])

    const [requestedMeetings, setRequestedMeetings] = useState([{
        alloted_start_time: '22:30',
        meeting_start_time: '23:30',
        alloted_end_time: '23:30',
        meeting_end_time: '23:30',
        user_name: 'Ijaj',
        no_of_peoples: '25'
    }])
    const getDprList = () => {
        setLoader(true)
        axiosClient.get('/Dprreport_list')
            .then(({ data }) => {
                setDprDetails(data.lists)
                setLoader(false)
            })
    }
    const [articlesCount, setArticlesCount] = useState({
        new_articles: 20,
        approved_articles: 25,
        published_articles: 25,
        rejected_articles: 25,
        draft_and_save_articles: 25
    })

    // const [newlyJoinedUsers, setNewlyJoinedUsers] = useState(['Suarj Khot', 'Shweta Patil', 'Maithilee Jadhav', 'Samiksha Shinde', 'Gautami Bhosale', 'IjajAhmed Akram Jaman', 'Tushar Chougule'])
    const [loader, setLoader] = useState(false)
    const [dprdetails, setDprDetails] = useState(false)
    useEffect(() => {
        getDprList();
    }, [])
    return (
        <Fragment>
            {loader ? (<Loader FURL={FURL} />) : (
                <Fragment>
                    <br />
                    <section className='px-4 py-2'>
                        <div className='grid grid-cols-1 md:grid-cols-2 justify-center gap-6 mb-6'>
                            <Card className='bg-white'>
                                <div className='flex flex-col md:flex-row justify-center md:justify-between items-center gap-4 mb-4'>
                                    <h1 className='font-bold uppercase mt-4 ml-3 tracking-wider'>Today's Requested Meeting's</h1>
                                    <Link to="/meetings/meetings-list" className='px-3 text-sm py-1 bg-[conic-gradient(at_left,_var(--tw-gradient-stops))] from-sky-400 to-blue-800 text-white rounded-md'>See All</Link>
                                </div>
                                <hr className='mt-1 mb-3 border-spacing-1 border-gray-400' />
                                <div className="overflow-x-auto relative border">
                                    <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
                                        <thead className="text-xs bg-gray-700 uppercase text-white dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="py-3 px-4">
                                                    Sr.No
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    Start Time
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    End Time
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    Full Name
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    Peoples
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestedMeetings.length > 0 ? (
                                                requestedMeetings.map((data, id) => {
                                                    return (
                                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={id}>
                                                            <td className="py-3 px-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" width="2%">
                                                                {id + 1}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.alloted_start_time ? (
                                                                    <input type="time" defaultValue={data.alloted_start_time} disabled className='p-0 border-none text-sm' />
                                                                ) : (
                                                                    <input type="time" defaultValue={data.meeting_start_time} disabled className='p-0 border-none text-sm' />
                                                                )}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.alloted_end_time ? (
                                                                    <input type="time" defaultValue={data.alloted_end_time} disabled className='p-0 border-none text-sm' />
                                                                ) : (
                                                                    <input type="time" defaultValue={data.meeting_end_time} disabled className='p-0 border-none text-sm' />
                                                                )}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.user_name}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.no_of_peoples}
                                                            </td>
                                                        </tr>
                                                    )
                                                })) : (
                                                <tr>
                                                    <td colSpan={6} className="text-lg text-center py-3 px-6">Sorry! No Requested Meetings Today</td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                            <Card className='bg-white'>
                                <div className='flex flex-col md:flex-row justify-center md:justify-between items-center gap-4 mb-4'>
                                    <h1 className='text-center font-bold mt-4 ml-3 uppercase tracking-wider'>Today's Scheduled Meeting's</h1>
                                    <Link to="/meetings/meetings-list" className='px-3 text-sm py-1 bg-[conic-gradient(at_left,_var(--tw-gradient-stops))] from-sky-400 to-blue-800 text-white rounded-md'>See All</Link>
                                </div>
                                <hr className='mt-1 mb-3 border-spacing-1 border-gray-400' />
                                <div className="overflow-x-auto relative border">
                                    <table className="w-full text-sm text-gray-500 dark:text-gray-400 text-center">
                                        <thead className="text-xs bg-gray-700 uppercase text-white dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="py-3 px-4">
                                                    Sr.No
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    Start Time
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    End Time
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    Full Name
                                                </th>
                                                <th scope="col" className="py-3 px-4">
                                                    Peoples
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestedMeetings.length > 0 ? (
                                                requestedMeetings.map((data, id) => {
                                                    return (
                                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={id}>
                                                            <td className="py-3 px-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" width="2%">
                                                                {id + 1}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.alloted_start_time ? (
                                                                    <input type="time" defaultValue={data.alloted_start_time} disabled className='p-0 border-none text-sm' />
                                                                ) : (
                                                                    <input type="time" defaultValue={data.meeting_start_time} disabled className='p-0 border-none text-sm' />
                                                                )}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.alloted_end_time ? (
                                                                    <input type="time" defaultValue={data.alloted_end_time} disabled className='p-0 border-none text-sm' />
                                                                ) : (
                                                                    <input type="time" defaultValue={data.meeting_end_time} disabled className='p-0 border-none text-sm' />
                                                                )}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.user_name}
                                                            </td>
                                                            <td className="py-3 px-4 whitespace-nowrap">
                                                                {data.no_of_peoples}
                                                            </td>
                                                        </tr>
                                                    )
                                                })) : (
                                                <tr>
                                                    <td colSpan={6} className="text-lg text-center py-3 px-6">Sorry! No Scheduled Meetings Today</td>
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        </div>
                        {/* <div className='flex flex-col md:flex-row gap-6 mb-6'> */}
                        <div className='grid grid-cols-1 md:grid-cols-3 justify-center gap-6 mb-1'>
                            <Card className='bg-white'>
                                <div className='flex flex-col md:flex-row justify-center md:justify-between items-center gap-4 mb-1'>
                                    <h1 className='text-center mt-1 ml-3 font-bold uppercase tracking-wider'>Latest Events</h1>
                                    <Link to="/activities/events" className='px-3 py-1 text-sm bg-[conic-gradient(at_left,_var(--tw-gradient-stops))] from-sky-400 to-blue-800 text-white rounded-md'>See All</Link>
                                </div>
                                <hr className='mt-2 mb-3 border-spacing-1 border-gray-400' />
                                <section className='p-4 overflow-y-auto'>
                                    {eventData.length > 0 ? (
                                        eventData.map((data, id) => {
                                            return (
                                                <ol className="relative border-l border-gray-200 dark:border-gray-700">
                                                    <li className="mb-6 ml-6">
                                                        <span className="flex ml-1 absolute -left-4 justify-center items-center w-8 h-8 bg-blue-200 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                                                            <svg aria-hidden="true" className="w-5 h-5 text-blue-600 dark:text-blue-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                                                        </span>
                                                        <Card classAdd={'cursor-pointer hover:shadow-blue-400'} className={'ml-1 p-1'} >
                                                            <h3 className="flex items-center font-semibold text-gray-900 dark:text-white text-sm overflow-hidden whitespace-nowrap mb-2"><span className="h-3 w-3 rounded-full bg-green-400 mr-2"></span>{data.event_title}</h3>
                                                            <div className='flex items-center gap-4'>
                                                                <div className='border border-r-2 border-y-0 border-l-0 pr-2 border-gray-400'>
                                                                    <p className='text-gray-500 text-xs mb-2'>{moment('2022-01-04').format('DD-MMM-YYYY')} To {moment(data.event_end_date).format('DD-MMM-YYYY')}</p>
                                                                    <p className='text-gray-500 text-xs'>10:00 AM To 12:00 PM</p>
                                                                </div>
                                                                <div>
                                                                    <p className='text-gray-500 text-sm mb-2 font-bold'>{data.event_place}</p>
                                                                    <p className='text-gray-500 text-xs'>Total Peoples : {data.total_peoples}</p>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </li>
                                                </ol>
                                            )
                                        })
                                    ) : (
                                        <p>Sorry! No Events Found</p>
                                    )}
                                </section>
                            </Card>
                            <Card className='bg-white'>
                                <div className='flex flex-col md:flex-row justify-center md:justify-between items-center gap-4 mb-1'>
                                    <h1 className='text-center font-bold mt-1 ml-3 uppercase tracking-wider'>Daily Progress Report</h1>
                                    <Link to="/new-articles" className='px-3 py-1 text-sm bg-[conic-gradient(at_left,_var(--tw-gradient-stops))] from-sky-400 to-blue-800 text-white rounded-md'>See All</Link>
                                </div>
                                <hr className='mt-2 mb-3 border-spacing-1 border-gray-400' />
                                <div className="overflow-x-auto relative border">
                                    <table className="w-full text-sm text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs bg-gray-700 uppercase text-white dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="py-2.5 px-4">
                                                    Sr.No
                                                </th>
                                                <th scope="col" className="py-2.5 px-4 text-left">
                                                    Work Name
                                                </th>
                                                <th scope="col" className="py-2.5 px-4 text-left">
                                                    Task Name
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dprdetails && dprdetails.map((data, id) => {
                                                return (
                                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={id}>
                                                        <td className="py-2.5 px-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center" width="2%">
                                                            {id + 1}
                                                        </td>
                                                        <td className="py-2.5 px-4 whitespace-nowrap">
                                                            {data.work_name}
                                                        </td>
                                                        <td className="py-2.5 px-4 whitespace-nowrap">
                                                            {data.task_name}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                            <Card className='bg-white'>
                                <div className='flex flex-col md:flex-row justify-center md:justify-between items-center gap-4 mb-1'>
                                    <h1 className='text-center font-bold mt-1 ml-3 uppercase tracking-wider'>Site Overview</h1>
                                    <Link to="/new-articles" className='px-3 py-1 text-sm bg-[conic-gradient(at_left,_var(--tw-gradient-stops))] from-sky-400 to-blue-800 text-white rounded-md'>See All</Link>
                                </div>
                                <hr className='mt-2 mb-3 border-spacing-1 border-gray-400' />
                                <section>
                                    <Pie data={{
                                        labels: [
                                            'New Articles',
                                            'Approved Articles',
                                            'Published Articles',
                                            'Rejected Articles',
                                            'Draft & Saved Articles'
                                        ],
                                        datasets: [{
                                            label: 'Articles',
                                            data: [
                                                articlesCount.new_articles,
                                                articlesCount.approved_articles,
                                                articlesCount.published_articles,
                                                articlesCount.rejected_articles,
                                                articlesCount.draft_and_save_articles
                                            ],
                                            backgroundColor: [
                                                '#2192FF',
                                                '#FED049',
                                                '#38E54D',
                                                '#CF0A0A',
                                                '#434242',
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }} />
                                </section>
                            </Card>
                        </div>
                    </section>
                </Fragment>
            )}

        </Fragment>
    )
}

export default SubDashboard