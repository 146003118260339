import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProductVersions";
const FetchRequestType = "GAAProductVersionFetchRequest";

export const GetGAAProductVersions = async (Ref) => {
  let payload = { GAAProductVersionRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGAAProductVersions = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetGAAProductVersionsList = async () => {
  let payload = { GAAProductVersionRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const DeleteGAAProductVersions = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GAAProductVersionByProduct = async (GAAProductRef) => {
  let payload = { GAAProductRefs: [GAAProductRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};
