import React, { Fragment, useEffect, useRef } from 'react'
import { useStateContext } from '../../context/ContextProvider'

function CustomSelect({ children, id, name, value, readOnly, disabled, inputFocus, className, onChange, required }) {
    const { theme } = useStateContext()
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputFocus == true) {
            inputRef.current.focus();
        }
    }, []);
    return (
        <Fragment>
            <select id={id} ref={inputRef} name={name} value={value} readOnly={readOnly} disabled={disabled} onChange={onChange} required={required} className={" block w-full rounded-md border-0 py-2.5 bg-transparent shadow-sm ring-1 ring-inset text-sm ring-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#f46f03] transition ease-in-out delay-150 duration-300 " + (theme ? 'text-default-color' : 'text-gray-800 ') + (className)}>
                {children}
            </select>
        </Fragment>
    )
}

export default CustomSelect