import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import Label from "../../../helpers/Label";
import CustomSelect from "../../../helpers/CustomSelect";
import Option from "../../../helpers/Option";
import {
  GetGAAProjectSpaceTypeListByProject,
  GetProjectSpaceTypeList,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import {
  DeleteProjectSpaceTypeArea,
  GetGAAProjectSpaceTypeAreaListBySpaceType,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { GetGAAProjectList } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../../context/ContextProvider";

function GAAProjectSpaceTypeArea({ Rights }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Project Space Type Areas ";
  const tableHeading = ["Sr.No", "Name", "Action"];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState();
  const [SpaceTypeAreaList, setSpaceTypeAreaList] = useState();
  const {
    GAAProjectRef,
    GAAProjectSpaceTypeRef,
    handleProjectRef,
    handleSpaceTypeRef,
  } = useStateContext();
  console.log("GAAProjectSpaceTypeRef :", GAAProjectSpaceTypeRef);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // To Print The Table //
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchProjectList = async () => {
    setGAAProjectList(await GetGAAProjectList());
  };

  // Space Type List Dropdown Function //
  const fetchSpaceTypeListByProject = async () => {
    GAAProjectRef &&
      setGAAProjectSpaceTypeList(
        (await GetGAAProjectSpaceTypeListByProject(GAAProjectRef)).filter(
          (e) => e.HasAreas == 1
        )
      );
  };

  // fetch Space Type Area List According to Project Space Type Ref //
  const fetchGAAProjectSpaceTypeAreaListBySpaceType = async () => {
    GAAProjectSpaceTypeRef &&
      setSpaceTypeAreaList(
        await GetGAAProjectSpaceTypeAreaListBySpaceType(GAAProjectSpaceTypeRef)
      );
  };

  const deleteProjectSpaceTypeArea = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteProjectSpaceTypeArea(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchSpaceTypeListByProject();
        }
      }
    });
  };

  useEffect(() => {
    fetchProjectList();
  }, []);

  useEffect(() => {
    if (GAAProjectRef == "" && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  // Space Type List Dropdown UseEffect //
  useEffect(() => {
    fetchSpaceTypeListByProject();
  }, [GAAProjectRef]);

  //  Set Zero Index Project Space Type Name For Dropdown
  useEffect(() => {
    if (GAAProjectSpaceTypeRef == "" && GAAProjectSpaceTypeList) {
      handleSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
    }
  }, [GAAProjectSpaceTypeList]);

  // fetch Space Type Area List According to Project Space Type Ref //
  useEffect(() => {
    fetchGAAProjectSpaceTypeAreaListBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Project Space Type Areas"}
          button={
            <LinkButton
              to={
                "/gaa-project-space-type-area/add-gaa-project-space-type-area"
              }
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={GAAProjectSpaceTypeRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Project Space Type Area</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"GAAProjectRef"} labelText={" Project"} />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => handleProjectRef(e.target.value)}
                required={true}
              >
                {GAAProjectList &&
                  GAAProjectList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeRef"}
                labelText={"Project Space Type"}
              />
              <CustomSelect
                id={"GAAProjectSpaceTypeRef"}
                name={"GAAProjectSpaceTypeRef"}
                value={GAAProjectSpaceTypeRef}
                onChange={(e) => handleSpaceTypeRef(e.target.value)}
              >
                <Option value={""}>-- Select --</Option>
                {GAAProjectSpaceTypeList &&
                  GAAProjectSpaceTypeList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
          </div>
          {SpaceTypeAreaList && SpaceTypeAreaList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {SpaceTypeAreaList &&
                  SpaceTypeAreaList.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  ).map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/gaa-project-space-type-area/edit-gaa-project-space-type-area/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton
                                  onClick={() =>
                                    deleteProjectSpaceTypeArea(tableData)
                                  }
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound
              labelText={"Sorry! Project Space Type Area Data Not Found"}
            />
          )}
          {SpaceTypeAreaList && SpaceTypeAreaList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={SpaceTypeAreaList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceTypeArea;
