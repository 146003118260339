import React, { Fragment, useEffect, useState } from "react";
import BackComponent from "../../../components/BackComponent";
import Card from "../../helpers/Card";
import Loader from "../../Common/Loader";
import CheckBox from "../../helpers/CheckBox";
import Table from "../../helpers/Table";
import SaveButton from "../../helpers/SaveButton";
import { SaveGladianceControlBouquet } from "../../../Encapsulation/GladianceDomainEntities/GladianceControlBouquet";
import flasher from "@flasher/flasher";
import { GetGladianceUserRightsList } from "../../../Encapsulation/GladianceDomainEntities/GladianceUserRights";

function ControlBouquetMaster() {

  document.title = "Gladiance - Control Bouquet";

  const tableHeading = [
    "Sr. No.",
    "Contols",
    "Designer Solution",
    "Hotel Instant Solution",
    "Hotel Instant Solution",
    "Home Instant Solution",
  ];
  const [loader, setLoader] = useState(false);
  const [ControlBouquet, setControlBouquet] = useState([])
  console.log('ControlBouquet :', ControlBouquet);

  const handleCheck = (UserRoleRef, Activity, value, e) => {
    ControlBouquet.map((data) => {
      if (data.UserRoleRef == UserRoleRef) {
        if (data.Activity == Activity) {
          data[e.target.name] = !value
        }
      }
    })
  }

  const handleSubmit = async () => {
    let result = await SaveGladianceControlBouquet(ControlBouquet);
    console.log('result :', result);

    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
    }
  }

  const fetchControlBouquet = async () => {
    setControlBouquet(await GetGladianceUserRightsList())
  }

  useEffect(() => {
    fetchControlBouquet();
  }, [])

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title="Control Bouquets"
        >
          <div>
            <Card className={"w-full p-6   "}>
              <div className="h-2/4">
                <Table tableHeading={tableHeading}>
                  {
                    ControlBouquet && ControlBouquet.map((data, id) => {
                      return (
                        <Fragment key={id}>
                          <tr className="border-b border-opacity-20 border-white">
                            <td className="px-6 py-3"> {id + 1} </td>
                            <td className="px-6 py-3"> {data.Activity} </td>
                            <td className="px-6 py-3"><CheckBox id={'CanAdd'} name={'CanAdd'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.Activity, data.CanAdd, e)} checked={data.CanAdd} /></td>
                            <td className="px-6 py-3"><CheckBox id={'CanEdit'} name={'CanEdit'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.Activity, data.CanEdit, e)} checked={data.CanEdit} /></td>
                            <td className="px-6 py-3"><CheckBox id={'CanDelete'} name={'CanDelete'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.Activity, data.CanDelete, e)} checked={data.CanDelete} /></td>
                            <td className="px-6 py-3"><CheckBox id={'CanView'} name={'CanView'} type={'checkbox'} onClick={(e) => handleCheck(data.UserRoleRef, data.Activity, data.CanView, e)} checked={data.CanView} /></td>
                          </tr>
                        </Fragment>
                      )
                    })
                  }
                </Table>
                <div className=" z-auto absolute w-full bottom-1 right-7 rounded-md mb-1 opacity-90 ">
                  <div className="flex gap-8 justify-center mt-6 ">
                    <SaveButton text="Save" onClick={() => handleSubmit()} />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default ControlBouquetMaster;
