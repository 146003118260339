import React, {useEffect, Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import flasher from '@flasher/flasher'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { ArrowCircleLeft2, CloseCircle, DirectInbox } from 'iconsax-react'
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/solid'
import CancelButton from '../../helpers/CancelButton'
import TextArea from '../../helpers/TextArea'
import SaveButton from '../../helpers/SaveButton'
import { useStateContext } from '../../../context/ContextProvider'
import { ClearInfo } from '../../../Encapsulation/Utils'
import { GetHotelPromotionManagement, SaveHotelPromotionManagement } from '../../../Encapsulation/HotelDomainEntities/HotelPromotionManagement'


function PromotionsManagementAddEdit() {
  let formMode = 'New';
  const { id } = useParams()
  if (id) {
    document.title = 'Gladiance - Edit Promotion Management'
    formMode = 'Edit'
  } else {
    document.title = 'Gladiance - Add Promotion Management'
  }
  const Navigation = useNavigate()
  const { theme } = useStateContext()
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const [formInfo, setFormInfo] = useState({})
  const [imgInfo, setImgInfo] = useState({})

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let result = await SaveHotelPromotionManagement(id, formInfo,
      [
        ['PromotionImg', imgInfo.PromotionImg],
      ]);
    console.log('result :', result);

    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      if (formMode == 'Edit') {
        Navigation('/laundry');
      }
      else {
        setFormInfo(ClearInfo(formInfo))
        setImgInfo(ClearInfo(imgInfo))
      }
    }

  }

  const fetchPromotion = async () => {
    setFormInfo(await GetHotelPromotionManagement(id));
  }

  useEffect(() => {
    if (id) {
      fetchPromotion();
    }
  }, [])
  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={id ? 'Edit Promotion Management' : 'Add Promotion Management'} button={
          <LinkButton to={'/promotion-management'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />

          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-10 mb-4'>
                  <div>
                    <Label htmlFor={'Event'} labelText={'Event'}  />
                    <Input id={'Event'} type={'Event'} name={'Event'} value={formInfo.Event} onChange={handleChange} />
                    <Validation error={errors.Event} />
                  </div>
                  <div className=''>
                    <Label htmlFor={'Upload Image'} labelText={'Upload Image'} className={'text-transparent'} />
                    <div className="flex justify-start items-center gap-10">
                      <span className={(theme ? 'text-default-color' : 'text-gray-800')}>Upload Image</span>
                      <label for="imgInfo.PromotionImg" className="flex flex-col items-center justify-center">
                        <div className="flex flex-col items-center justify-center">
                          <ArrowUpTrayIcon className='w-10 h-10 cursor-pointer' />
                        </div>
                        <input id="imgInfo.PromotionImg" name='imgInfo.PromotionImg' type="file" onChange={(e) => setFormInfo(e.target.files[0])} className="hidden p-[0px!important] mt-2" />
                      </label>
                    </div>
                    <Validation error={errors.CameraName} />
                  </div>
                  <div className='col-span-2'>
                    <Label htmlFor={'Description'} labelText={'Description'}  />
                    <TextArea id={'Description'} type={'text'} name={'Description'} value={formInfo.Description} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.Description} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/promotion-management'} />
                  <SaveButton text='Save' />
                </div>

              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  )
}


export default PromotionsManagementAddEdit