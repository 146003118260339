import React, { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { ArrowCircleLeft2 } from 'iconsax-react'
import { useStateContext } from '../../../context/ContextProvider'
import { PrinterIcon, QrCodeIcon } from '@heroicons/react/24/solid'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import Option from '../../helpers/Option'

function DeviceProgrammingAddEdit() {
    const { id } = useParams()
    {
        id ? (
            document.title = 'Gladiance - Edit Device Programming Master'
        ) : (
            document.title = 'Gladiance - Add Device Programming Master'
        )
    }
    const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
    const [Image, setImage] = useState('')
    const handleImage = (e) => {
        setImage(e.target.files[0]);
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const navigate = useNavigate()
    const [formInfo, setFormInfo] = useState({
        ProductId: '',
        Name: '',
        DesignId: '',
        FirmwareId: '',
        FirmwareFile: '',

    })

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const loginToken = 50000002;

        // if (!id) {
        //   try {
        //     let tr = (await axiosClient.post(
        //       `/request/allocatesingleid/${loginToken}`
        //     )).data;

        //     if (!tr.Successful) {
        //       throw tr.Message;
        //     } else {
        //       id = +tr.Tag;
        //     }
        //   } catch (errors) {
        //     setErrors(errors);
        //   }
        // }

        // let response = await AllocateSingleId(id);
        const formData = new FormData()
        formData.append("Meta_LoginToken", loginToken.toString());
        formData.append("Meta_RequestType", "Save");
        formData.append("Meta_MasterTableName", "GAADeviceProgrammingRecordMaster");
        formData.append('ProductId :', formInfo.ProductId);
        formData.append('Name :', formInfo.Name);
        formData.append('DesignId :', formInfo.DesignId);
        formData.append('FirmwareId :', formInfo.FirmwareId);
        formData.append('FirmwareFile :', formInfo.FirmwareFile);
        formData.append("Img_Image", Image);

        // try {
        //   let tr = (await axiosClient.post(
        //     `/request/acceptrequest2`, formData
        //   )).data;

        //   if (!tr.Successful) {
        //     throw tr.Message;
        //   } else {
        //     let td = JSON.parse(tr.Tag);
        //     console.log(td);
        //   }
        // } catch (errors) {
        //   setErrors(errors);
        // }
    }

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Device Programming' : 'Add Device Programming'} button={
                    <LinkButton to={'/device-programming'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />

                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'ProductId'} labelText={'Product'} />
                                        <CustomSelect inputFocus={true} id={'ProductId'} name={'ProductId'} value={formInfo.ProductId} onChange={handleChange}>
                                            <Option value={''}>---  Select Product  ---</Option>
                                            <Option value={'Product Name (Code)'}>Product Name (Code)</Option>
                                        </CustomSelect>
                                        <Validation error={errors.ProductId} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'Product Name'} />
                                        <Input id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} />
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'DesignId'} labelText={'Design Id'} />
                                        <Input id={'DesignId'} type={'text'} name={'DesignId'} value={formInfo.DesignId} onChange={handleChange} />
                                        <Validation error={errors.currency_symbol} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 '>
                                    <div>
                                        <div className='mb-6  mt-4' >
                                            <Label htmlFor={'FirmwareId'} labelText={'Firmware ID'} />
                                            <Input id={'FirmwareId'} type={'text'} name={'FirmwareId'} value={formInfo.FirmwareId} onChange={handleChange} />
                                            <Validation error={errors.FirmwareId} />
                                        </div>
                                        <div className='mb-4'>
                                            <Label htmlFor={'FirmwareFile'} labelText={'Firmware File'} />
                                            <Input id={'FirmwareFile'} type={'text'} name={'FirmwareFile'} value={formInfo.FirmwareFile} onChange={handleChange} />
                                            <Validation error={errors.FirmwareFile} />
                                        </div>
                                    </div>
                                    <div>
                                        <Label htmlFor={'Image'} labelText={'Image'} />
                                        <img src={Image ? ((Image.name) ? URL.createObjectURL(Image) : (IMG_URL + '/assets/images/Userimages/' + Image)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-24 h-24 rounded' />
                                        <Input id={'Image'} type={'file'} name={'Image'} onChange={handleImage} className={'p-[2px!important] mt-2'} />
                                        <Validation error={errors.Image} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-8'>
                                    <button className={'w-60 mt-6 mx-auto "group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color " '}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><QrCodeIcon variant='Bulk' className="h-5 w-5" /></span>
                                        <span className='ml-5'>Claim and Flash</span>
                                    </button>
                                    <button className={'w-60 mt-6 mx-auto "group relative flex justify-center rounded-md bg-primary-color p-2 tracking-wider font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color " '}>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3"><PrinterIcon variant='Bulk' className="h-5 w-5" /></span>
                                        <span className='ml-5'>Print</span>
                                    </button>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/device-programming'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}




export default DeviceProgrammingAddEdit
