import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAComboMaster";
const FetchRequestType = "GAAComboFetchRequest";

// Fetch Single data from Server //
export const GetCombo = async (Ref) => {
  let payload = { GAAComboRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};
// Save all data from Server //
export const SaveCombo = async (ref, payload, images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

// Fetch all data from Server  //
export const GetComboList = async () => {
  let payload = { GAAComboRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  // result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};
