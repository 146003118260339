import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon,TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Encapsulation/Axios'
import { Link } from 'react-router-dom'

import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteGladianceCountry, GetGladianceCountryList } from '../../../Encapsulation/GladianceDomainEntities/GladianceCountryMaster'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
  
    function CountryMaster() {
     
        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }
            document.title = 'Gladiance - Country Master'
            const tableHeading = ['Sr.No', 'Country Name','Currency', 'Currency Symbol','Currency Name','Action']
            const [loader, setLoader] = useState(false)
            const [currentPage, setCurrentPage] = useState(1);
            const itemsPerPage = 7;
            const [errors, setErrors] = useState([])
            const [gladianceCountryList, setGladianceCountryList] = useState([]);

            const fetchGladianceCountryList = async () => {
                setGladianceCountryList(await GetGladianceCountryList(setErrors));
            }

            const handlePageChange = (pageNumber) => {
                setCurrentPage(pageNumber);
            };
        
            const componentRef = useRef();
            const handlePrint = useReactToPrint({
                content: () => componentRef.current,
            });
        
            const handleGladianceDepartmentMasterCsv = () => {
                // setLoader(true)
                // const formData = new FormData()
                // formData.append('GladianceDepartmentMasterCsv', GladianceDepartmentMasterCsv)
                // axiosClient.post(`import_material`, formData)
                //     .then(({ data }) => {
                //         flasher.success(data.msg)
                //         setLoader(false)
                //     })
                //     .catch(({ response }) => {
                //         setLoader(false)
                //     })
                // setViewModel(false)
                // getGladianceCountryList()
            }
        
            const deleteCountry = (tableData) => {
                Swal.fire({
                  title: 'Are you sure to delete this?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    let response = await DeleteGladianceCountry(tableData)
                    if (response.Successful == false) {
                      flasher.warning(response.Message)
                    } else {
                      flasher.success('Deleted Successfully')
                      fetchGladianceCountryList();
                    }
                  }
                })
              }

            useEffect(() => {
                fetchGladianceCountryList();
            }, [])
        
            return (
                <Fragment>
                    {loader ? (<Loader />) : (
                        <AddComponent title={'Country Masters'} button={
                            <LinkButton to={'/country-master/add-country-master'}>
                                <AddCircle variant='Bold' className='w-5 h-5' />
                                <span>Add Country Details</span>
                            </LinkButton>
                        }>
                            {gladianceCountryList && gladianceCountryList.length > 0 ? (
                                <div ref={componentRef} className='m-2'>
                                    <Table tableHeading={tableHeading}>
                                        {gladianceCountryList && gladianceCountryList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                            return (
                                                <Fragment key={tableDataID}>
                                                     <tr className="border-b border-opacity-30 border-white">
                                                        <td className="px-4 py-3">{tableDataID + 1}</td>
                                                        <td className="px-4 py-3">{tableData.Name}</td>
                                                        <td className="px-4 py-3">{tableData.Currency}</td>
                                                        <td className="px-4 py-3">{tableData.CurrencySymbol}</td>
                                                        <td className="px-4 py-3">{tableData.CurrencyName}</td>
                                                        <td className="px-4 py-3">
                                                            <div className='flex justify-center gap-3'>
                                                                <Link to={`/country-master/edit-country-master/${tableData.Ref}`} title='Edit' ><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                                    <PencilSquareIcon className='w-4 h-4' />
                                                                </ActionButton></Link>
                                                                <ActionButton onClick={() => deleteCountry(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                    <TrashIcon className='w-4 h-4' />
                                                                </ActionButton>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            )
                                        })}
                                    </Table>
                                </div>
        
                            ) : (
                            <DataNotFound labelText={'Sorry! Country Master Data Not Found'}/>
                            )}
                            {gladianceCountryList && gladianceCountryList.length > 7 ? (<div className="flex justify-end">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={gladianceCountryList.length}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>) : (<div></div>)
                            }
        
                        </AddComponent>
                    )}
                </Fragment>
            )
        }    

export default CountryMaster
