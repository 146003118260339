
import React, { useEffect, Fragment, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetGAAProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import {
  ClearInfo,
  RBItemCategoryList,
  RBItemTasteList,
} from "../../../../Encapsulation/Utils";
import Loader from "../../../Common/Loader";
import BackComponent from "../../../../components/BackComponent";
import { ArrowCircleLeft2 } from "iconsax-react";
import Card from "../../../helpers/Card";
import Input from "../../../helpers/Input";
import Label from "../../../helpers/Label";
import Validation from "../../../helpers/Validation";
import SaveButton from "../../../helpers/SaveButton";
import CancelButton from "../../../helpers/CancelButton";
import LinkButton from "../../../helpers/LinkButton";
import flasher from "@flasher/flasher";
import CustomSelect from "../../../helpers/CustomSelect";
import Option from "../../../helpers/Option";
import TextArea from "../../../helpers/TextArea";
import { GetRBItemApplicableServingSlot, SaveRBItemApplicableServingSlot } from "../../../../Encapsulation/HotelDomainEntities/GAAProjectRBItemApplicableServingSlot";
import { GetRBItemMaster } from "../../../../Encapsulation/HotelDomainEntities/RBItemMaster";
import CheckBox from "../../../helpers/CheckBox";
import { GetRBItemServingSlot, GetRBItemServingSlotList, GetRBItemServingSlotListByProject } from "../../../../Encapsulation/HotelDomainEntities/RBItemServingSlot";

function GAAProjectRBItemApplicableServingSlotsAddEdit({ Rights }) {
  let formMode = "New";
  const { id, GAAProjectRBItemRef, GAAProjectRBItemServingSlotRef } = useParams();
  if (id, GAAProjectRBItemRef, GAAProjectRBItemServingSlotRef) {
    document.title = "Gladiance - Edit RB item Applicable Serving Slot";
    formMode = "Edit";
  } else {
    document.title = "Gladiance - Add RB item Applicable Serving Slot";
  }
  const Navigation = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const [GAAProjectRBItemServingSlotList, setGAAProjectRBItemServingSlotList] = useState([]);
  const Location = useLocation();

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveRBItemApplicableServingSlot(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/rb-item-applicable-serving-slots");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProject();
      }
    }
  };
  const fetchProject = async () => {
    let RBItem = await GetRBItemMaster(Location.state.GAAProjectRBItemRef);
    let Project = await GetGAAProject(Location.state.projectRef);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name,
      GAAProjectRBItemRef: RBItem.Ref,
      GAAProjectRBItemName: RBItem.Name,
      GAAProjectRBItemCategory: RBItem.GAAProjectRBItemCategory,
      GAAProjectRBItemCategoryName: RBItem.GAAProjectRBItemCategoryName,
      GAAProjectRBItemTaste: RBItem.GAAProjectRBItemTaste,
      GAAProjectRBItemTasteName: RBItem.GAAProjectRBItemTasteName,
    }));
  };

  const fetchProjectRBItemServingSlot = async () => {
    formInfo.GAAProjectRef &&
      setGAAProjectRBItemServingSlotList(await GetRBItemServingSlotListByProject(formInfo.GAAProjectRef));
  };

  const fetchRBItemApplicableServingSlots = async () => {
    setFormInfo(await GetRBItemApplicableServingSlot(GAAProjectRBItemRef, GAAProjectRBItemServingSlotRef));
  };

  useEffect(() => {
    if (Location.state) {
      fetchProject();
    }
    if (GAAProjectRBItemRef, GAAProjectRBItemServingSlotRef) {
      fetchRBItemApplicableServingSlots();
    }
  }, []);

  useEffect(() => {
    fetchProjectRBItemServingSlot();
  }, [formInfo.GAAProjectRef]);


  // useEffect(() => {
  //   if (GAAProjectRBItemServingSlotList.length > 0) {
  //     setFormInfo((formInfo) => ({
  //       ...formInfo,
  //       GAAProjectRBItemServingSlotRef: GAAProjectRBItemServingSlotList[0].Ref,
  //     }));
  //   }
  // }, [GAAProjectRBItemServingSlotList]);


  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={(id, GAAProjectRBItemRef, GAAProjectRBItemServingSlotRef) ? "Edit RB item Applicable Serving Master" : "Add RB item Applicable Serving Master"}
          button={
            <LinkButton to={"/rb-item-applicable-serving-slots"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  {/* <div>
                    <Label
                      htmlFor={"GAAProjectName"}
                      labelText={"Project Name"}
                    />
                    {formInfo.GAAProjectRef && (
                      <Input
                        id={"GAAProjectName"}
                        type={"text"}
                        name={"GAAProjectName"}
                        value={formInfo.GAAProjectName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div> */}
                  <div>
                    <Label
                      htmlFor={"GAAProjectRBItemName"}
                      labelText={"Project RB Item Name"}
                    />
                    {formInfo.GAAProjectRBItemRef && (
                      <Input
                        id={"GAAProjectRBItemName"}
                        type={"text"}
                        name={"GAAProjectRBItemName"}
                        value={formInfo.GAAProjectRBItemName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label htmlFor={"GAAProjectRBItemServingSlotRef"} labelText={"Serving Slot"} />
                    <CustomSelect
                      id={"GAAProjectRBItemServingSlotRef"}
                      name={"GAAProjectRBItemServingSlotRef"}
                      value={formInfo.GAAProjectRBItemServingSlotRef}
                      onChange={handleChange}
                      inputFocus={true}
                      readOnly={!Rights.Edit}
                      required={true}
                    >
                      <Option value={""}>
                        --- Select Serving Slot ---
                      </Option>
                      {GAAProjectRBItemServingSlotList.map((data, id) => {
                        return (
                          <Fragment key={id}>
                            <Option value={data.Ref}>{data.Name}</Option>
                          </Fragment>
                        );
                      })}
                    </CustomSelect>
                  </div>
                  {/* <div>
                    <Label
                      htmlFor={"GAAProjectRBItemCategoryName"}
                      labelText={"GAAProjectRBItemCategoryName"}
                    />
                    {formInfo.GAAProjectRBItemCategory && (
                      <Input
                        id={"GAAProjectRBItemCategoryName"}
                        type={"text"}
                        name={"GAAProjectRBItemCategoryName"}
                        value={formInfo.GAAProjectRBItemCategoryName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectRBItemTasteName"}
                      labelText={"GAAProjectRBItemTasteName"}
                    />
                    {formInfo.GAAProjectRBItemTaste && (
                      <Input
                        id={"GAAProjectRBItemTasteName"}
                        type={"text"}
                        name={"GAAProjectRBItemTasteName"}
                        value={formInfo.GAAProjectRBItemTasteName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div> */}
                  <div className="ml-5">
                    <Label htmlFor={"IsApplicable"} labelText={"Is Applicable"} />
                    <div className={' p-2 pl-6'}>
                      <CheckBox readOnly={!Rights.Edit} id={'IsApplicable'} name={'IsApplicable'} type={'checkbox'} onClick={(e) => setFormInfo({ ...formInfo, IsApplicable: e.target.checked })} checked={formInfo.IsApplicable} />
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/rb-item-applicable-serving-slots"} />
                  {Rights.Edit && <SaveButton text="Save" />}
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectRBItemApplicableServingSlotsAddEdit;
