import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";


const MasterTableName = "GAAProjectMaster";
const FetchRequestType = "GAAProjectFetchRequest";

export const GetGAAProject = async (Ref) => {
  let payload = { GAAProjectRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGAAProject = async (ref, payload, images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

export const GetGAAProjectList = async () => {
  let payload = { GAAProjectRefs: [], CustomerRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const DeleteGAAProduct = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectListByCustomer = async (customerRef) => {
  let payload = { GAAProjectRefs: [], CustomerRefs: [customerRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

