import React, {useEffect, Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import flasher from '@flasher/flasher'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { AddCircle, ArrowCircleLeft2, CloseCircle, DirectInbox } from 'iconsax-react'
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/solid'
import CancelButton from '../../helpers/CancelButton'
import CheckBox from '../../helpers/CheckBox'
import { ClearInfo } from '../../../Encapsulation/Utils'
import SaveButton from '../../helpers/SaveButton'
import { GetHotelMiscellaneous, SaveHotelMiscellaneous } from '../../../Encapsulation/HotelDomainEntities/HotelMiscellaneous'

function MiscellaneousParameterAddEdit() {
  let formMode = 'New';
  const { id } = useParams()
  if (id) {
    document.title = 'Gladiance - Edit Miscellaneous Parameter'
    formMode = 'Edit'
  } else {
    document.title = 'Gladiance - Add Miscellaneous Parameter'
  }
  const Navigation = useNavigate()
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  
  const [formInfo, setFormInfo] = useState({})

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let result = await SaveHotelMiscellaneous(id, formInfo, []);
    console.log('result :', result);

    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      if (formMode == 'Edit') {
        Navigation('/miscellaneous-parameter');
      }
      else {
        setFormInfo(ClearInfo(formInfo))
      }
    }
  }

  const fetchMiscellaneous = async () => {
    setFormInfo(await GetHotelMiscellaneous(id));
  }

  useEffect(() => {
    if (id) {
      fetchMiscellaneous();
    }
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={id ? 'Edit In Room Miscellaneous Parameter' : 'Add In Room Miscellaneous Parameter'} button={
          <LinkButton to={'/miscellaneous-parameter'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-4'>
                  <div>
                    <Label htmlFor={'Item'} labelText={'Room Type'}  />
                    <Input id={'Item'} type={'Item'} name={'Item'} value={formInfo.Item} onChange={handleChange} />
                    <Validation error={errors.Item} />
                  </div>
                  <div>
                    <Label htmlFor={'VideoURL'} labelText={'Area'}  />
                    <Input id={'VideoURL'} type={'text'} name={'VideoURL'} value={formInfo.VideoURL} onChange={handleChange}
                       />
                    <Validation error={errors.VideoURL} />
                  </div>
                  <div>
                    <Label htmlFor={'Category'} labelText={'Parameter'}  />
                    <Input id={'Category'} type={'text'} name={'Category'} value={formInfo.Category} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.Category} />
                  </div>
                  <div>
                    <Label htmlFor={'Price'} labelText={'Value'}  />
                    <Input id={'Price'} type={'Price'} name={'Price'} value={formInfo.Price} onChange={handleChange} />
                    <Validation error={errors.Price} />
                  </div>
                  <div>
                  <button className='group relative flex justify-center rounded-md bg-transparent text-orange-500 w-[12vw]  p-2 gap-5 tracking-wider font-normal duration-300'>
                   Add Area<AddCircle variant="Outline"  className="h-5 w-5" /></button>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/miscellaneous-parameter'} />
                  <SaveButton text='Save' />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  )
}


export default MiscellaneousParameterAddEdit
