import React, { Fragment, useEffect, useState } from 'react'
import flasher from '@flasher/flasher'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import BackComponent from '../../../components/BackComponent'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { useStateContext } from '../../../context/ContextProvider'
import { GetGladianceDepartment, SaveGladianceDepartment } from '../../../Encapsulation/GladianceDomainEntities/GladianceDepartment'
import { ClearInfo } from '../../../Encapsulation/Utils'

function GladianceDepartmentMasterAddEdit({ Rights}) {
    let formMode = 'New';
    let { id } = useParams()

    if (id) {
        document.title = 'Gladiance - Edit Department';
        formMode = 'Edit'
    } else {
        document.title = 'Gladiance - Add Department'
    }

    const { FRONTEND_URL, IMG_URL } = useStateContext()

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    console.log('errors :', errors);
    const Navigation = useNavigate()
    const location = useLocation()
    const state = location.state.currentPage
    const [formInfo, setFormInfo] = useState({})
    const [imgInfo, setImgInfo] = useState({})
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let result = await SaveGladianceDepartment(id, formInfo,
            [
                ['DepartmentLogo', imgInfo.DepartmentLogo],
                ['DepartmentImage', imgInfo.DepartmentImage]
            ]);
        console.log('result :', result);

        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/gladiance-department-master', {state: state });
            }
            else {
                setFormInfo(ClearInfo(formInfo))
                setImgInfo(ClearInfo(imgInfo))
            }
        }

    }

    const fetchDepartment = async () => {
        setFormInfo(await GetGladianceDepartment(id));
    }

    useEffect(() => {
        if (id) {
            fetchDepartment();
        }
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Department ' : 'Add Department'} button={
                    <LinkButton to={'/gladiance-department-master'} state={location.state.currentPage}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex'>
                        <Card className={'w-full px-4 py-4 justify-center items-center'}>
                            <form onSubmit={(e) => handleSubmit(e)} >
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                                    <div className=' md:relative'>
                                        <div className='md:absolute w-full bottom-0'>
                                            <Label htmlFor={'Name'} labelText={'Department Name'} />
                                            <Input inputFocus={true} id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} required={true} readOnly={!Rights.Edit} />
                                        </div>
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'DepartmentLogo'} labelText={'Department Logo'} />
                                        <img src={imgInfo.DepartmentLogo ? ((imgInfo.DepartmentLogo.name) ? URL.createObjectURL(imgInfo.DepartmentLogo) : (IMG_URL + '/assets/images/UserImages/' + imgInfo.DepartmentLogo)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-[10vw] h-[10vw] rounded' />
                                        <Input id={'DepartmentLogo'} type={'file'} name={'DepartmentLogo'} onChange={(e) => setImgInfo({ ...imgInfo, DepartmentLogo: e.target.files[0] })} className={'p-[1px!important] mt-2'} />
                                        <Validation error={errors.DepartmentLogo} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'DepartmentImage'} labelText={'Department Image'} />
                                        <img src={imgInfo.DepartmentImage ? ((imgInfo.DepartmentImage.name) ? URL.createObjectURL(imgInfo.DepartmentImage) : (IMG_URL + '/assets/images/UserImages/' + imgInfo.DepartmentImage)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-DepartmentImage" className='w-[10vw] h-[10vw] rounded' />
                                        <Input id={'DepartmentImage'} type={'file'} name={'DepartmentImage'} onChange={(e) => setImgInfo({ ...imgInfo, DepartmentImage: e.target.files[0] })} className={'p-[1px!important] mt-2'} />
                                        <Validation error={errors.DepartmentImage} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/gladiance-department-master'} state={location.state.currentPage} />
                                     <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}
export default GladianceDepartmentMasterAddEdit
