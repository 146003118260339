import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";

const MasterTableName = "GAAProjectSpaceMaster";
const FetchRequestType = "GAAProjectSpaceFetchRequest";

export const SaveGAAProjectSpace = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetGAAProjectSpaceList = async () => {
  let payload = { Refs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  //  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

export const GetGAAProjectSpace = async (Ref) => {
  let payload = { GAAProjectSpaceRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteGAAProjectSpace = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectSpaceListBySpaceGroup = async (spaceGroupRef) => {
  let payload = { GAAProjectSpaceGroupRefs: [spaceGroupRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

