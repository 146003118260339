import React, { Fragment, useEffect, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import ActionButton from '../../helpers/ActionButton'
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteProductOutputFunctionId, GetProductOutputFunctionIdList } from "../../../Encapsulation/GladianceDomainEntities/GAAProductOutputFunctionId";
import flasher from '@flasher/flasher'
import Swal from 'sweetalert2'
import Pagination from '../../helpers/Pagination'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function GAAProductOutputFunctionId() {
    document.title = 'Gladiance - Product Output Function'
    const tableHeading = ['Sr.No','Name','Value', 'Action']
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [ProductOutputFunctionIdList, setProductOutputFunctionIdList] = useState([])

    // Fetch All Data from database //
    const fetchProductOutputFunctionList = async () => {
        setProductOutputFunctionIdList(await GetProductOutputFunctionIdList());
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const deleteProductOutputFunctionId = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteProductOutputFunctionId(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchProductOutputFunctionList();
                }
            }
        })
    }

    useEffect(() => {
        fetchProductOutputFunctionList();        // Fetch All Data from database //
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Product Output Function'} button={
                    <LinkButton to={'/product-output-function-id-master/add-product-output-function-id-master'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Product Output Function</span>
                    </LinkButton>
                }>
                    {ProductOutputFunctionIdList && ProductOutputFunctionIdList.length > 0 ? (
                        <div className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {ProductOutputFunctionIdList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3">{tableData.Value}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link to={`/product-output-function-id-master/edit-product-output-function-id-master/${tableData.Ref}`} title='Edit' >
                                                            <ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                                <PencilSquareIcon className='w-4 h-4' />
                                                            </ActionButton>
                                                        </Link>
                                                        <ActionButton onClick={() => deleteProductOutputFunctionId(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                            <TrashIcon className='w-4 h-4' />
                                                        </ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>
                    ) : (
                        <DataNotFound labelText={'Sorry! Product Output Function Data Not Found'} />
                    )}
                     {ProductOutputFunctionIdList && ProductOutputFunctionIdList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={ProductOutputFunctionIdList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                </AddComponent>
            )}
        </Fragment>
    )
}
export default GAAProductOutputFunctionId