import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";

const MasterTableName = "GAAProjectJobTitleMaster";
const FetchRequestType = "GAAProjectJobTitleFetchRequest";

export const SaveGAAProjectJobTitle = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetGAAProjectJobTitleList = async () => {
  let payload = { GAAProjectJobTitleRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

export const GetGAAProjectJobTitle = async (Ref) => {
  let payload = { GAAProjectJobTitleRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteGAAProjectJobTitle = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectJobTitleListByDepartment = async (departmentRef) => {
  let payload = { GAAProjectDepartmentRefs: [departmentRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

