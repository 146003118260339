import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "CustomerMaster";
const FetchRequestType = "CustomerFetchRequest";

export const GetGladianceCustomer = async (Ref) => {
  let payload = { CustomerRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGladianceCustomer = async (
  ref,
  payload,
  images
) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

export const DeleteGladianceCustomer = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGladianceCustomerList = async () => {
  let payload = { CustomerRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
 // result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};
