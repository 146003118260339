import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import {PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteGladianceUserRole, GetGladianceUserRoleList } from '../../../Encapsulation/GladianceDomainEntities/GladianceUserRole'
import flasher from '@flasher/flasher'
import Swal from 'sweetalert2'

function GladianceUserRoleMaster() {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    document.title = 'Gladiance - User Roles'
    const tableHeading = ['Sr.No', 'Name', 'Action']
    const [loader, setLoader] = useState(false)

    const [viewModel, setViewModel] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [errors, setErrors] = useState([])
    
    const [gladianceUserRoleList, setGladianceUserRoleList] = useState([]);
    const fetchGladianceUserRoleList = async () => {
        setGladianceUserRoleList(await GetGladianceUserRoleList(setErrors));
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleGladianceUserRoleMasterCsv = () => {
        // setLoader(true)
        // const formData = new FormData()
        // formData.append('GladianceDepartmentMasterCsv', GladianceDepartmentMasterCsv)
        // axiosClient.post(`import_material`, formData)
        //     .then(({ data }) => {
        //         flasher.success(data.msg)
        //         setLoader(false)
        //     })
        //     .catch(({ response }) => {
        //         setLoader(false)
        //     })
        // setViewModel(false)
        // getGladianceUserRoleList()
    }

    const deleteGladianceUserRole = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteGladianceUserRole(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchGladianceUserRoleList();
                }
            }
        })
    }

    useEffect(() => {
        fetchGladianceUserRoleList();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'User Roles'} button={
                    <LinkButton to={'/gladiance-userrole-master/add-userrole-master'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add User Role</span>
                    </LinkButton>
                }>
                    {gladianceUserRoleList && gladianceUserRoleList.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {gladianceUserRoleList && gladianceUserRoleList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link to={`/gladiance-userrole-master/edit-userrole-master/${tableData.Ref}`} title='Edit' ><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                            <PencilSquareIcon className='w-4 h-4' />
                                                        </ActionButton></Link>
                                                        <ActionButton onClick={() => deleteGladianceUserRole(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                            <TrashIcon className='w-4 h-4' />
                                                        </ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! User Role Data Not Found'} />
                    )}
                    {gladianceUserRoleList && gladianceUserRoleList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={gladianceUserRoleList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                </AddComponent>
            )}
        </Fragment>
    )
}
export default GladianceUserRoleMaster
