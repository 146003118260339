import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProjectRBItemApplicableServingSlots";
const FetchRequestType = "GAAProjectRBItemApplicableServingSlotFetchRequest";

// Save All Data To Database //
export const SaveRBItemApplicableServingSlot = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};
// Get All Data From Database //
export const GetRBItemApplicableServingSlotList = async () => {
  let payload = {GAAProjectRBItemServingSlotRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

// Get Single Data From Database //
export const GetRBItemApplicableServingSlot = async (GAAProjectRBItemRef,GAAProjectRBItemServingSlotRef) => {
  let payload = {GAAProjectRBItemRefs: [GAAProjectRBItemRef] ,
    GAAProjectRBItemServingSlotRefs: [GAAProjectRBItemServingSlotRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteRBItemApplicableServingSlot = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetRBItemApplicableServingSlotByRBItem = async (GAAProjectRBItemRef) => {
  let payload = { GAAProjectRBItemRefs: [GAAProjectRBItemRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const GetRBItemApplicableServingSlotByProject = async (projectRef) => {
  let payload = { GAAProjectRefs: [projectRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

