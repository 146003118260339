import React, { Fragment, useEffect, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Encapsulation/Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteGladianceSolution, GetGladianceSolutionBouquetList } from '../../../Encapsulation/GladianceDomainEntities/GladianceSolutionBouquet'
import Pagination from '../../helpers/Pagination'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function GladianceSolutionMaster() {
    document.title = 'Gladiance - Solutions'
    const tableHeading = ['Sr.No', 'Solution Name', 'Action']
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [gladianceSolutionMasterList, setGladianceSolutionMasterList] = useState([])
    const [errors, setErrors] = useState([]);
    console.log('errors :', errors);

    const fetchSolutionList = async () => {
        setGladianceSolutionMasterList(await GetGladianceSolutionBouquetList(setErrors));
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const deleteSolution = (tableData) => {
        Swal.fire({
          title: 'Are you sure to delete this?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let response = await DeleteGladianceSolution(tableData)
            if (response.Successful == false) {
              flasher.warning(response.Message)
            } else {
              flasher.success('Deleted Successfully')
              fetchSolutionList();
            }
          }
        })
      }

    useEffect(() => {
        fetchSolutionList();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Solutions'} button={
                    <LinkButton to={'/gladiance-solution-master/add-gladiance-solution-master'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Solution</span>
                    </LinkButton>
                }>
                    {gladianceSolutionMasterList && gladianceSolutionMasterList.length > 0 ? (
                        <div className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {gladianceSolutionMasterList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link to={`/gladiance-solution-master/edit-gladiance-solution-master/${tableData.Ref}`} title='Edit' >
                                                            <ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                                <PencilSquareIcon className='w-4 h-4' />
                                                            </ActionButton>
                                                        </Link>
                                                        <ActionButton onClick={() => deleteSolution(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                            <TrashIcon className='w-4 h-4' />
                                                        </ActionButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>
                    ) : (
                        <DataNotFound labelText={'Sorry! Solutions Data Not Found'} />
                    )}
                     {gladianceSolutionMasterList && gladianceSolutionMasterList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={gladianceSolutionMasterList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }
                </AddComponent>
            )}
        </Fragment>
    )
}
export default GladianceSolutionMaster