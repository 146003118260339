import React, { Fragment, useEffect, useRef, useState } from 'react'
import { LightBulbIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { useReactToPrint } from 'react-to-print'
import { AddCircle } from 'iconsax-react'
import Loader from '../../Common/Loader'
import AddComponent from '../../../components/AddComponent'
import Table from '../../helpers/Table'
import LinkButton from '../../helpers/LinkButton'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination'
import DataNotFound from '../../helpers/DataNotFound'
import { DeleteGAAProjectScene, GetGAAProjectRoomSceneListByProject, GetGAAProjectRoomSceneListBySpaceType } from '../../../Encapsulation/HotelDomainEntities/HotelRoomScene'
import Label from '../../helpers/Label'
import CustomSelect from '../../helpers/CustomSelect'
import { GetGAAProjectList } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import Option from '../../helpers/Option'
import { GetGAAProjectSpaceTypeListByProject } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType'
import Input from '../../helpers/Input'

function RoomScene({ Rights }) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    document.title = 'Gladiance - Room Scene'
    const tableHeading = ['Sr.No', 'Name', 'Code', 'Action']
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [ProjectList, setProjectList] = useState([]);
    const [ProjectRef, setProjectRef] = useState();
    const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState([]);
    const [GAAProjectSpaceTypeRef, setGAAProjectSpaceTypeRef] = useState();
    const [RoomSceneList, setRoomSceneList] = useState([])

    // fetch Room Scene List According to Project Ref //
    const fetchProjectRoomSceneListByProject = async () => {
        ProjectRef && setRoomSceneList(await GetGAAProjectRoomSceneListByProject(ProjectRef));
    };

    const fetchProjectRoomSceneListBySpaceType = async () => {
        GAAProjectSpaceTypeRef && setRoomSceneList(await GetGAAProjectRoomSceneListBySpaceType(GAAProjectSpaceTypeRef));
    };

    // Fetch Project List For Dropdown //
    const fetchProjectList = async () => {
        setProjectList(await GetGAAProjectList());
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleRoomSceneCsv = () => {
        // setLoader(true)
        // const formData = new FormData()
        // formData.append('GladianceDepartmentMasterCsv', GladianceDepartmentMasterCsv)
        // axiosClient.post(`import_material`, formData)
        //     .then(({ data }) => {
        //         flasher.success(data.msg)
        //         setLoader(false)
        //     })
        //     .catch(({ response }) => {
        //         setLoader(false)
        //     })
        // setViewModel(false)
        // fetchRoomSceneList()
    }
    const deleteRoomScene = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteGAAProjectScene(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchProjectRoomSceneListByProject();
                    fetchProjectRoomSceneListBySpaceType();
                }
            }
        })
    }

    // Fetch Project Space Type List For Dropdown By Project //
    const fetchSpaceTypeListByProject = async () => {
        ProjectRef &&
            setGAAProjectSpaceTypeList(
                await GetGAAProjectSpaceTypeListByProject(ProjectRef)
            );
    };

    useEffect(() => {
        fetchProjectRoomSceneListByProject();
    }, [ProjectRef])

    useEffect(() => {
        fetchProjectRoomSceneListBySpaceType();
    }, [GAAProjectSpaceTypeRef])

    useEffect(() => {
        fetchProjectList();
    }, []);

    useEffect(() => {
        fetchSpaceTypeListByProject();
    }, [ProjectRef]);

    useEffect(() => {
        if (ProjectList.length > 0) {
            setProjectRef(ProjectList[0].Ref);
        }
    }, [ProjectList]);

    useEffect(() => {
        if (GAAProjectSpaceTypeList.length > 0) {
            setGAAProjectSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
        }
    }, [GAAProjectSpaceTypeList]);

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Room Scenes'} button={
                    <LinkButton to={'/room-scene/add-room-scene'} className={Rights.Add ? "opacity-100" : "hidden"} state={{ProjectRef:ProjectRef , GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef}}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Scene</span>
                    </LinkButton>
                }>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                        <div>
                            <Label
                                htmlFor={"projectRef"}
                                labelText={" Project"}
                            />
                            <CustomSelect
                                id={"projectRef"}
                                name={"projectRef"}
                                value={ProjectRef}
                                onChange={(e) => setProjectRef(e.target.value)}
                                required={true}
                            >
                                {ProjectList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                        <div>
                            <Label
                                htmlFor={"GAAProjectSpaceTypeRef"}
                                labelText={" Space Type"}
                            />
                            {GAAProjectSpaceTypeList.length > 0 ? (
                                <div>
                                    <CustomSelect
                                        id={"GAAProjectSpaceTypeRef"}
                                        name={"GAAProjectSpaceTypeRef"}
                                        value={GAAProjectSpaceTypeRef}
                                        onChange={(e) => setGAAProjectSpaceTypeRef(e.target.value)}
                                        required={true}
                                    >
                                        {GAAProjectSpaceTypeList &&
                                            GAAProjectSpaceTypeList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option value={data.Ref}>{data.Name}</Option>
                                                    </Fragment>
                                                );
                                            })}
                                    </CustomSelect>
                                </div>
                            ) : (
                                <div>
                                    <Input
                                        placeHolder={"  No Space Types"}
                                        disabled={true}
                                    ></Input>
                                </div>
                            )}
                        </div>
                    </div>
                    {RoomSceneList && RoomSceneList.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading} >
                                {RoomSceneList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3">{tableData.Code}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link
                                                            to={`/room-scene/edit-room-scene/${tableData.Ref}`}
                                                            title="Edit"
                                                        >
                                                            <ActionButton
                                                                className={
                                                                    "p-1.5 text-white bg-yellow-400 "
                                                                }
                                                            >
                                                                <PencilSquareIcon className="w-4 h-4" />
                                                            </ActionButton>
                                                        </Link>
                                                        {
                                                            Rights.Delete && (
                                                                <ActionButton onClick={() => deleteRoomScene(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                                    <TrashIcon className='w-4 h-4' />
                                                                </ActionButton>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! Room Scene Data Not Found'} />
                    )}
                    {RoomSceneList && RoomSceneList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={RoomSceneList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}

export default RoomScene


