import React, { Fragment } from 'react'

function TextArea({id,name,value,rows,className,onChange,readOnly,disabled}) {
    return (
        <Fragment>
            <textarea id={id} name={name} defaultValue={value} rows={rows} className={"block w-full bg-transparent rounded-md border-0 py-2 text-default-color shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#f94327] sm:text-sm sm:leading-6 "+(className)} onChange={onChange} readOnly={readOnly} disabled={disabled}></textarea>
        </Fragment>
    )
}

export default TextArea