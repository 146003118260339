import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";

const MasterTableName = "GAAProjectSpaceTypePlannedDeviceConnections";
const FetchRequestType = "GAAProjectSpaceTypePlannedDeviceConnectionFetchRequest";

export const SaveGAAProjectSpaceTypePlannedDeviceConnection = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetGAAProjectSpaceTypePlannedDeviceConnectionList = async () => {
  let payload = { Refs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const GetGAAProjectSpaceTypePlannedDeviceConnection = async (Ref) => {
  let payload = { GAAProjectSpaceTypePlannedDeviceConnectionRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteGAAProjectSpaceTypePlannedDeviceConnection = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectSpaceTypePlannedDeviceConnectionByPlannedDevice = async (
  Ref
) => {
  let payload = { GAAProjectSpaceTypePlannedDeviceRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const GetGAAProjectSpaceTypePlannedDeviceConnectionBySpaceType = async (
  spaceTypeRef, bindToPlannedDeviceRef
) => {
  let payload = { GAAProjectSpaceTypeRefs: [spaceTypeRef], 
    BindToGAAProjectSpaceTypePlannedDeviceRefs: [bindToPlannedDeviceRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

