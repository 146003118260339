import React, { Fragment, useState } from 'react'
import { useStateContext } from '../../context/ContextProvider'
import Label from '../helpers/Label'
import Input from '../helpers/Input'
import Validation from '../helpers/Validation'
import Button from '../helpers/Button'
import axiosClient from '../../Encapsulation/Axios'
import flasher from "@flasher/flasher";
import { Link } from 'react-router-dom'
import Loader from '../Common/Loader'
import { DirectInbox } from 'iconsax-react'

function ForgetPassword() {
    document.title = 'Gladiance - Forget Password'
    const [formInfo, setFormInfo] = useState({email: ''});
    const [errors, setErrors] = useState([]);
    const { FRONTEND_URL } = useStateContext()
    const [loader, setLoader] = useState(false)
    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        const formData = new FormData();
        formData.append('email', formInfo.email);
        axiosClient.post('/forget_password', formData)
        .then(({ data }) => {
            setLoader(false)
            flasher.success(data.msg)
        })
        .catch(({ response }) => {
            setLoader(false)
            setErrors(response.data.errors)
        })
    }
    return (
        <Fragment>
            {loader ? (<Loader/>) : (
                <div className="flex w-full md:w-[35%] items-center px-4 py-12 sm:px-6 lg:px-8 absolute">
                    <div className="w-full space-y-2 pt-4 pb-6 px-6 rounded-lg">
                        <div>
                            <img className="mx-auto w-auto" src={FRONTEND_URL + "/assets/logos/logo1.png"} alt="Your Company" />
                            <h2 className="text-center text-2xl text-white uppercase font-extrabold tracking-widest mt-4">Forget Password</h2>
                            <p className="mt-2 text-center text-white">
                                Or{' '}
                                <Link to="/login" className="font-medium hover:text-indigo-500">
                                    Go To Login
                                </Link>
                            </p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="rounded-md shadow-sm">
                                <div className='mb-6'>
                                    <Label labelText={'Email Addess'} htmlFor='email' className={'text-white font-medium'} />
                                    <Input type={'email'} value={formInfo.email} name={'email'} id={'email'} onChange={handleChange} />
                                    <Validation error={errors.email} />
                                </div>
                            </div>
                            <Button className={'w-full'}>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <DirectInbox className="h-5 w-5" aria-hidden="true" />
                                </span>
                                Send Email Password Reset Link
                            </Button>
                        </form>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default ForgetPassword