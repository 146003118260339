import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../../helpers/LinkButton'
import Table from '../../../helpers/Table'
import Loader from '../../../Common/Loader'
import axiosClient from '../../../../Encapsulation/Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../../helpers/ActionButton'
import Pagination from '../../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../../helpers/DataNotFound'
import { useStateContext } from '../../../../context/ContextProvider'
import { GetGAAProjectList, GetGAAProjectListByCustomer } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import Label from '../../../helpers/Label'
import Option from '../../../helpers/Option'
import CustomSelect from '../../../helpers/CustomSelect'
import { GetGAAProjectDepartmentListByProject } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectDepartment'
import { GetGladianceCustomerList } from '../../../../Encapsulation/GladianceDomainEntities/Customer'
import { DeleteGAAProjectUser, GetGAAProjectUserListByDepartment } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectUser'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function GAAProjectUser({ Rights }) {
  document.title = 'Hotel - User'
  const tableHeading = ['Sr.No', 'User Name', 'Job Title', 'User Role', 'Password', 'First Name', 'Middle Name', 'Last Name', 'Phone No.', 'Email Id', 'Action']
  const [loader, setLoader] = useState(false)
  const [customerList, setCustomerList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [GAAProjectDepartmentList, setGAAProjectDepartmentList] = useState([]);
  const [GAAProjectUserList, setGAAProjectUserList] = useState([]);
  const { CustomerRef,  GAAProjectRef,  GAADepartmentRef, handleCustomerRef,handleProjectRef,handleDepartmentRef,} = useStateContext();

  //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  }

  //Fetch Project List for Dropdowm By Customer//
  const fetchGAAProjectListByCustomer = async () => {
    CustomerRef && setGAAProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };

  const fetchGAADepartmentListByProject = async () => {
    GAAProjectRef && setGAAProjectDepartmentList(await GetGAAProjectDepartmentListByProject(GAAProjectRef));
  }

  const fetchProjectUserListByDepartment = async () => {
    GAADepartmentRef && setGAAProjectUserList(await GetGAAProjectUserListByDepartment(GAADepartmentRef));
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const deleteProjectUser = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectUser(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchProjectUserListByDepartment();
        }
      }
    })
  }

  useEffect(() => {
    if (CustomerRef == '' && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
    fetchGAAProjectListByCustomer();
  }, [CustomerRef]);

  //  Set Zero Index Project Name For Dropdown //
  useEffect(() => {
    if (GAAProjectRef == '' && GAAProjectList.length > 0) {
      handleProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchGAADepartmentListByProject();
  }, [GAAProjectRef]);

  // Set Zero Index Project Name For Dropdown //
  useEffect(() => {
    if (GAADepartmentRef == '' && GAAProjectDepartmentList.length > 0) {
      handleDepartmentRef(GAAProjectDepartmentList[0].Ref);
    }
  }, [GAAProjectDepartmentList]);

  useEffect(() => {
    fetchProjectUserListByDepartment();
  }, [GAADepartmentRef])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <AddComponent title={'Users'} button={
          <LinkButton to={Rights.Add && '/project-user-master/add-project-user-master'} className={Rights.Add ? "opacity-100" : "hidden"} state={{ GAADepartmentRef: GAADepartmentRef, GAAProjectRef: GAAProjectRef }}>
            <AddCircle variant='Bold' className='w-5 h-5' />
            <span>Add User</span>
          </LinkButton>
        }>
          <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mb-4'>
            <div>
              <Label htmlFor={'CustomerRef'} labelText={'Customer'} />
              <CustomSelect id={'CustomerRef'} name={'CustomerRef'} value={CustomerRef} onChange={(e)=> handleCustomerRef(e.target.value)} >
                <Option value={''}>-- Select --</Option>
                {
                  customerList && customerList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    )
                  })
                }
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={'GAAProjectRef'} labelText={'Project'} />
              <CustomSelect id={'GAAProjectRef'} name={'GAAProjectRef'} value={GAAProjectRef} onChange={(e)=> handleProjectRef(e.target.value)}>
                <Option value={''}>-- Select --</Option>
                {
                  GAAProjectList && GAAProjectList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    )
                  })
                }
              </CustomSelect>
            </div>
            <div>
              <Label htmlFor={'GAADepartmentRef'} labelText={'Department'} />
              <CustomSelect id={'GAADepartmentRef'} name={'GAADepartmentRef'} value={GAADepartmentRef} onChange={(e) => handleDepartmentRef(e.target.value)}>
                <Option value={''}>-- Select --</Option>
                {
                  GAAProjectDepartmentList && GAAProjectDepartmentList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    )
                  })
                }
              </CustomSelect>
            </div>
          </div> 
          {GAAProjectUserList && GAAProjectUserList.length > 0 ? (
            <div className='m-2'>
              <Table tableHeading={tableHeading}>
                {GAAProjectUserList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                  return (
                    <Fragment key={tableDataID}>
                      <tr className="border-b border-opacity-30 border-white">
                        <td className="px-4 py-3">{tableDataID + 1}</td>
                        <td className="px-4 py-3">{tableData.Name}</td>
                        <td className="px-4 py-3">{tableData.GAAProjectJobTitleName}</td>
                        <td className="px-4 py-3">{tableData.GAAProjectUserRoleName}</td>
                        <td className="px-4 py-3">{tableData.Password}</td>
                        <td className="px-4 py-3">{tableData.FirstName}</td>
                        <td className="px-4 py-3">{tableData.MiddleName}</td>
                        <td className="px-4 py-3">{tableData.LastName}</td>
                        <td className="px-4 py-3">{tableData.PhoneNo}</td>
                        <td className="px-4 py-3">{tableData.EMailId}</td>
                        <td className="px-4 py-3">
                          <div className='flex justify-center gap-3'>
                            <Link to={`/project-user-master/edit-project-user-master/${tableData.Ref}`} state={{ GAAProjectRef: GAAProjectRef }} title='Edit' >
                              <ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                <PencilSquareIcon className='w-4 h-4' />
                              </ActionButton>
                            </Link>
                            {Rights.Delete && (
                            <ActionButton onClick={() => deleteProjectUser(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                              <TrashIcon className='w-4 h-4' />
                            </ActionButton>
                            )}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  )
                })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={'Sorry! User Data Not Found'} />
          )}
          {GAAProjectUserList && GAAProjectUserList.length > 7 ? (<div className="flex justify-end">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={GAAProjectUserList.length}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>) : (<div></div>)
          }
        </AddComponent>
      )}
    </Fragment>
  )
}
export default GAAProjectUser