
import { Fragment } from "react";
import { NavLink } from 'react-router-dom';
import { useStateContext } from "../context/ContextProvider";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Nav = ({ to, iconPath, label,className ,name, id}) => {
  const {
    theme,
    FRONTEND_URL,
} = useStateContext();
  return (
    <div>
      <NavLink
        to={to}
        name={name} 
        id={id} 
        className={({ isActive }) =>
        classNames(
            isActive &&
            " text-orange-400 ring-1 ring-inset ring-orange-400 ",
            "flex items-center w-full p-2 transition duration-75 rounded-md group gap-2 " + (className)
        )
    }
      >
       <Fragment>
          {theme ? (
            <img src={FRONTEND_URL + `/assets/icons/${iconPath}white.png`} alt="user-img" className="w-[6%]" />
          ) : (
            <img src={FRONTEND_URL + `/assets/icons/${iconPath}dark.png`} alt="user-img" className="w-[6%]" />
          )}
          <span>{label}</span>
          </Fragment>
      </NavLink>
    </div>
  );
};

export default Nav;
