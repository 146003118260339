import React, { Fragment } from 'react'
import { useStateContext } from '../../context/ContextProvider'
import { DirectInbox } from 'iconsax-react'

function SaveButton({ className, title, onClick, text }) {
    const { theme } = useStateContext()
    return (
        <Fragment>
            <button type="submit" id='save' name='save' className={(theme ? 'text-gray-800' : 'text-default-color') + "group relative flex justify-center rounded-md bg-gradient-to-r w-[12vw] from-[#ff7d14] to-[#f94327] hover:bg-gradient-to-bl p-2 tracking-wider font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 " + (className)} title={title} onClick={onClick}>
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"><DirectInbox variant='Bulk' className="h-5 w-5" /></span>
                {text}
            </button>
        </Fragment>
    )
}

export default SaveButton