import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";


const MasterTableName = "GAAProjectUserMaster";
const FetchRequestType = "GAAProjectUserFetchRequest";

export const GetGAAProjectUser = async (Ref) => {
  let payload = { GAAProjectUserRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGAAProjectUser = async (ref, payload, images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

export const GetGAAProjectUserList = async () => {
  let payload = { GAAProjectUserRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const DeleteGAAProjectUser = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectUserListByDepartment = async (GAAProjectDepartmentRef) => {
    let payload = { GAAProjectDepartmentRefs: [GAAProjectDepartmentRef] };
    let td = await FetchFromServer(payload, FetchRequestType, []);
    let result = GetMasterTableEntries(td, MasterTableName);
    return result;
  };


