import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../../components/BackComponent'
import Label from '../../../helpers/Label'
import Card from '../../../helpers/Card'
import Input from '../../../helpers/Input'
import LinkButton from '../../../helpers/LinkButton'
import Loader from '../../../Common/Loader'
import Validation from '../../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../../helpers/CancelButton'
import SaveButton from '../../../helpers/SaveButton'
import { ClearInfo } from '../../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import { GetGAAProject } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import { GetGAAProjectUserRole, SaveGAAProjectUserRole } from '../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectUserRole '

function GAAProjectUserRoleAddEdit({Rights}) {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Hotel - Edit User Role";
      formMode = "Edit";
    } else {
      document.title = "Hotel - Add User Role";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation()
  const [formInfo, setFormInfo] = useState({});

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

// Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGAAProjectUserRole(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/project-user-role-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProject();                   // Save ProjectRef 
      }
      console.log("result :", result);
    }
  };

    // Fetch project Details Using Location State //
  const fetchProject = async () => {
    let Project = await GetGAAProject(Location.state);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name
    }))
  };


  const fetchUserRole = async () => {
    let data = await GetGAAProjectUserRole(id)
    setFormInfo(data);
}

     // Fetch project Details Using Location State //
  useEffect(() => {
    if (Location.state) {
      fetchProject();
    }
    if(id)
    {         
      fetchUserRole();
    }
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit User Role" : "Add User Role"}
          button={
            <LinkButton to={"/project-user-role-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"GAAProjectName"} labelText={"Project Name"} />
                    {formInfo.GAAProjectRef && (<Input
                      id={"GAAProjectName"}
                      type={"text"}
                      name={"GAAProjectName"}
                      value={formInfo.GAAProjectName}
                      required={true}
                      readOnly={true}
                    />
                    )}
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      inputFocus={true}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Name} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/project-user-role-master"} />
                  {
                    Rights.Edit && <SaveButton text="Save" />
                  }
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectUserRoleAddEdit;
