import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";

const MasterTableName = "GAAProjectUserRoleMaster";
const FetchRequestType = "GAAProjectUserRoleFetchRequest";

export const SaveGAAProjectUserRole = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetGAAProjectUserRoleList = async () => {
  let payload = { Refs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  //  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

export const GetGAAProjectUserRole = async (Ref) => {
  let payload = { GAAProjectUserRoleRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteGAAProjectUserRole = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectUserRoleListByProject = async (projectRef) => {
  let payload = { GAAProjectRefs: [projectRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

