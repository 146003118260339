import React, { Fragment } from 'react'

function Card({ children, className }) {
    return (
        <Fragment>
            <div className={"block mb-2 rounded-lg  text-white " + (className)}>
                {children}
            </div>
        </Fragment>
    )
}

export default Card