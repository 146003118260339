import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { GetGladianceInventoryList, SaveGladianceInventory } from '../../../Encapsulation/GladianceDomainEntities/GladianceCommercialInventory'
import { GetGladianceCountryList } from '../../../Encapsulation/GladianceDomainEntities/GladianceCountryMaster'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import { GetGAAProductModesMasterList } from '../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster'
import Option from '../../helpers/Option'


function CommercialInventoryAddEdit() {
    let formMode = 'New';
    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit Commercial Inventory'
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add Commercial Inventory'
        }
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const [gladianceCountryList, setGladianceCountryList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [formInfo, setFormInfo] = useState({})

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveGladianceInventory(id, formInfo, []);

        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/commercial-inventory');
            }
            else {
                setFormInfo(ClearInfo(formInfo))
            }
        }
    };

    const fetchInventory = async () => {
        let Inventory = await GetGladianceInventoryList(id);
        setFormInfo(Inventory);
    }

    const fetchGladianceCountryList = async () => {
        let countryList = await GetGladianceCountryList();
        setGladianceCountryList(countryList);
    }

    const fetchProductList = async () => {
        setProductList(await GetGAAProductModesMasterList());
    };


    // const formatDate = (date) => {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const hours = String(date.getHours()).padStart(2, '0');
    //     const minutes = String(date.getMinutes()).padStart(2, '0');
    //     const seconds = String(date.getSeconds()).padStart(2, '0');
    //     const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    //     return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    // };

    useEffect(() => {
        if (id) {
            fetchInventory();
        }
        fetchGladianceCountryList()
        fetchProductList()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Commercial Inventory' : 'Add Commercial Inventory'} button={
                    <LinkButton to={'/commercial-inventory'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={handleSubmit}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'GAAProductRef'} labelText={'Product'} />
                                        <CustomSelect inputFocus={true} id={'GAAProductRef'} name={'GAAProductRef'} value={formInfo.GAAProductRef} onChange={handleChange}>
                                            <Option value={''}>--- Select Product ---</Option>
                                            {productList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option
                                                           
                                                            value={data.Ref}
                                                        >
                                                            {data.Name}
                                                        </Option>
                                                    </Fragment>
                                                );    
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.GAAProductRef} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'Name'} />
                                        <Input id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Business'} labelText={'Business'} />
                                        <Input id={'Business'} type={'text'} name={'Business'} value={formInfo.Business} onChange={handleChange} />
                                        <Validation error={errors.Business} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'CountryRef'} labelText={'Country'} />
                                        <CustomSelect id={'CountryRef'} name={'CountryRef'} value={formInfo.CountryRef} onChange={handleChange}>
                                            <Option value={''}>--- Select Country ---</Option>
                                            {gladianceCountryList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option
                                                           
                                                            value={data.Ref}
                                                        >
                                                            {data.Name}
                                                        </Option>
                                                    </Fragment>
                                                );
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.CountryRef} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'FromDateTime'} labelText={'From Date Time'} />
                                        <Input id={'FromDateTime'} type={'datetime-local'} name={'FromDateTime'} value={formInfo.FromDateTime} onChange={handleChange} />
                                        <Validation error={errors.FromDateTime} />
                                    </div>
                                    {/* <div>
                                    <Label htmlFor={'FromDateTime'} labelText={'From Date Time'} />
                                        <Input
                                            id="FromDateTime"
                                            type="datetime-local"
                                            name="FromDateTime"
                                            value={formInfo.FromDateTime ? formatDate(new Date(formInfo.FromDateTime)) : ''}
                                            onChange={handleChange}
                                        />
                                        <div>{formInfo.FromDateTime ? formatDate(new Date(formInfo.FromDateTime)) : ''}</div>
                                        <Validation error={errors.FromDateTime} />
                                    </div> */}
                                    <div>
                                        <Label htmlFor={'ToDateTime'} labelText={'To Date Time'} />
                                        <Input id={'ToDateTime'} type={'datetime-local'} name={'ToDateTime'} value={formInfo.ToDateTime} onChange={handleChange} />
                                        <Validation error={errors.ToDateTime} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Price'} labelText={'Price'} />
                                        <Input id={'Price'} type={'text'} name={'Price'} value={formInfo.Price} onChange={handleChange} />
                                        <Validation error={errors.Price} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'DealerPrice'} labelText={'Dealer Price'} />
                                        <Input id={'DealerPrice'} type={'text'} name={'DealerPrice'} value={formInfo.DealerPrice} onChange={handleChange} />
                                        <Validation error={errors.DealerPrice} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'DiscountPercentage'} labelText={'Discount %'} />
                                        <Input id={'DiscountPercentage'} type={'text'} name={'DiscountPercentage'} value={formInfo.DiscountPercentage} onChange={handleChange} />
                                        <Validation error={errors.DiscountPercentage} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'FestivalDiscountPercentage'} labelText={'Festival Discount %'} />
                                        <Input id={'FestivalDiscountPercentage'} type={'text'} name={'FestivalDiscountPercentage'} value={formInfo.FestivalDiscountPercentage} onChange={handleChange} />
                                        <Validation error={errors.FestivalDiscountPercentage} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'TransportationCharges'} labelText={'Transportation Charges '} />
                                        <Input id={'TransportationCharges'} type={'text'} name={'TransportationCharges'} value={formInfo.TransportationCharges} onChange={handleChange} />
                                        <Validation error={errors.TransportationCharges} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/commercial-inventory'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}


export default CommercialInventoryAddEdit
