import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import { GetGAAProductOutputDriverChannelDeviceType, SaveGAAProductOutputDriverChannelDeviceType } from '../../../Encapsulation/GladianceDomainEntities/GAAProductOutputDriverChannelDeviceType'

function GAAProductOutputDriverChannelsAddEdit() {
    let formMode = 'New';

    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit Product Output Channel Device Type';
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add Product Output Channel Device Type';
        }
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const [formInfo, setFormInfo] = useState({})

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveGAAProductOutputDriverChannelDeviceType(id, formInfo);
        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/product-input-driver-channel-device-type-master');
            }
            else {
                setFormInfo(ClearInfo(formInfo))
            }
        }
    };

    const ProductOutputDriverChannelDeviceType = async () => {
        setFormInfo(await GetGAAProductOutputDriverChannelDeviceType(id));
    };

    useEffect(() => {
        if (id) {
            ProductOutputDriverChannelDeviceType();
        }
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Product Output Channel Device Type' : 'Add Product Output Channel Device Type'} button={
                    <LinkButton to={'/product-input-driver-channel-device-type-master'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'SingularName'} labelText={'Singular Name'} />
                                        <Input inputFocus={true} id={'SingularName'} type={'text'} name={'SingularName'} value={formInfo.SingularName} onChange={handleChange} required={true} />
                                        <Validation error={errors.SingularName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'PluralName'} labelText={'Plural Name'} />
                                        <Input id={'PluralName'} type={'text'} name={'PluralName'} value={formInfo.PluralName} onChange={handleChange} required={true} />
                                        <Validation error={errors.PluralName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'DisplayOrder'} labelText={'Display Order'} />
                                        <Input id={'DisplayOrder'} type={'number'} name={'DisplayOrder'} value={formInfo.DisplayOrder} min={1} onChange={handleChange} required={true} />
                                        <Validation error={errors.DisplayOrder} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/product-input-driver-channel-device-type-master'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}
export default GAAProductOutputDriverChannelsAddEdit
