import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProjectSceneMaster";
const FetchRequestType = "GAAProjectSceneFetchRequest";

// Save All Data To Database //
export const SaveGAAProjectScene = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};
// Get All Data From Database //
export const GetGAAProjectSceneList = async () => {
  let payload = {GAAProjectSceneRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

// Get Single Data From Database //
export const GetHotelRoomScene = async (Ref) => {
  let payload = {GAAProjectSceneRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteGAAProjectScene = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectRoomSceneListByProject = async (ProjectRef) => {
  let payload = { GAAProjectRefs: [ProjectRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const GetGAAProjectRoomSceneListBySpaceType= async (GAAProjectSpaceTypeRef) => {
  let payload = { GAAProjectSpaceTypeRefs: [GAAProjectSpaceTypeRef]};
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};
