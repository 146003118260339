import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { ArrowsUpDownIcon, DocumentArrowUpIcon, DocumentIcon, PencilSquareIcon, PlusCircleIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import axiosClient from '../../../Encapsulation/Axios'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import { GetHotelMiscellaneousList } from '../../../Encapsulation/HotelDomainEntities/HotelMiscellaneous'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MiscellaneousParameter() {
    document.title = 'Gladiance - Miscellaneous Parameter'
    const tableHeading = ['Sr.No', 'Room Type', 'Regular', 'Parameter', 'Value'];
    const [loader, setLoader] = useState(false)
    const [viewModel, setViewModel] = useState(false)
    const [miscellaneousCsv, setMiscellaneousCsv] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [miscellaneousList, setMiscellaneousList] = useState([])

    const fetchMiscellaneousList = async () => {
        setMiscellaneousList(await GetHotelMiscellaneousList());
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const headers = [
        { label: 'Sr.No', key: 'index' },
        { label: 'Material', key: 'material_name' },
        { label: 'Unit', key: 'material_unit' },
    ];

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleMiscellaneousCsvCsv = () => {
        // setLoader(true)
        // const formData = new FormData()
        // formData.append('miscellaneousCsv', miscellaneousCsv)
        // axiosClient.post(`import_material`, formData)
        //     .then(({ data }) => {
        //         flasher.success(data.msg)
        //         setLoader(false)
        //     })
        //     .catch(({ response }) => {
        //         setLoader(false)
        //     })
        // setViewModel(false)
        // fetchMiscellaneousList()
    }

    const deletemiscellaneous = (id) => {
        // Swal.fire({
        //     title: 'Are you sure to delete this?',
        //     text: "You won't be able to revert this!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes, delete it!'
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         axiosClient.delete(`/delete_material/${id}`)
        //             .then(({ data }) => {
        //                 flasher.success(data.msg)
        //                 fetchMiscellaneousList()
        //             })
        //     }
        // })
    }
    useEffect(() => {
        fetchMiscellaneousList()
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Miscellaneous Parameters'} button={
                    <LinkButton to={'/miscellaneous-parameter/add-miscellaneous-parameter'}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Miscellaneous</span>
                    </LinkButton>
                }>
                    {miscellaneousList && miscellaneousList.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading} >
                                {miscellaneousList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white text-center">
                                                <td className="px-4 py-3">{1}</td>
                                                <td className="px-4 py-3">{tableData}</td>
                                                <td className="px-4 py-3"> {tableData}</td>
                                                <td className="px-4 py-3 grid">{tableData}
                                                    <td className="px-4 py-3">{tableData}</td>
                                                </td>
                                                <td className="px-4 py-3 ">{tableData} </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                                {/* <tr className="border-b border-opacity-30 border-white text-center">
                                    <td className="px-4 py-3">{1}</td>
                                    <td className="px-4 py-3 ">{'Jacket'} </td>
                                    <td className="px-4 py-3 "> </td>
                                    <td className="px-4 py-3">{'300'}</td>
                                    <td className="px-4 py-3">{'200'}</td>
                                    <td className="px-4 py-3">
                                        <div className='flex justify-center gap-3'>
                                            <Link title='Edit'><ActionButton className={'p-1.5 text-white bg-yellow-400 '}>
                                                <PencilSquareIcon className='w-4 h-4' />
                                            </ActionButton></Link>
                                            <ActionButton onClick={() => deletemiscellaneous('tableData.id')} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                <TrashIcon className='w-4 h-4' />
                                            </ActionButton>
                                        </div>
                                    </td>
                                </tr> */}
                            </Table>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! Miscellaneous Parameter Data Not Found'} />
                    )}
                    {miscellaneousList && miscellaneousList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={miscellaneousList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}


export default MiscellaneousParameter
