import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import { GetGAAProject } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import { GetGAADoorCameraMaster, SaveGAADoorCameraMaster } from '../../../Encapsulation/HotelDomainEntities/HotelDoorCamera'
import { GetProjectSpaceGroup } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/ProjectSpaceGroup'
import { GetGAAProjectSpace } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpace'
import { GetGAAProjectSpaceTypeAreaListBySpaceType } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea'
import Option from '../../helpers/Option'
import CustomSelect from '../../helpers/CustomSelect'
import { GetProjectSpaceType } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType'

function DoorCameraAddEdit() {
  let formMode = "New";
  let { id } = useParams();
  {
    if (id) {
      document.title = "Hotel - Edit Door Camera";
      formMode = "Edit";
    } else {
      document.title = "Hotel - Add Door Camera";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation()
  const [GAASpaceTypeAreaList, setGAASpaceTypeAreaList] = useState([]);
  const [formInfo, setFormInfo] = useState({});
  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveGAADoorCameraMaster(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/door-camera");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProject();                   // Save ProjectRef 
      }
      console.log("result :", result);
    }
  };

  // Fetch project Details Using Location State //
  const fetchProject = async () => {
    let Project = await GetGAAProject(Location.state.GAAProjectRef);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name
    }))
    let spacegroup = await GetProjectSpaceGroup(Location.state.spaceGroupRef);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectSpaceGroupRef: spacegroup.Ref,
      GAAProjectSpaceGroupName: spacegroup.Name
    }))
    let Space = await GetGAAProjectSpace(Location.state.ProjectSpaceRef);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectSpaceRef: Space.Ref,
      GAAProjectSpaceName: Space.Name,
      GAAProjectSpaceTypeRef:Space.GAAProjectSpaceTypeRef
    }))
    let Areas = await GetProjectSpaceType(formInfo.GAAProjectSpaceTypeRef);
    console.log('Areas :', Areas);
    setFormInfo(formInfo => ({
      ...formInfo,
      HasAreas: Areas.HasAreas,
    }))
  };


  // Fetch Project Space Type Area List For Dropdown By space type //
  const fetchAreaBySpaceType = async () => {
    formInfo.GAAProjectSpaceTypeRef && setGAASpaceTypeAreaList(await GetGAAProjectSpaceTypeAreaListBySpaceType(formInfo.GAAProjectSpaceTypeRef));
  };

  const fetchDoorCamera = async () => {
    let data = await GetGAADoorCameraMaster(id)
    setFormInfo(data);
  }

  // Fetch project Details Using Location State //
  useEffect(() => {
    if (Location.state) {
      fetchProject();
    }
    if (id) {
      fetchDoorCamera();
    }
  }, []);


  useEffect(() => {
    fetchAreaBySpaceType();
  }, [formInfo.GAAProjectSpaceTypeRef]);
  
  // useEffect(() => {
  //   if (GAASpaceTypeAreaList.length > 0) {
  //     setFormInfo((formInfo) => ({
  //       ...formInfo,
  //       GAAProjectSpaceTypeAreaRef: GAASpaceTypeAreaList[0].Ref,
  //     }));
  //   };
    
  // }, [GAASpaceTypeAreaList]);
  

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={id ? "Edit Door Camera" : "Add Door Camera"}
          button={
            <LinkButton to={"/door-camera"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"GAAProjectName"} labelText={"Project Name"} />
                    {formInfo.GAAProjectRef && (<Input
                      id={"GAAProjectName"}
                      type={"text"}
                      name={"GAAProjectName"}
                      value={formInfo.GAAProjectName}
                      required={true}
                      readOnly={true}
                    />
                    )}
                  </div>
                  <div>
                    <Label htmlFor={"GAAProjectSpaceGroupName"} labelText={"Space Group"} />
                    {formInfo.GAAProjectSpaceGroupRef && (<Input
                      id={"GAAProjectSpaceGroupName"}
                      type={"text"}
                      name={"GAAProjectSpaceGroupName"}
                      value={formInfo.GAAProjectSpaceGroupName}
                      required={true}
                      readOnly={true}
                    />
                    )}
                  </div>
                  <div>
                    <Label htmlFor={"GAAProjectSpaceName"} labelText={"Project Space"} />
                    {formInfo.GAAProjectSpaceRef && (<Input
                      id={"GAAProjectSpaceName"}
                      type={"text"}
                      name={"GAAProjectSpaceName"}
                      value={formInfo.GAAProjectSpaceName}
                      required={true}
                      readOnly={true}
                    />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypeAreaRef"}
                      labelText={" Space Type Area"}
                    />
                    <CustomSelect
                      id={"GAAProjectSpaceTypeAreaRef"}
                      name={"GAAProjectSpaceTypeAreaRef"}
                      value={formInfo.GAAProjectSpaceTypeAreaRef}
                      onChange={handleChange}
                      required={true}
                      inputFocus={true}
                    >
                      <Option value={""}>-- Select --</Option>
                      <Option value={0}>None</Option>
                      {GAASpaceTypeAreaList && GAASpaceTypeAreaList.map((data, id) => {
                        return (
                          <Fragment key={id}>
                            <Option
                              value={data.Ref}
                            >
                              {data.Name}
                            </Option>
                          </Fragment>
                        );
                      })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Camera Name"} />
                    <Input
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={"IPAddress"} labelText={"IP Address"} />
                    <Input
                      id={"IPAddress"}
                      type={"text"}
                      name={"IPAddress"}
                      value={formInfo.IPAddress}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.IPAddress} />
                  </div>
                  <div>
                    <Label htmlFor={"ONVIFPort"} labelText={"ONVIF Port"} />
                    <Input
                      id={"ONVIFPort"}
                      type={"text"}
                      name={"ONVIFPort"}
                      value={formInfo.ONVIFPort}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.ONVIFPort} />
                  </div>
                  <div>
                    <Label htmlFor={"CameraUserName"} labelText={"Camera UserName"} />
                    <Input
                      id={"CameraUserName"}
                      type={"text"}
                      name={"CameraUserName"}
                      value={formInfo.CameraUserName}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.CameraUserName} />
                  </div>
                  <div>
                    <Label htmlFor={"CameraPassword"} labelText={"Camera Password"} />
                    <Input
                      id={"CameraPassword"}
                      type={"text"}
                      name={"CameraPassword"}
                      value={formInfo.CameraPassword}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.CameraPassword} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/door-camera"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default DoorCameraAddEdit;
