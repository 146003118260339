import React, { Fragment, useEffect, useRef, useState } from 'react'
import { LightBulbIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { AddCircle } from 'iconsax-react'
import Loader from '../../Common/Loader'
import AddComponent from '../../../components/AddComponent'
import Table from '../../helpers/Table'
import LinkButton from '../../helpers/LinkButton'
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination'
import DataNotFound from '../../helpers/DataNotFound'
import Label from '../../helpers/Label'
import CustomSelect from '../../helpers/CustomSelect'
import { GetGAAProjectList, GetGAAProjectListByCustomer } from '../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject'
import Option from '../../helpers/Option'
import { DeleteHotelKeyContact, GetProjectKeyContactListByProject } from '../../../Encapsulation/HotelDomainEntities/HotelKeyContact'
import Swal from 'sweetalert2'
import flasher from '@flasher/flasher'
import { useStateContext } from '../../../context/ContextProvider'
import { GetGladianceCustomerList } from '../../../Encapsulation/GladianceDomainEntities/Customer'

function KeyContact({ Rights }) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    document.title = 'Gladiance - Key Contacts'
    const tableHeading = ['Sr.No', 'Name', 'Google Location', 'Telephone No', 'Action']
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [ProjectList, setProjectList] = useState([]);
 //   const [GAAProjectRef, setGAAProjectRef] = useState();
    const [KeyContactList, setKeyContactList] = useState([])
    const [customerList, setCustomerList] = useState([]);

    const { GAAProjectRef, handleProjectRef,CustomerRef, handleCustomerRef } =   useStateContext();

     //Fetch Customer List For Dropdown//
  const fetchCustomerList = async () => {
    setCustomerList(await GetGladianceCustomerList());
  };

  // Fetch Project List For Dropdown //
  const fetchProjectListByCustomer = async () => {
      CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
  };
    // fetch Feedback List According to Project Ref //
    const fetchProjectKeyContactByProject = async () => {
        GAAProjectRef && setKeyContactList(await GetProjectKeyContactListByProject(GAAProjectRef));
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const deleteKeyContact = (tableData) => {
        Swal.fire({
            title: 'Are you sure to delete this?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await DeleteHotelKeyContact(tableData)
                if (response.Successful == false) {
                    flasher.warning(response.Message)
                } else {
                    flasher.success('Deleted Successfully')
                    fetchProjectKeyContactByProject();
                }
            }
        })
    }
    useEffect(() => {
        fetchCustomerList();
      }, []);
    
      useEffect(() => {
        if (CustomerRef == "" && customerList.length > 0) {
          handleCustomerRef(customerList[0].Ref);
        }
      }, [customerList]);

      useEffect(() => {
          fetchProjectListByCustomer();
        }, [CustomerRef]);

    useEffect(() => {
        if (GAAProjectRef == '' && ProjectList.length > 0) {
            handleProjectRef(ProjectList[0].Ref);
        }
    }, [ProjectList]);

    useEffect(() => {
        fetchProjectKeyContactByProject();
    }, [GAAProjectRef])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Key Contact'} button={
                    <LinkButton to={'/key-contact/add-key-contact'} className={Rights.Add ? 'opacity-100' : 'hidden'} state={GAAProjectRef}>
                        <AddCircle variant='Bold' className='w-5 h-5' />
                        <span>Add Key Contact</span>
                    </LinkButton>
                }>
                     <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                    <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
                        <div>
                            <Label
                                htmlFor={"GAAprojectRef"}
                                labelText={"Project"}
                            />
                            <CustomSelect
                                id={"GAAprojectRef"}
                                name={"GAAprojectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => handleProjectRef(e.target.value)}
                                required={true}
                            >
                                {ProjectList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                    </div>
                    {KeyContactList && KeyContactList.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading} >
                                {KeyContactList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white text-center">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{tableData.Name}</td>
                                                <td className="px-4 py-3">{tableData.GoogleLocation}</td>
                                                <td className="px-4 py-3">{tableData.TelephoneNumber}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <Link
                                                            to={`/key-contact/edit-key-contact/${tableData.Ref}`}
                                                            title="Edit"
                                                        >
                                                            <ActionButton
                                                                className={
                                                                    "p-1.5 text-white bg-yellow-400 "
                                                                }
                                                            >
                                                                <PencilSquareIcon className="w-4 h-4" />
                                                            </ActionButton>
                                                        </Link>
                                                        {Rights.Delete && (
                                                        <ActionButton onClick={() => deleteKeyContact(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                                            <TrashIcon className='w-4 h-4' />
                                                        </ActionButton>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! Key Contacts Data Not Found'} />
                    )}
                    {KeyContactList && KeyContactList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={KeyContactList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}

export default KeyContact


