import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import Label from "../../helpers/Label";
import Card from "../../helpers/Card";
import Input from "../../helpers/Input";
import LinkButton from "../../helpers/LinkButton";
import Loader from "../../Common/Loader";
import Validation from "../../helpers/Validation";
import { ArrowCircleLeft2 } from "iconsax-react";
import CancelButton from "../../helpers/CancelButton";
import SaveButton from "../../helpers/SaveButton";
import {
  GetGladianceUserRole,
  SaveGladianceUserRole,
} from "../../../Encapsulation/GladianceDomainEntities/GladianceUserRole";
import { ClearInfo } from "../../../Encapsulation/Utils";
import flasher from "@flasher/flasher";
import {
  GetGAAProductOutputDriverChannel,
  SaveGAAProductOutputDriverChannel,
} from "../../../Encapsulation/GladianceDomainEntities/GAAProductOutputDriverChannel";
import { GetProductOutputFunctionIdList } from "../../../Encapsulation/GladianceDomainEntities/GAAProductOutputFunctionId";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProductOutputFunctionTypeList } from "../../../Encapsulation/GladianceDomainEntities/GAAProductOutputFunctionType";
import { GetGAAProductModesMaster } from "../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster";
import { GetGAAProductOutputDriverChannelDeviceTypeList } from "../../../Encapsulation/GladianceDomainEntities/GAAProductOutputDriverChannelDeviceType";

function GAAProductOutputDriverChannelAddEdit() {
  let formMode = "New";

  let { id } = useParams();
  {
    if (id) {
      document.title = "Gladiance - Edit Product Output Channel";
      formMode = "Edit";
    } else {
      document.title = "Gladiance - Add Product Output Channel";
    }
  }

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [GAAProductOutputFunctionIdList, setProductOutputFunctionIdList] =
    useState([]);
  const [
    GAAProductOutputFunctionTypeList,
    setGAAProductOutputFunctionTypeList,
  ] = useState([]);
  const [TypeValue, setTypeValue] = useState();
  const [IdValue, setIdValue] = useState();
  const Navigation = useNavigate();
  const Location = useLocation();
  const [formInfo, setFormInfo] = useState({});

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };
  const [
    ProductOutputDriverChannelDeviceTypeList,
    setProductOutputDriverChannelDeviceTypeList,
  ] = useState([]);
  console.log(
    "ProductOutputDriverChannelDeviceTypeList :",
    ProductOutputDriverChannelDeviceTypeList
  );

  const getProductOutputDriverChannelDeviceTypeList = async () => {
    setProductOutputDriverChannelDeviceTypeList(
      await GetGAAProductOutputDriverChannelDeviceTypeList(setErrors)
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formInfo :", formInfo);
    let result = await SaveGAAProductOutputDriverChannel(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/product-output-driver-channel-master");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProduct();
      }
    }
  };

  // Fetch Product Details Using Location State //
  const fetchProduct = async () => {
    let Product = await GetGAAProductModesMaster(Location.state);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProductRef: Product.Ref,
      GAAProductName: Product.Name,
    }));
  };

  const FetchUserRole = async () => {
    setFormInfo(await GetGAAProductOutputDriverChannel(id));
  };

  const fetchGAAProductOutputFunctionIdList = async () => {
    setProductOutputFunctionIdList(await GetProductOutputFunctionIdList());
  };

  const fetchGAAProductOutputFunctionTypeList = async () => {
    setGAAProductOutputFunctionTypeList(
      await GetGAAProductOutputFunctionTypeList()
    );
  };

  useEffect(() => {
    if (Location.state) {
      fetchProduct();
    }
    if (id) {
      FetchUserRole();
    }
    getProductOutputDriverChannelDeviceTypeList();
    fetchGAAProductOutputFunctionIdList();
    fetchGAAProductOutputFunctionTypeList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={
            id ? "Edit Product Output Channel" : "Add Product Output Channel"
          }
          button={
            <LinkButton to={"/product-output-driver-channel-master"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor={"GAAProductName"} labelText={"Product"} />
                    {formInfo.GAAProductRef && (
                      <Input
                        id={"GAAProductName"}
                        type={"text"}
                        name={"GAAProductName"}
                        min={0}
                        value={formInfo.GAAProductName}
                        onChange={handleChange}
                        required={true}
                        readOnly={true}
                      />
                    )}
                    <Validation error={errors.GAAProductName} />
                  </div>
                  <div>
                    <Label htmlFor={"Name"} labelText={"Name"} />
                    <Input
                      inputFocus={true}
                      id={"Name"}
                      type={"text"}
                      name={"Name"}
                      value={formInfo.Name}
                      onChange={handleChange}
                      required={true}
                    />
                    <Validation error={errors.Name} />
                  </div>
                  <div>
                    <Label htmlFor={"DisplayOrder"} labelText={"Device No"} />
                    <Input
                      id={"DisplayOrder"}
                      type={"number"}
                      name={"DisplayOrder"}
                      value={formInfo.DisplayOrder}
                      onChange={handleChange}
                      min={0}
                    />
                  </div>
                  <div className="mb-3 ">
                    <Label
                      htmlFor={"GAAProductOutputDriverChannelDeviceTypeRef"}
                      labelText={"Product Output Channel Device Type"}
                    />
                    <CustomSelect
                      id={"GAAProductOutputDriverChannelDeviceTypeRef"}
                      name={"GAAProductOutputDriverChannelDeviceTypeRef"}
                      value={
                        formInfo.GAAProductOutputDriverChannelDeviceTypeRef
                      }
                      onChange={handleChange}
                      required={true}
                    >
                      <Option
                        value={""}
                      >
                        --- Select ---
                      </Option>
                      {ProductOutputDriverChannelDeviceTypeList.map(
                        (data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option
                                value={data.Ref}
                              >
                                {data.SingularName}
                              </Option>
                            </Fragment>
                          );
                        }
                      )}
                    </CustomSelect>
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/product-output-driver-channel-master"} />
                  <SaveButton text="Save" />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}
export default GAAProductOutputDriverChannelAddEdit;
