import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { GetGladianceUserRole, SaveGladianceUserRole } from '../../../Encapsulation/GladianceDomainEntities/GladianceUserRole'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'

function GladianceUserRoleMasterAddEdit() {
    let formMode = 'New';

    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit User';
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add User';
        }
    }

    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const [formInfo, setFormInfo] = useState({})

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveGladianceUserRole(id, formInfo);
        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/gladiance-userrole-master');
            }
            else {
                setFormInfo(ClearInfo(formInfo))
            }
        }
    };

    const FetchUserRole = async () => {
        setFormInfo(await GetGladianceUserRole(id));
    };

    useEffect(() => {
        if (id) {
            FetchUserRole();
        }
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit UserRole' : 'Add User Role'} button={
                    <LinkButton to={'/gladiance-userrole-master'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                    </div>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'User Role'} />
                                        <Input inputFocus={true} id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} required={true} />
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/gladiance-userrole-master'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}
export default GladianceUserRoleMasterAddEdit
