import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";


const MasterTableName = "GAAProjectDepartmentMaster";
const FetchRequestType = "GAAProjectDepartmentFetchRequest";

export const GetGAAProjectDepartment = async (Ref) => {
  let payload = { GAAProjectDepartmentRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGAAProjectDepartment = async (ref, payload, images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

export const DeleteGAAProjectDepartment = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectDepartmentList = async () => {
  let payload = { GAAProjectDepartmentRef: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const GetGAAProjectDepartmentListByProject = async (GAAProjectRef) => {
    let payload = { GAAProjectRefs: [GAAProjectRef] };
    let td = await FetchFromServer(payload, FetchRequestType, []);
    let result = GetMasterTableEntries(td, MasterTableName);
    return result;
  };


