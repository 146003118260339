import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight2, ArrowDown2 } from "iconsax-react";
import { useStateContext } from "../../context/ContextProvider";
import Nav from "../../components/Nav";

function Sidebar({ GladianceUserMapping, HotelUserMapping }) {
  const { Department } = GladianceUserMapping;
  const { theme, FRONTEND_URL } = useStateContext();

  const [showNotification, setShowNotification] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [toggleFun, setToggleFun] = useState({
    company: false,
    GladianceUserMasters: false,
    ProductMasters: false,
    ControlBouquet: false,
    CommercialInventory: false,
    Programming: false,
    CustomerMaster: false,
    UserManagement: false,
    RoomInventory: false,
    DeviceInventory: false,
    RoomSceneInventory: false,
    gladiancedevice: false,
    InRoomDining: false,
    door: false,
    Information: false,
    GuestManagement: false,
  });

  const navigate = useNavigate();

  const ToggleNotification = (status) => {
    setShowNotification(status);
  };

  const handleToggleFun = (e, v) => {
    setToggleFun({
      [e]: v,
    });
  };

  const logout = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const userNavigation = [
    { name: "Your Profile", href: "/your-profile" },
    { name: "Change Password", href: "/change-password" },
    { name: "Background Image", href: "/background-image" },
    { name: "Sign out", clickFuntion: logout },
  ];

  return (
    <div
      className={
        (theme ? "bg-[#222222] text-white" : "bg-gray-200 text-black") +
        " border-r h-[100%] flex items-center justify-center rounded-xl border-gray-300"
      }
    >
      <div className="overflow-x-auto h-[94%] journal-scroll ">
        <div>
          <button
            onClick={(e) => handleToggleFun("company", !toggleFun.company)}
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/companywhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/companydark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                Company
              </span>
              {toggleFun.company ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.company ? "block" : "hidden")
            }
          >
            <li>
              <Nav
                to="/solutions"
                id="Solutions"
                name="Solutions"
                iconPath="addcircle"
                label="Solutions"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/verticals"
                id="Verticals"
                name="Verticals"
                iconPath="addcircle"
                label="Verticals"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        <div>
          <button
            onClick={(e) =>
              handleToggleFun(
                "GladianceUserMasters",
                !toggleFun.GladianceUserMasters
              )
            }
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {/* <Category variant="Bold" className="h-4 w-4" /> */}
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/masterswhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/mastersdark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                Gladiance User Masters
              </span>
              {toggleFun.GladianceUserMasters ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.GladianceUserMasters ? "block" : "hidden")
            }
          >
            {(Department.View) && (
              <li>
                <Nav
                  to="/gladiance-department-master"
                  id="Departments"
                  name="Departments"
                  iconPath="addcircle"
                  label="Departments"
                  className={"pl-4"}
                />
              </li>
            )}
            <li>
              <Nav
                to="/gladiance-job-title-master"
                id="Job Titles"
                name="Job Titles"
                iconPath="addcircle"
                label="Job Titles"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/gladiance-userrole-master"
                id="User Roles"
                name="User Roles"
                iconPath="addcircle"
                label="User Roles"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/gladiance-user-rights"
                id="User Rights"
                name="User Rights"
                iconPath="addcircle"
                label="User Rights"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/gladiance-user-master"
                id="Users"
                name="Users"
                iconPath="addcircle"
                label="Users"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        <div>
          <button
            onClick={(e) =>
              handleToggleFun(
                "ProductMasters",
                !toggleFun.ProductMasters
              )
            }
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/companywhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/companydark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                Product  Inventory
              </span>
              {toggleFun.ProductMasters ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.ProductMasters ? "block" : "hidden")
            }
          >
            <li>
              <Nav
                to="/product-output-function-id-master"
                id="Product Output Function Ids"
                name="Product Output Function Ids"
                iconPath="addcircle"
                label="Product Output Function Ids"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/product-output-function-type-master"
                id="Product Output Function Types"
                name="Product Output Function Types"
                iconPath="addcircle"
                label="Product Output Function Types"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/product-operation-modes-master"
                id="Product Inventory"
                name="Product Inventory"
                iconPath="addcircle"
                label="Product Inventory"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/product-input-driver-channel-master"
                id="Product Input Channels"
                name="Product Input Channels"
                iconPath="addcircle"
                label="Product Input Channels"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/product-input-driver-channel-device-type-master"
                id="Product Output Channel Device Types"
                name="Product Output Channel Device Types"
                iconPath="addcircle"
                label="Product Output Channel Device Types"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/product-output-driver-channel-master"
                id="Product Output Channels"
                name="Product Output Channels"
                iconPath="addcircle"
                label="Product Output Channels"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/gladiance-product-version"
                id="Product Versions"
                name="Product Versions"
                iconPath="addcircle"
                label="Product Versions"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/gladiance-combo"
                id="Combos"
                name="Combos"
                iconPath="addcircle"
                label="Combos"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        <div>
          <button
            onClick={(e) =>
              handleToggleFun(
                "ControlBouquet",
                !toggleFun.ControlBouquet
              )
            }
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/companywhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/companydark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                Control Bouquet
              </span>
              {toggleFun.ControlBouquet ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.ControlBouquet ? "block" : "hidden")
            }
          >
            <li>
              <Nav
                to="/gladiance-solution-master"
                id="Control Bouquet Solutions"
                name="Control Bouquet Solutions"
                iconPath="addcircle"
                label="Control Bouquet Solutions"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/gladiance-control-bouquet"
                id="Control Bouquet Solution Configuration"
                name="Control Bouquet Solution Configuration"
                iconPath="addcircle"
                label="Control Bouquet Solution Configuration"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        <div>
          <button
            onClick={(e) =>
              handleToggleFun(
                "CommercialInventory",
                !toggleFun.CommercialInventory
              )
            }
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/companywhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/companydark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                Commercial Inventory
              </span>
              {toggleFun.CommercialInventory ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.CommercialInventory ? "block" : "hidden")
            }
          >
            <li>
              <Nav
                to="/country-master"
                id="Country Master"
                name="Country Master"
                iconPath="addcircle"
                label="Country Master"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/commercial-inventory"
                id="Commercial Inventory"
                name="Commercial Inventory"
                iconPath="addcircle"
                label="Commercial Inventory"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        <div>
          <button
            onClick={(e) =>
              handleToggleFun(
                "Programming",
                !toggleFun.Programming
              )
            }
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/companywhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/companydark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                Programming  Inventory
              </span>
              {toggleFun.Programming ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.Programming ? "block" : "hidden")
            }
          >
            <li>
              <Nav
                to="/programming-requisition"
                id="Programming Requisition"
                name="Programming Requisition"
                iconPath="addcircle"
                label="Programming Requisition"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/device-programming"
                id="Device Programming"
                name="Device Programming"
                iconPath="addcircle"
                label="Device Programming"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        <div>
          <button
            onClick={(e) =>
              handleToggleFun("CustomerMaster", !toggleFun.CustomerMaster)
            }
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/companywhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/companydark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                Customer Master
              </span>
              {toggleFun.CustomerMaster ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.CustomerMaster ? "block" : "hidden")
            }
          >
            <li>
              <Nav
                to="/customer"
                id="Customer"
                name="Customer"
                iconPath="addcircle"
                label="Customer"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        {
          HotelUserMapping.UserManagement.View && (
            <div>
              <button
                onClick={(e) =>
                  handleToggleFun(
                    "UserManagement",
                    !toggleFun.UserManagement
                  )
                }
                className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
              >
                <Fragment>
                  {theme ? (
                    <img
                      src={FRONTEND_URL + "/assets/icons/masterswhite.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  ) : (
                    <img
                      src={FRONTEND_URL + "/assets/icons/mastersdark.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  )}
                  <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                    User Management
                  </span>
                  {toggleFun.UserManagement ? (
                    <ArrowDown2 className="h-4 w-4" />
                  ) : (
                    <ArrowRight2 className="h-4 w-4" />
                  )}
                </Fragment>
              </button>
              <ul
                className={
                  "py-1.5 px-2 space-y-2 rounded-md " +
                  (toggleFun.UserManagement ? "block" : "hidden")
                }
              >
                <li>
                  <Nav
                    to="/gaa-project"
                    id="Project"
                    name="Project"
                    iconPath="addcircle"
                    label="Project"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/project-department-master"
                    id="Department"
                    name="Department"
                    iconPath="addcircle"
                    label="Department"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/project-job-title-master"
                    id="Job Titles"
                    name="Job Titles"
                    iconPath="addcircle"
                    label="Job Titles"
                    className={"pl-4"}
                  />
                </li>

                <li>
                  <Nav
                    to="/project-user-role-master"
                    id="User Roles"
                    name="User Roles"
                    iconPath="addcircle"
                    label="User Roles"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/rights-registration"
                    id="User Rights"
                    name="User Rights"
                    iconPath="addcircle"
                    label="User Rights"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/project-user-master"
                    id="Users"
                    name="Users"
                    iconPath="addcircle"
                    label="Users"
                    className={"pl-4"}
                  />
                </li>
              </ul>
            </div>
          )
        }
        {
          HotelUserMapping.RoomInventory.View && (
            <div>
              <button
                onClick={(e) =>
                  handleToggleFun(
                    "RoomInventory",
                    !toggleFun.RoomInventory
                  )
                }
                className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
              >
                <Fragment>
                  {theme ? (
                    <img
                      src={FRONTEND_URL + "/assets/icons/masterswhite.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  ) : (
                    <img
                      src={FRONTEND_URL + "/assets/icons/mastersdark.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  )}
                  <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                    Room Inventory
                  </span>
                  {toggleFun.RoomInventory ? (
                    <ArrowDown2 className="h-4 w-4" />
                  ) : (
                    <ArrowRight2 className="h-4 w-4" />
                  )}
                </Fragment>
              </button>
              <ul
                className={
                  "py-1.5 px-2 space-y-2 rounded-md " +
                  (toggleFun.RoomInventory ? "block" : "hidden")
                }
              >
                <li>
                  <Nav
                    to="/gaa-project-space-group"
                    id="Space Groups"
                    name="Space Groups"
                    iconPath="addcircle"
                    label="Space Groups"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/gaa-project-space-type"
                    id="Space Types"
                    name="Space Types"
                    iconPath="addcircle"
                    label="Space Types"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/gaa-project-space-type-area"
                    id="Space Group Type Areas"
                    name="Space Group Type Areas"
                    iconPath="addcircle"
                    label="Space Group Type Areas"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/gaa-project-space"
                    id="Project Spaces"
                    name="Project Spaces"
                    iconPath="addcircle"
                    label="Project Spaces"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/gaa-area-wifi-configuration"
                    id="Wifi Configuration"
                    name="Wifi Configuration"
                    iconPath="addcircle"
                    label="Wifi Configuration"
                    className={"pl-4"}
                  />
                </li>
              </ul>
            </div>
          )
        }

        {
          HotelUserMapping.DeviceInventory.View && (
            <div>
              <button
                onClick={(e) =>
                  handleToggleFun(
                    "DeviceInventory",
                    !toggleFun.DeviceInventory
                  )
                }
                className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
              >
                <Fragment>
                  {theme ? (
                    <img
                      src={FRONTEND_URL + "/assets/icons/masterswhite.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  ) : (
                    <img
                      src={FRONTEND_URL + "/assets/icons/mastersdark.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  )}
                  <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                    Device Inventory
                  </span>
                  {toggleFun.DeviceInventory ? (
                    <ArrowDown2 className="h-4 w-4" />
                  ) : (
                    <ArrowRight2 className="h-4 w-4" />
                  )}
                </Fragment>
              </button>
              <ul
                className={
                  "py-1.5 px-2 space-y-2 rounded-md " +
                  (toggleFun.DeviceInventory ? "block" : "hidden")
                }
              >
                <li>
                  <Nav
                    to="/gaa-project-space-type-planned-device"
                    id="Planned Device"
                    name="Planned Device"
                    iconPath="addcircle"
                    label="Planned Device"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/gaa-project-space-type-planned-device-Connections"
                    id="Device Connections"
                    name="Device Connections"
                    iconPath="addcircle"
                    label="Device Connections"
                    className={"pl-4"}
                  />
                </li>
              </ul>
            </div>
          )
        }

        {
          HotelUserMapping.RoomSceneInventory.View && (
            <div>
              <button
                onClick={(e) =>
                  handleToggleFun("RoomSceneInventory", !toggleFun.RoomSceneInventory)
                }
                className="flex items-center w-full p-2  transition duration-75 rounded-lg group mt-1"
              >
                <Fragment>
                  {theme ? (
                    <img
                      src={FRONTEND_URL + "/assets/icons/roommasterwhite.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  ) : (
                    <img
                      src={FRONTEND_URL + "/assets/icons/roommasterdark.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  )}
                  <span className="flex-1 ml-2 text-left whitespace-nowrap">
                    Room Scene Inventory
                  </span>
                  {toggleFun.RoomSceneInventory ? (
                    <ArrowDown2 className="h-4 w-4" />
                  ) : (
                    <ArrowRight2 className="h-4 w-4" />
                  )}
                </Fragment>
              </button>
              <ul
                className={
                  "py-1.5 px-2 space-y-2 rounded-md " +
                  (toggleFun.RoomSceneInventory ? "block" : "hidden")
                }
              >

                <li>
                  <Nav
                    to="/room-scene"
                    id="Room Scene Inventory"
                    name="Room Scene Inventory"
                    iconPath="addcircle"
                    label="Room Scene Inventory"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/scene-configurations"
                    id="scene- configurations"
                    name="scene configurations"
                    iconPath="addcircle"
                    label="Scene Configurations"
                    className={"pl-4"}
                  />
                </li>
              </ul>
            </div>
          )
        }

        {
          HotelUserMapping.TelevisionInventory.View && (
            <div>
              <Nav
                to="/television-master"
                id="Television Inventory"
                name="Television Inventory"
                iconPath="televisionmaster"
                label="Television Inventory"
                className={"pl-2"}
              />
            </div>
          )
        }


        <div>
          <button
            onClick={(e) => handleToggleFun("door", !toggleFun.door)}
            className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
          >
            <Fragment>
              {theme ? (
                <img
                  src={FRONTEND_URL + "/assets/icons/doorlock&camerawhite.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              ) : (
                <img
                  src={FRONTEND_URL + "/assets/icons/doorlock&cameradark.png"}
                  alt="user-img"
                  className="w-[6%]"
                />
              )}
              <span className="flex-1 ml-2 text-left whitespace-nowrap">
                Door Lock & Camera
              </span>
              {toggleFun.door ? (
                <ArrowDown2 className="h-4 w-4" />
              ) : (
                <ArrowRight2 className="h-4 w-4" />
              )}
            </Fragment>
          </button>
          <ul
            className={
              "py-1.5 px-2 space-y-2 rounded-md " +
              (toggleFun.door ? "block" : "hidden")
            }
          >
            <li>
              <Nav
                to="/door-camera"
                id="Door Camera"
                name="Door Camera"
                iconPath="addcircle"
                label="Door Camera"
                className={"pl-4"}
              />
            </li>
            <li>
              <Nav
                to="/door-camera"
                id="Door Lock"
                name="Door Lock"
                iconPath="addcircle"
                label="Door Lock"
                className={"pl-4"}
              />
            </li>
          </ul>
        </div>

        {
          HotelUserMapping.SurveillanceInventory.View && (
            <div>
              <Nav
                to="/surveillance"
                id="Surveillance Inventory"
                name="Surveillance Inventory"
                iconPath="Surveillance"
                label="Surveillance Inventory"
                className={"pl-2"}
              />
            </div>
          )
        }

        {
          HotelUserMapping.LaundryInventory.View && (
            <div>
              <Nav
                to="/laundry"
                id="Laundry Inventory"
                name="Laundry Inventory"
                iconPath="laundrymaster"
                label="Laundry Inventory"
                className={"pl-2"}
              />
            </div>
          )
        }




        {
          HotelUserMapping.InRoomDiningInventory.View && (
            <div>
              <button
                onClick={(e) => handleToggleFun("InRoomDining", !toggleFun.InRoomDining)}
                className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
              >
                <Fragment>
                  {theme ? (
                    <img
                      src={FRONTEND_URL + "/assets/icons/companywhite.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  ) : (
                    <img
                      src={FRONTEND_URL + "/assets/icons/companydark.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  )}
                  <span className="flex-1 ml-2 text-left whitespace-nowrap tracking-wide">
                    Dining Inventory
                  </span>
                  {toggleFun.InRoomDining ? (
                    <ArrowDown2 className="h-4 w-4" />
                  ) : (
                    <ArrowRight2 className="h-4 w-4" />
                  )}
                </Fragment>
              </button>
              <ul
                className={
                  "py-1.5 px-2 space-y-2 rounded-md " +
                  (toggleFun.InRoomDining ? "block" : "hidden")
                }
              >
                <li>
                  <Nav
                    to="/RB-item-serving-slot-master"
                    id="RB item serving slot master"
                    name="RB item serving slot master"
                    iconPath="inroomdiningmaster"
                    label="RB item serving slot master"
                    className={"pl-2"}
                  />
                </li>
                <li>
                  <Nav
                    to="/rb-item-master"
                    id="RBItemMaster"
                    name="RBItemMaster"
                    iconPath="addcircle"
                    label="RB Item Master"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/rb-item-applicable-serving-slots"
                    id="RBItemApplicableServingSlots"
                    name="RBItemApplicableServingSlots"
                    iconPath="addcircle"
                    label="RB Item Applicable Serving Slots"
                    className={"pl-4"}
                  />
                </li>
              </ul>
            </div>
          )
        }

        {
          HotelUserMapping.InformationInventory.View && (
            <div>
              <button
                onClick={(e) =>
                  handleToggleFun("Information", !toggleFun.Information)
                }
                className="flex items-center w-full p-2  transition duration-75 rounded-lg group mt-1"
              >
                <Fragment>
                  {/* <Category variant="Bold" className="h-6 w-6" /> */}
                  {theme ? (
                    <img
                      src={
                        FRONTEND_URL + "/assets/icons/informationmasterwhite.png"
                      }
                      alt="user-img"
                      className="w-[6%]"
                    />
                  ) : (
                    <img
                      src={FRONTEND_URL + "/assets/icons/informationmasterdark.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  )}
                  <span className="flex-1 ml-2 text-left whitespace-nowrap">
                    Information Inventory
                  </span>
                  {toggleFun.Information ? (
                    <ArrowDown2 className="h-4 w-4" />
                  ) : (
                    <ArrowRight2 className="h-4 w-4" />
                  )}
                </Fragment>
              </button>
              <ul
                className={
                  "py-1.5 px-2 space-y-2 rounded-md " +
                  (toggleFun.Information ? "block" : "hidden")
                }
              >
                <li>
                  <Nav
                    to="/amenities"
                    id="Amenities"
                    name="Amenities"
                    iconPath="addcircle"
                    label="Amenities"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/telephone"
                    id="Telephone"
                    name="Telephone"
                    iconPath="addcircle"
                    label="Telephone"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/key-contact"
                    id="Key Contact"
                    name="Key Contact"
                    iconPath="addcircle"
                    label="Key Contact"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/feedback"
                    id="Feedback"
                    name="Feedback"
                    iconPath="addcircle"
                    label="Feedback"
                    className={"pl-4"}
                  />
                </li>
              </ul>
            </div>
          )
        }


        <div>
          <Nav
            to="/miscellaneous-parameter"
            id="Miscellaneous Parameter Inventory"
            name="Miscellaneous Parameter Inventory"
            iconPath="parametermaster"
            label="Miscellaneous Parameter Inventory"
            className={"pl-2"}
          />
        </div>

        {
          HotelUserMapping.GuestManagement.View && (
            <div>
              <button
                onClick={(e) =>
                  handleToggleFun("GuestManagement", !toggleFun.GuestManagement)
                }
                className="flex items-center w-full p-2 transition duration-75 rounded-lg group mt-1"
              >
                <Fragment>
                  {/* <Category variant="Bold" className="h-6 w-6" /> */}
                  {theme ? (
                    <img
                      src={FRONTEND_URL + "/assets/icons/guestmanagementwhite.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  ) : (
                    <img
                      src={FRONTEND_URL + "/assets/icons/guestmanagementdark.png"}
                      alt="user-img"
                      className="w-[6%]"
                    />
                  )}
                  <span className="flex-1 ml-2 text-left whitespace-nowrap">
                    Guest Management
                  </span>
                  {toggleFun.GuestManagement ? (
                    <ArrowDown2 className="h-4 w-4" />
                  ) : (
                    <ArrowRight2 className="h-4 w-4" />
                  )}
                </Fragment>
              </button>
              <ul
                className={
                  "py-1.5 px-2 space-y-2 rounded-md " +
                  (toggleFun.GuestManagement ? "block" : "hidden")
                }
              >
                <li>
                  <Nav
                    to="/reservation"
                    id="Reservation"
                    name="Reservation"
                    iconPath="addcircle"
                    label="Reservation"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/check-in"
                    id="Check In"
                    name="Check In"
                    iconPath="addcircle"
                    label="Check In"
                    className={"pl-4"}
                  />
                </li>
                <li>
                  <Nav
                    to="/check-out"
                    id="Check Out"
                    name="Check Out"
                    iconPath="addcircle"
                    label="Check Out"
                    className={"pl-4"}
                  />
                </li>
              </ul>
            </div>
          )
        }


        {
          HotelUserMapping.BillViewManagement.View && (
            <div>
              <Nav
                to="/bill-view-management"
                id="Bill View Management"
                name="Bill View Management"
                iconPath="billviewmanagement"
                label="Bill View Management"
                className={"pl-2"}
              />
            </div>
          )
        }

        {
          HotelUserMapping.PromotionsManagement.View && (
            <div>
              <Nav
                to="/promotion-management"
                id="Promotions Management"
                name="Promotions Management"
                iconPath="promotionsmanagement"
                label="Promotions Management"
                className={"pl-2"}
              />
            </div>
          )
        }

        {
          HotelUserMapping.MessagingManagement.View && (
            <div>
              <Nav
                to="/message-management"
                id="Message Management"
                name="Message Management"
                iconPath="messagingmanagement"
                label="Message Management"
                className={"pl-2"}
              />
            </div>
          )
        }


      </div>
    </div>
  );
}
export default Sidebar;
