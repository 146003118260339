import { createContext, useContext, useState } from "react";

const StateContext = createContext({
  currentUser: {},
  setCurrentUser: () => {},
  userToken: null,
  setUserToken: () => {},
  FRONTEND_URL: null,
  BACKEND_URL: null,
  IMG_URL: null,
});

export const ContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [idParam, setIdParam] = useState("");
  const [successful, setSuccessful] = useState("");
  const [theme, setTheme] = useState(true);
  const [userToken, _setUserToken] = useState(
    localStorage.getItem("ACCESS_TOKEN")
  );
  const setUserToken = (token) => {
    token ? localStorage.setItem("ACCESS_TOKEN", token) : localStorage.clear();
    _setUserToken(token);
  };

  const [GAAProductRef, setGAAProductRef] = useState("");
  const [GAAProductInputRef, setGAAProductInputRef] = useState("");
  const [GAAProductOutputRef, setGAAProductOutputRef] = useState("");

  const [CustomerRef, setCustomerRef] = useState("");
  const [GAAProjectRef, setGAAProjectRef] = useState("");
  const [GAADepartmentRef, setGAADepartmentRef] = useState("");
  const [GAAProjectSpaceTypeRef, setGAAProjectSpaceTypeRef] = useState("");
  const [GAAProjectSpaceTypeAreaRef, setGAAProjectSpaceTypeAreaRef] =
    useState("");
  const [GAAProjectSpaceGroupRef, setGAAProjectSpaceGroupRef] = useState("");
  const [GAAProjectSpaceRef, setGAAProjectSpaceRef] = useState("");
  const [GAAProjectRBItemRef, setGAAProjectRBItemRef] = useState();

  // const FRONTEND_URL = 'http://localhost:3000';
  const BACKEND_URL = "http://localhost:8000";
  const IMG_URL = "http://localhost:8000";

  function handleCustomerRef(value) {
    setCustomerRef(value);
    setGAAProjectRef("");
  }

  function handleProjectRef(value) {
    setGAAProjectRef(value);
    setGAADepartmentRef("");
    setGAAProjectSpaceGroupRef("");
    setGAAProjectSpaceTypeRef("");
    setGAAProjectRBItemRef("");
  }

  function handleDepartmentRef(value) {
    setGAADepartmentRef(value);
  }

  function handleSpaceTypeRef(value) {
    setGAAProjectSpaceTypeRef(value);
    setGAAProjectSpaceTypeAreaRef("");
  }

  function handleSpaceGroupRef(value) {
    setGAAProjectSpaceGroupRef(value);
    setGAAProjectSpaceRef("");
  }

  function handleSpaceRef(value) {
    setGAAProjectSpaceRef(value);
  }
  function handleRBItemRef(value) {
    setGAAProjectRBItemRef(value);
  }

  // Online  URL'S //
  const FRONTEND_URL = "https://gladiancedev.enscloud.in";
  // const FRONTEND_URL = 'https://api.gladiance.one';

  //   const BACKEND_URL = 'https://smartsugarfactory.com/gladiance/backend/api/';
  //   const IMG_URL = 'https://smartsugarfactory.com/gladiance/backend';

  return (
    <StateContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        userToken,
        setUserToken,
        FRONTEND_URL,
        BACKEND_URL,
        IMG_URL,
        theme,
        setTheme,
        idParam,
        setIdParam,
        successful,
        setSuccessful,
        CustomerRef,
        setCustomerRef,
        GAAProjectRef,
        setGAAProjectRef,
        GAADepartmentRef,
        setGAADepartmentRef,
        GAAProjectSpaceTypeRef,
        setGAAProjectSpaceTypeRef,
        GAAProjectSpaceGroupRef,
        setGAAProjectSpaceGroupRef,
        handleCustomerRef,
        handleProjectRef,
        handleDepartmentRef,
        handleSpaceTypeRef,
        handleSpaceGroupRef,
        handleSpaceRef,
        handleRBItemRef,
        GAAProjectSpaceTypeAreaRef,
        setGAAProjectSpaceTypeAreaRef,
        GAAProjectSpaceRef,
        setGAAProjectSpaceRef,
        GAAProjectRBItemRef,
        setGAAProjectRBItemRef,
        GAAProductRef,
        setGAAProductRef,
        GAAProductInputRef,
        setGAAProductInputRef,
        GAAProductOutputRef,
        setGAAProductOutputRef,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
