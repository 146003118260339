import { BoltSlashIcon } from "@heroicons/react/24/solid";
import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GladianceControlBouquetSolutionMaster";
const FetchRequestType = "GladianceControlBouquetSolutionFetchRequest";

export const GetGladianceSolutionBouquet = async (Ref) => {
  let payload = { GladianceControlBouquetSolutionRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGladianceSolutionBouquet = async (
  ref,  payload,  images) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, images);
};

export const GetGladianceSolutionBouquetList = async () => {
  let payload = { GladianceControlBouquetSolutionRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

export const DeleteGladianceSolution = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};
