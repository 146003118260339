import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import { GetGAAProjectList } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGAAProjectSpaceTypeListByProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { DeleteProjectSpaceTypeArea, GetGAAProjectSpaceTypeAreaListBySpaceType } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { DeleteGAAProjectSpaceTypePlannedDevice, GetGAAProjectSpaceTypePlannedDeviceByArea, GetGAAProjectSpaceTypePlannedDeviceBySpaceType } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDevice";
import Input from "../../../helpers/Input";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";

function GAAProjectSpaceTypePlannedDevice({Rights}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Project Space Type Planned Device";
  const tableHeading = ["Sr.No", "Name", 'Product Name', "Action"];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [GAAProjectRef, setGAAProjectRef] = useState();
  const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState([]);
  const [GAAProjectSpaceTypeRef, setGAAProjectSpaceTypeRef] = useState();
  const [GAAProjectSpaceTypeAreaList, setGAAProjectSpaceTypeAreaList] = useState([]);
  const [GAAProjectSpaceTypeAreaRef, setGAAProjectSpaceTypeAreaRef] = useState();
  const [ProjectSpaceTypePlannedDeviceList, setProjectSpaceTypePlannedDeviceList] = useState([]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Fetch Project List For Dropdown //
  const fetchProjectList = async () => {
    setGAAProjectList(await GetGAAProjectList());
  };
  
    // Fetch Project Space Type List For Dropdown By Project //
    const fetchSpaceTypeListByProject = async () => {
      GAAProjectRef && setGAAProjectSpaceTypeList(await GetGAAProjectSpaceTypeListByProject(GAAProjectRef));
      setGAAProjectSpaceTypeAreaRef('');
    };

  
  // fetchProject Space Type Planned Device List According to Project Space Type  Ref //
  const fetchProjectSpaceTypePlannedDeviceListBySpaceType = async () => {
    GAAProjectSpaceTypeRef && setProjectSpaceTypePlannedDeviceList(await GetGAAProjectSpaceTypePlannedDeviceBySpaceType(GAAProjectSpaceTypeRef))
  };
  
  // Fetch Area List For Dropdown By Space Type//
  const fetchAreaBySpaceType = async () => {
    GAAProjectSpaceTypeRef && setGAAProjectSpaceTypeAreaList(await GetGAAProjectSpaceTypeAreaListBySpaceType(GAAProjectSpaceTypeRef));
  };
  // fetchProject Space Type Planned Device List According to Project Space Type Area Ref //
  const fetchProjectSpaceTypePlannedDeviceListByArea = async () => {
    GAAProjectSpaceTypeAreaRef && setProjectSpaceTypePlannedDeviceList(await GetGAAProjectSpaceTypePlannedDeviceByArea(GAAProjectSpaceTypeAreaRef))
  };

  const deletePlannedDevice= (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectSpaceTypePlannedDevice(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchProjectSpaceTypePlannedDeviceListByArea();
        }
      }
    })
  }


  useEffect(() => {
    fetchProjectList();
  }, []);

  useEffect(() => {
    if (GAAProjectList.length > 0) {
      setGAAProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchSpaceTypeListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypeList.length > 0) {
      setGAAProjectSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
    }
  }, [GAAProjectSpaceTypeList]);

  useEffect(() => {
    fetchAreaBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypeAreaList.length > 0) {
      setGAAProjectSpaceTypeAreaRef(GAAProjectSpaceTypeAreaList[0].Ref);
    }
  }, [GAAProjectSpaceTypeAreaList]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListByArea();
  }, [GAAProjectSpaceTypeAreaRef]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={" Planned Devices"}
          button={
            <LinkButton to={"/gaa-project-space-type-planned-device/add-gaa-project-space-type-planned-device"} className={Rights.Add ? "opacity-100" : "hidden"} state={{ GAAProjectRef: GAAProjectRef, GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef, GAAProjectSpaceTypeAreaRef: GAAProjectSpaceTypeAreaRef }}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Plan Device</span>
            </LinkButton>
          }
        >

          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
            <div>
              <Label
                htmlFor={"GAAProjectRef"}
                labelText={" Project"}
              />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => setGAAProjectRef(e.target.value)}
                required={true}
              >
                {GAAProjectList && GAAProjectList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeRef"}
                labelText={" Space Type"}
              />
              {GAAProjectSpaceTypeList.length > 0 ? (<div><CustomSelect
                id={"GAAProjectSpaceTypeRef"}
                name={"GAAProjectSpaceTypeRef"}
                value={GAAProjectSpaceTypeRef}
                onChange={(e) => setGAAProjectSpaceTypeRef(e.target.value)}
                required={true}
              >
                {GAAProjectSpaceTypeList && GAAProjectSpaceTypeList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect></div>) : (<div><Input placeHolder={"  No Space Types"} disabled={true}></Input></div>)}
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeAreaRef"}
                labelText={" Space Type Area"}
              />
              {GAAProjectSpaceTypeAreaList.length > 0 ? (<div><CustomSelect
                id={"GAAProjectSpaceTypeAreaRef"}
                name={"GAAProjectSpaceTypeAreaRef"}
                value={GAAProjectSpaceTypeAreaRef}
                onChange={(e) => setGAAProjectSpaceTypeAreaRef(e.target.value)}
                required={true}
              >
                {GAAProjectSpaceTypeAreaList && GAAProjectSpaceTypeAreaList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect></div>) : (<div><Input placeHolder={"  No Areas"} disabled={true}></Input></div>)}
            </div>
          </div>
          {ProjectSpaceTypePlannedDeviceList && ProjectSpaceTypePlannedDeviceList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {ProjectSpaceTypePlannedDeviceList &&
                  ProjectSpaceTypePlannedDeviceList
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((tableData, tableDataID) => {
                      return (
                        <Fragment key={tableDataID}>
                          <tr className="border-b border-opacity-30 border-white">
                            <td className="px-4 py-3">{tableDataID + 1}</td>
                            <td className="px-4 py-3">{tableData.Name}</td>
                            <td className="px-4 py-3">{tableData.GAAProductName}</td>
                            <td className="px-4 py-3">
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/gaa-project-space-type-planned-device/edit-gaa-project-space-type-planned-device/${tableData.Ref}`}
                                  title="Edit"
                                >
                                  <ActionButton
                                    className={
                                      "p-1.5 text-white bg-yellow-400 "
                                    }
                                  >
                                    <PencilSquareIcon className="w-4 h-4" />
                                  </ActionButton>
                                </Link>
                                {Rights.Delete && (
                                <ActionButton onClick={() => deletePlannedDevice(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                                )}
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Planned Device Data Not Found"} />
          )}
          {ProjectSpaceTypePlannedDeviceList && ProjectSpaceTypePlannedDeviceList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={ProjectSpaceTypePlannedDeviceList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceTypePlannedDevice;
