import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import Label from "../../helpers/Label";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProjectList, GetGAAProjectListByCustomer } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { DeleteGAASurveillanceCameraMaster, GetGAASurveillanceCameraMasterListByProject } from "../../../Encapsulation/HotelDomainEntities/hotelSurveillance";
import { useStateContext } from "../../../context/ContextProvider";
import { GetGladianceCustomerList } from "../../../Encapsulation/GladianceDomainEntities/Customer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Surveillance({ Rights }) {
  document.title = "Gladiance - Surveillance";
  const tableHeading = [
    "Sr.No",
    "Name",
    "IP Address",
    "ONVIF Port",
    "Camera UserName",
    "Camera Password",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [SurveillanceList, setSurveillanceList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const { GAAProjectRef, handleProjectRef,CustomerRef, handleCustomerRef } =   useStateContext();

   //Fetch Customer List For Dropdown//
const fetchCustomerList = async () => {
  setCustomerList(await GetGladianceCustomerList());
};

// Fetch Project List For Dropdown //
const fetchProjectListByCustomer = async () => {
    CustomerRef && setProjectList(await GetGAAProjectListByCustomer(CustomerRef));
};
  const itemsPerPage = 7;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchSurveillanceListByProject = async () => {
    GAAProjectRef &&
      setSurveillanceList(await GetGAASurveillanceCameraMasterListByProject(GAAProjectRef));
  };

 
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const deleteSurveillance = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAASurveillanceCameraMaster(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchSurveillanceListByProject();
        }
      }
    })
  }
  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    if (CustomerRef == "" && customerList.length > 0) {
      handleCustomerRef(customerList[0].Ref);
    }
  }, [customerList]);

  useEffect(() => {
      fetchProjectListByCustomer();
    }, [CustomerRef]);

useEffect(() => {
    if (GAAProjectRef == '' && ProjectList.length > 0) {
        handleProjectRef(ProjectList[0].Ref);
    }
}, [ProjectList]);

  useEffect(() => {
    fetchSurveillanceListByProject();
  }, [GAAProjectRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Surveillance List"}
          button={
          <LinkButton to={'/surveillance/add-surveillance'} className={Rights.Add ? 'opacity-100' : 'hidden'} state={GAAProjectRef}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Surveillance</span>
            </LinkButton>
          }
        >
           <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                    <div>
              <Label htmlFor={"CustomerRef"} labelText={"Customer"} />
              <CustomSelect
                id={"CustomerRef"}
                name={"CustomerRef"}
                value={CustomerRef}
                onChange={(e) => handleCustomerRef(e.target.value)}
              >
                {customerList &&
                  customerList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
                        <div>
                            <Label
                                htmlFor={"GAAProjectRef"}
                                labelText={"Project"}
                            />
                            <CustomSelect
                                id={"GAAProjectRef"}
                                name={"GAAProjectRef"}
                                value={GAAProjectRef}
                                onChange={(e) => handleProjectRef(e.target.value)}
                                required={true}
                            >
                                {ProjectList.map((data, id) => {
                                    return (
                                        <Fragment key={id}>
                                            <Option
                                                value={data.Ref}
                                            >
                                                {data.Name}
                                            </Option>
                                        </Fragment>
                                    );
                                })}
                            </CustomSelect>
                        </div>
                    </div>
          {SurveillanceList && SurveillanceList.length > 0 ? (
            <div className="m-2">
              <Table tableHeading={tableHeading}>
                {SurveillanceList
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white text-center">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.Name}</td>
                          <td className="px-4 py-3">{tableData.IPAddress}</td>
                          <td className="px-4 py-3">{tableData.ONVIFPort}</td>
                          <td className="px-4 py-3">{tableData.CameraUserName}</td>
                          <td className="px-4 py-3">{tableData.CameraPassword}</td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/surveillance/edit-surveillance/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                              <ActionButton onClick={() => deleteSurveillance(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                                )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Surveillance Data Not Found"} />
          )}
          {SurveillanceList && SurveillanceList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={SurveillanceList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default Surveillance;
