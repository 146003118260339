import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import Label from "../../helpers/Label";
import Card from "../../helpers/Card";
import Input from "../../helpers/Input";
import LinkButton from "../../helpers/LinkButton";
import Loader from "../../Common/Loader";
import Validation from "../../helpers/Validation";
import { ArrowCircleLeft2 } from "iconsax-react";
import CancelButton from "../../helpers/CancelButton";
import SaveButton from "../../helpers/SaveButton";
import { ClearInfo } from "../../../Encapsulation/Utils";
import flasher from "@flasher/flasher";
import {GetHotelRoomScene} from "../../../Encapsulation/HotelDomainEntities/HotelRoomScene";
import {GetProjectSpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import CustomSelect from "../../helpers/CustomSelect";
import Option from "../../helpers/Option";
import { GetGAAProjectSpaceTypePlannedDeviceConnectionBySpaceType } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDeviceConnections";
import { GetProjectSpaceTypeArea } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import { GetGAAProject } from "../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetHotelRoomSceneConfiguration, SaveHotelRoomSceneConfiguration } from "../../../Encapsulation/HotelDomainEntities/HotelRoomSceneConfiguration";

function RoomSceneConfigurationAddEdit({Rights}) {
  let formMode = "New";
  let { id,GAAProjectSceneRef, GAAProjectSpaceTypeRef,GAAProjectSpaceTypePlannedDeviceConnectionRef } = useParams();
  {
    if (id,GAAProjectSceneRef, GAAProjectSpaceTypeRef,GAAProjectSpaceTypePlannedDeviceConnectionRef) {
      document.title = "Hotel - Edit Room Scene Configuration";
      formMode = "Edit";
    } else {
      document.title = "Hotel - Add Room Scene Configuration";
    }
  }
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const Navigation = useNavigate();
  const Location = useLocation();
  const [formInfo, setFormInfo] = useState({});
  const [ProjectRef, setProjectRef] = useState();
  const [DeviceConnectionList, setDeviceConnectionList] = useState([]);

  const handleChange = (e) => {
    setFormInfo((formInfo) => ({
      ...formInfo,
      [e.target.name]: e.target.value,
    }));
  };

  // Save All Data To Server //
  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await SaveHotelRoomSceneConfiguration(id, formInfo, []);
    if (result.Successful == false) {
      flasher.warning(result.Message);
    } else {
      flasher.success("Data Added Successfully");
      if (formMode == "Edit") {
        Navigation("/scene-configurations");
      } else {
        setFormInfo(ClearInfo(formInfo));
        fetchProjectSpaceTypeandAreaHotelRoomScene(); // Save ProjectRef
      }
      console.log("result :", result);
    }
  };

  const fetchProjectSpaceTypeandAreaHotelRoomScene = async () => {
    let Project = await GetGAAProject(Location.state.GAAProjectRef);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectRef: Project.Ref,
      GAAProjectName: Project.Name
    }))
    let SpaceType = await GetProjectSpaceType(Location.state.GAAProjectSpaceTypeRef);
    setFormInfo(formInfo => ({
      ...formInfo,
      GAAProjectSpaceTypeRef: SpaceType.Ref,
      GAAProjectSpaceTypeName: SpaceType.Name,
    }))

    let SpaceTypeArea = '';
    if (Location.state.GAAProjectSpaceTypeAreaRef) {
      SpaceTypeArea = await GetProjectSpaceTypeArea(Location.state.GAAProjectSpaceTypeAreaRef);
    }
    if (SpaceTypeArea) {
      setFormInfo(formInfo => ({
        ...formInfo,
        GAAProjectSpaceTypeAreaRef: SpaceTypeArea.Ref,
        GAAProjectSpaceTypeAreaName: SpaceTypeArea.Name
      }));
    } else {
      setFormInfo(formInfo => ({
        ...formInfo,
        GAAProjectSpaceTypeAreaRef: 0,
        GAAProjectSpaceTypeAreaName: '-'
      }));
    }
    let Scene = await GetHotelRoomScene(Location.state.GAAProjectSceneRef);
    setProjectRef(Scene.GAAProjectRef);
    setFormInfo((formInfo) => ({
      ...formInfo,
      GAAProjectSceneRef: Scene.Ref,
      GAAProjectSceneName: Scene.Name,
    }));
  };

  const fetchDeviceConnectionBySpaceType = async () => {
    formInfo.GAAProjectSpaceTypeRef &&
      setDeviceConnectionList(
        await GetGAAProjectSpaceTypePlannedDeviceConnectionBySpaceType(formInfo.GAAProjectSpaceTypeRef, 0)
        
        );
        console.log('DeviceConnectionList :', DeviceConnectionList);
  };

  const fetchRoomSceneConfiguration = async () => {
    let data = await GetHotelRoomSceneConfiguration(GAAProjectSceneRef,GAAProjectSpaceTypeRef,GAAProjectSpaceTypePlannedDeviceConnectionRef)
    console.log('data :', data);
    setFormInfo(data);
}

  // Fetch project Details Using Location State //
  useEffect(() => {
    if (Location.state) {
        fetchProjectSpaceTypeandAreaHotelRoomScene();
    }
    if(GAAProjectSceneRef,GAAProjectSpaceTypeRef,GAAProjectSpaceTypePlannedDeviceConnectionRef)
    {         
      fetchRoomSceneConfiguration();
    }
  }, []);

  useEffect(() => {
    if (DeviceConnectionList.length > 0) {
      setFormInfo((formInfo) => ({
        ...formInfo,
        GAAProjectSpaceTypePlannedDeviceConnectionRef: DeviceConnectionList[0].Ref,
      }));
    };
    
  }, [DeviceConnectionList]);

  useEffect(() => {
    fetchDeviceConnectionBySpaceType();
  }, [formInfo.GAAProjectSpaceTypeRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <BackComponent
          title={(id,GAAProjectSceneRef, GAAProjectSpaceTypeRef,GAAProjectSpaceTypePlannedDeviceConnectionRef)? "Edit Room Scene Configuration" : "Add Room Scene Configuration"}
          button={
            <LinkButton to={"/scene-configurations"}>
              <ArrowCircleLeft2
                variant="Bold"
                className="w-5 h-5 text-default-color"
              />
            </LinkButton>
          }
        >
          <div className="flex items-center justify-center">
            <Card className={"w-full p-6"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label
                      htmlFor={"GAAProjectName"}
                      labelText={"Project"}
                    />
                    {formInfo.GAAProjectRef && (
                      <Input
                        id={"GAAProjectName"}
                        type={"text"}
                        name={"GAAProjectName"}
                        value={formInfo.GAAProjectName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypeName"}
                      labelText={"Space Type"}
                    />
                    {formInfo.GAAProjectSpaceTypeRef && (
                      <Input
                        id={"GAAProjectSpaceTypeName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeName"}
                        value={formInfo.GAAProjectSpaceTypeName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypeAreaName"}
                      labelText={"Area"}
                    />
                    {formInfo.GAAProjectSpaceTypeAreaRef == 0 ? (
                      <Input
                        id={"GAAProjectSpaceTypeAreaName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeAreaName"}
                        value={formInfo.GAAProjectSpaceTypeAreaName}
                        required={true}
                        readOnly={true}
                      />
                    ) : (
                      <Input
                        id={"GAAProjectSpaceTypeAreaName"}
                        type={"text"}
                        name={"GAAProjectSpaceTypeAreaName"}
                        value={formInfo.GAAProjectSpaceTypeAreaName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>
                  <div>
                    <Label
                      htmlFor={"GAAProjectSceneRef"}
                      labelText={"Room Scene Name"}
                    />
                    {formInfo.GAAProjectSceneRef && (
                      <Input
                        id={"GAAProjectSceneRef"}
                        type={"text"}
                        name={"GAAProjectSceneRef"}
                        value={formInfo.GAAProjectSceneName}
                        required={true}
                        readOnly={true}
                      />
                    )}
                  </div>

                  <div>
                    <Label
                      htmlFor={"GAAProjectSpaceTypePlannedDeviceConnectionRef"}
                      labelText={"Label"}
                    />
                    <CustomSelect
                      id={"GAAProjectSpaceTypePlannedDeviceConnectionRef"}
                      name={"GAAProjectSpaceTypePlannedDeviceConnectionRef"}
                      value={
                        formInfo.GAAProjectSpaceTypePlannedDeviceConnectionRef
                      }
                      onChange={handleChange}
                    >
                      {DeviceConnectionList &&
                        DeviceConnectionList.map((data, id) => {
                          return (
                            <Fragment key={id}>
                              <Option value={data.Ref}>{data.Label}</Option>
                            </Fragment>
                          );
                        })}
                    </CustomSelect>
                  </div>
                  <div>
                    <Label
                      htmlFor={"NodeConfigParamName"}
                      labelText={"Node Config Param Name"}
                    />
                    <Input
                      id={"NodeConfigParamName"}
                      type={"text"}
                      name={"NodeConfigParamName"}
                      value={formInfo.NodeConfigParamName}
                      inputFocus={true}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.NodeConfigParamName} />
                  </div>
                  <div>
                    <Label htmlFor={"Value"} labelText={"Value"} />
                    <Input
                      id={"Value"}
                      type={"text"}
                      name={"Value"}
                      value={formInfo.Value}
                      onChange={handleChange}
                      required={true}
                      readOnly={!Rights.Edit}
                    />
                    <Validation error={errors.Value} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={"/scene-configurations"} />
                  {
                    Rights.Edit && <SaveButton text="Save" />
                  }
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  );
}

export default RoomSceneConfigurationAddEdit;
