import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../../helpers/LinkButton";
import Table from "../../../helpers/Table";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import ActionButton from "../../../helpers/ActionButton";
import { useReactToPrint } from "react-to-print";
import Pagination from "../../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../../helpers/DataNotFound";
import CustomSelect from "../../../helpers/CustomSelect";
import Label from "../../../helpers/Label";
import Option from "../../../helpers/Option";
import { GetGAAProjectList } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProject";
import { GetGAAProjectSpaceTypeListByProject } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceType";
import { GetGAAProjectSpaceTypeAreaListBySpaceType } from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceGroupTypeArea";
import {
  GetGAAProjectSpaceTypePlannedDeviceByArea,
  GetGAAProjectSpaceTypePlannedDeviceBySpaceType,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDevice";
import {
  DeleteGAAProjectSpaceTypePlannedDeviceConnection,
  GetGAAProjectSpaceTypePlannedDeviceConnectionByPlannedDevice,
} from "../../../../Encapsulation/HotelDomainEntities/Hotel Master/GAAProjectSpaceTypePlannedDeviceConnections";
import flasher from "@flasher/flasher";
import Swal from "sweetalert2";

function GAAProjectSpaceTypePlannedDeviceConnections({ Rights }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Connections";
  const tableHeading = [
    "Sr.No",
    "Lable",
    "Wattage",
    "Product",
    "From Input",
    "From Output",
    "To Input",
    "To Output",
    "Action",
  ];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [GAAProjectList, setGAAProjectList] = useState([]);
  const [GAAProjectRef, setGAAProjectRef] = useState();
  const [GAAProjectSpaceTypeList, setGAAProjectSpaceTypeList] = useState([]);
  const [GAAProjectSpaceTypeRef, setGAAProjectSpaceTypeRef] = useState();
  const [GAASpaceTypeAreaList, setGAASpaceTypeAreaList] = useState([]);
  const [GAAProjectSpaceTypeAreaRef, setGAAProjectSpaceTypeAreaRef] =
    useState();
  const [DeviceConnectionList, setDeviceConnectionList] = useState([]);
  const [
    ProjectSpaceTypePlannedDeviceList,
    setProjectSpaceTypePlannedDeviceList,
  ] = useState([]);
  const [
    GAAProjectSpaceTypePlannedDeviceRef,
    setGAAProjectSpaceTypePlannedDeviceRef,
  ] = useState();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Fetch Project List For Dropdown //
  const fetchProjectList = async () => {
    setGAAProjectList(await GetGAAProjectList());
  };

  // Fetch Project Space Type List For Dropdown By Project //
  const fetchSpaceTypeListByProject = async () => {
    GAAProjectRef &&
      setGAAProjectSpaceTypeList(
        await GetGAAProjectSpaceTypeListByProject(GAAProjectRef)
      );
  };

  // Fetch Area List For Dropdown By Space Type//
  const fetchAreaBySpaceType = async () => {
    GAAProjectSpaceTypeRef &&
      setGAASpaceTypeAreaList(
        await GetGAAProjectSpaceTypeAreaListBySpaceType(GAAProjectSpaceTypeRef)
      );
  };

  // fetchProject Space Type Planned Device List According to Space Type Ref //
  const fetchProjectSpaceTypePlannedDeviceListBySpaceType = async () => {
    GAAProjectSpaceTypeRef &&
      setProjectSpaceTypePlannedDeviceList(
        await GetGAAProjectSpaceTypePlannedDeviceBySpaceType(
          GAAProjectSpaceTypeRef
        )
      );
  };

  // fetchProject Space Type Planned Device List According to Area Ref //
  const fetchProjectSpaceTypePlannedDeviceListByArea = async () => {
    GAAProjectSpaceTypeAreaRef &&
      setProjectSpaceTypePlannedDeviceList(
        await GetGAAProjectSpaceTypePlannedDeviceByArea(
          GAAProjectSpaceTypeAreaRef
        )
      );
  };

  const fetchDeviceConnectionByPlannedDevice = async () => {
    GAAProjectSpaceTypePlannedDeviceRef &&
      setDeviceConnectionList(
        await GetGAAProjectSpaceTypePlannedDeviceConnectionByPlannedDevice(
          GAAProjectSpaceTypePlannedDeviceRef
        )
      );
  };

  const deleteDeviceConnection = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProjectSpaceTypePlannedDeviceConnection(
          tableData
        );
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchDeviceConnectionByPlannedDevice();
        }
      }
    });
  };

  useEffect(() => {
    fetchProjectList();
  }, []);

  useEffect(() => {
    if (GAAProjectList.length > 0) {
      setGAAProjectRef(GAAProjectList[0].Ref);
    }
  }, [GAAProjectList]);

  useEffect(() => {
    fetchSpaceTypeListByProject();
  }, [GAAProjectRef]);

  useEffect(() => {
    if (GAAProjectSpaceTypeList.length > 0) {
      setGAAProjectSpaceTypeRef(GAAProjectSpaceTypeList[0].Ref);
    }
  }, [GAAProjectSpaceTypeList]);

  useEffect(() => {
    fetchAreaBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  useEffect(() => {
    if (GAASpaceTypeAreaList.length > 0) {
      setGAAProjectSpaceTypeAreaRef(GAASpaceTypeAreaList[0].Ref);
    }
  }, [GAASpaceTypeAreaList]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListBySpaceType();
  }, [GAAProjectSpaceTypeRef]);

  useEffect(() => {
    fetchProjectSpaceTypePlannedDeviceListByArea();
  }, [GAAProjectSpaceTypeAreaRef]);

  useEffect(() => {
    if (ProjectSpaceTypePlannedDeviceList.length > 0) {
      setGAAProjectSpaceTypePlannedDeviceRef(
        ProjectSpaceTypePlannedDeviceList[0].Ref
      );
    }
  }, [ProjectSpaceTypePlannedDeviceList]);

  useEffect(() => {
    fetchDeviceConnectionByPlannedDevice();
  }, [GAAProjectSpaceTypePlannedDeviceRef]);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Connections"}
          button={
            <LinkButton
              to={
                "/gaa-project-space-type-planned-device-Connections/add-gaa-project-space-type-planned-device-Connections"
              }
              className={Rights.Add ? "opacity-100" : "hidden"}
              state={{
                GAAProjectSpaceTypeRef: GAAProjectSpaceTypeRef,
                GAAProjectSpaceTypeAreaRef: GAAProjectSpaceTypeAreaRef,
                GAAProjectSpaceTypePlannedDeviceRef:
                  GAAProjectSpaceTypePlannedDeviceRef,
              }}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Connection</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
            <div>
              <Label htmlFor={"GAAProjectRef"} labelText={" Project"} />
              <CustomSelect
                id={"GAAProjectRef"}
                name={"GAAProjectRef"}
                value={GAAProjectRef}
                onChange={(e) => setGAAProjectRef(e.target.value)}
                required={true}
              >
                {GAAProjectList &&
                  GAAProjectList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeRef"}
                labelText={" Space Type"}
              />
              <CustomSelect
                id={"GAAProjectSpaceTypeRef"}
                name={"GAAProjectSpaceTypeRef"}
                value={GAAProjectSpaceTypeRef}
                onChange={(e) => setGAAProjectSpaceTypeRef(e.target.value)}
                required={true}
              >
                {GAAProjectSpaceTypeList &&
                  GAAProjectSpaceTypeList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypeAreaRef"}
                labelText={" Space Type Area"}
              />
              <CustomSelect
                id={"GAAProjectSpaceTypeAreaRef"}
                name={"GAAProjectSpaceTypeAreaRef"}
                value={GAAProjectSpaceTypeAreaRef}
                onChange={(e) => setGAAProjectSpaceTypeAreaRef(e.target.value)}
                required={true}
              >
                {GAASpaceTypeAreaList &&
                  GAASpaceTypeAreaList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
            <div>
              <Label
                htmlFor={"GAAProjectSpaceTypePlannedDeviceRef"}
                labelText={"Planned Device"}
              />
              <CustomSelect
                id={"GAAProjectSpaceTypePlannedDeviceRef"}
                name={"GAAProjectSpaceTypePlannedDeviceRef"}
                value={GAAProjectSpaceTypePlannedDeviceRef}
                onChange={(e) =>
                  setGAAProjectSpaceTypePlannedDeviceRef(e.target.value)
                }
                required={true}
              >
                {ProjectSpaceTypePlannedDeviceList &&
                  ProjectSpaceTypePlannedDeviceList.map((data, id) => {
                    return (
                      <Fragment key={id}>
                        <Option value={data.Ref}>{data.Name}</Option>
                      </Fragment>
                    );
                  })}
              </CustomSelect>
            </div>
          </div>
          {DeviceConnectionList && DeviceConnectionList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {DeviceConnectionList &&
                  DeviceConnectionList.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  ).map((tableData, tableDataID) => {
                    return (
                      <Fragment key={tableDataID}>
                        <tr className="border-b border-opacity-30 border-white">
                          <td className="px-4 py-3">{tableDataID + 1}</td>
                          <td className="px-4 py-3">{tableData.Label}</td>
                          <td className="px-4 py-3">{tableData.Wattage}</td>
                          <td className="px-4 py-3">
                            {tableData.GAAProductName}
                          </td>
                          <td className="px-4 py-3">
                            {tableData.BindFromGAAProductInputDriverChannelName}
                          </td>
                          <td className="px-4 py-3">
                            {
                              tableData.BindFromGAAProductOutputDriverChannelName
                            }
                          </td>
                          <td className="px-4 py-3">
                            {tableData.BindToGAAProductInputDriverChannelName}
                          </td>
                          <td className="px-4 py-3">
                            {tableData.BindToGAAProductOutputDriverChannelName}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-center gap-3">
                              <Link
                                to={`/gaa-project-space-type-planned-device-Connections/edit-gaa-project-space-type-planned-device-Connections/${tableData.Ref}`}
                                title="Edit"
                              >
                                <ActionButton
                                  className={"p-1.5 text-white bg-yellow-400 "}
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </ActionButton>
                              </Link>
                              {Rights.Delete && (
                                <ActionButton
                                  onClick={() =>
                                    deleteDeviceConnection(tableData)
                                  }
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Connections Data Not Found"} />
          )}
          {DeviceConnectionList && DeviceConnectionList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={DeviceConnectionList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProjectSpaceTypePlannedDeviceConnections;
