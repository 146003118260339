import React, { Fragment, useEffect, useRef, useState } from 'react'
import AddComponent from '../../../components/AddComponent'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import flasher from '@flasher/flasher'
import ActionButton from '../../helpers/ActionButton'
import { useReactToPrint } from 'react-to-print'
import Pagination from '../../helpers/Pagination';
import { useStateContext } from '../../../context/ContextProvider'
import DataNotFound from '../../helpers/DataNotFound'

// export class DeviceProgrammingRequest {
//     public readonly MessageType: string = 'DeviceProgrammingRequest';
//     public JobId: string = '';
//     public ProductVersionRef: number = 0;
//   }

//   export class BrowserConnectivityFileDownloadRequest {
//     public readonly MessageType: string = 'BrowserConnectivityFileDownloadRequest';
//   }

//   export class BrowserConnectivityFileDownloadResponse {
//     public readonly MessageType: string = 'BrowserConnectivityFileDownloadResponse';
//     public Successful: boolean = false;
//     public Message: string = '';
//   }

//   export class WaitingForProgrammerReadyStatus {
//     public readonly MessageType: string = 'WaitingForProgrammerReadyStatus';
//   }

//   export class ProgrammerReadySignal {
//     public readonly MessageType: string = 'ProgrammerReadySignal';
//   }

//   export class ProgrammerStoppedSignal {
//     public readonly MessageType: string = 'ProgrammerStoppedSignal';
//   }

//   export class     {
//     public readonly MessageType: string = 'DeviceProgrammingResponse'
//     public JobId: string = '';
//     public Successful: boolean = false;
//     public Message: string = '';
//     public QRCodeText: string = '';
//   }

//   export class DeviceProgrammingProgress {
//     public readonly MessageType: string = 'DeviceProgrammingProgress'
//     public JobId: string = '';
//     public Percentage: number = 0.0;
//     public Description: string = '';
//   }

function DeviceProgrammingMaster() {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    document.title = 'Gladiance - Device Programming '
    const tableHeading = ['Sr.No', 'Product', 'Image', 'Design Name', 'Hardware Id', 'Firmware Id', 'Firmware Type', 'Action']
    const [loader, setLoader] = useState(false)
    const [gladianceDepartmentMasterList, setGladianceDepartmentMasterList] = useState(['Design'])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const [image, setImage] = useState('');
    const { FRONTEND_URL, IMG_URL } = useStateContext();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const DownloadFile = (id) => {
        let ifr = document.getElementById('ifcomm');
        if (ifr) {
            const msg = {
                MessageType: 'BrowserConnectivityFileDownloadRequest',
            }
            ifr.contentWindow.postMessage(msg, '*');
        }
    }

    const claimAndFlash = (tableData) => {
        let ifr = document.getElementById('ifcomm');
        if (ifr) {
            const msg = {
                MessageType: 'DeviceProgrammingRequest',
                JobId: 'A',
                ProductVersionRef: 1
            }
            ifr.contentWindow.postMessage(msg, '*');
        }
    }

    const handleCommFrameMessage = (msg) => {
        if (msg.MessageType == 'DeviceProgrammingProgress') {

        }
        else if (msg.MessageType == 'DeviceProgrammingResponse') {
        
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleCommFrameMessage);
        // During Destroy : window.removeEventListener('message', handleCommFrameMessage);
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <AddComponent title={'Device Programming'}
                    // button={
                    //     <LinkButton to={'/device-programming/add-device-programming'}>
                    //         <AddCircle variant='Bold' className='w-5 h-5' />
                    //         <span>Add Device Programming </span>
                    //     </LinkButton>
                    // }
                >
                    {gladianceDepartmentMasterList && gladianceDepartmentMasterList.length > 0 ? (
                        <div ref={componentRef} className='m-2'>
                            <Table tableHeading={tableHeading}>
                                {gladianceDepartmentMasterList && gladianceDepartmentMasterList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((tableData, tableDataID) => {
                                    return (
                                        <Fragment key={tableDataID}>
                                            <tr className="border-b border-opacity-30 border-white">
                                                <td className="px-4 py-3">{tableDataID + 1}</td>
                                                <td className="px-4 py-3">{'XYZ (A)'}</td>
                                                <td className="px-4 py-3 justify-center flex">
                                                    {
                                                        <Link className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 hover:bg-gray-50-500 duration-300'>
                                                            <img src={image ? (
                                                                image.name ? URL.createObjectURL(image) : IMG_URL + "/assets/images/image/" + image
                                                            ) : FRONTEND_URL + "/assets/images/noimage.png"} alt="Cad-img" className='w-12 h-12 rounded' />
                                                        </Link>
                                                    }
                                                </td>
                                                <td className="px-4 py-3">{'1'}</td>
                                                <td className="px-4 py-3">{'1'}</td>
                                                <td className="px-4 py-3">{'1'}</td>
                                                <td className="px-4 py-3">{'Firmware-A-1.hex'}</td>
                                                <td className="px-4 py-3">
                                                    <div className='flex justify-center gap-3'>
                                                        <ActionButton onClick={() => claimAndFlash(tableData)} title={'Claim & Flash'} className='p-1.5 text-white bg-blue-600 rounded-lg cursor-pointer'>
                                                            Claim and Flash
                                                        </ActionButton>
                                                        <Link title='download' >
                                                            <ActionButton onClick={() => DownloadFile()} className={'p-1.5 text-white bg-green-600 '}>
                                                                <ArrowDownTrayIcon className='w-5 h-5' />
                                                            </ActionButton>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    )
                                })}
                            </Table>
                            <iframe style={{ visibility: 'hidden' }} title='programmer'
                                id="ifcomm" src="https://esptool.gladiance.one"
                                allow="serial cross-origin-isolated"
                                sandbox="allow-scripts allow-downloads">
                            </iframe>
                        </div>

                    ) : (
                        <DataNotFound labelText={'Sorry! Device Programming Data Not Found'} />
                    )}
                    {gladianceDepartmentMasterList && gladianceDepartmentMasterList.length > 7 ? (<div className="flex justify-end">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={gladianceDepartmentMasterList.length}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>) : (<div></div>)
                    }

                </AddComponent>
            )}
        </Fragment>
    )
}


export default DeviceProgrammingMaster
