import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GladianceControlBouquetMaster";
const FetchRequestType = "GladianceControlBouquetFetchRequest";

export const SaveGladianceControlBouquet = async (payload) => {
  return await SaveToServer(payload, MasterTableName, [], true);
};

export const GetGladianceControlBouquetList = async () => {
  let payload = { UserRoleRefs: [], ActivityRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  return GetMasterTableEntries(td, MasterTableName);
};