import React, { Fragment } from 'react'

function Option({ children, id, name, value, readOnly, disabled, className, onChange }) {
    return (
        <Fragment>
            <option id={id} name={name} value={value} readOnly={readOnly} disabled={disabled} onChange={onChange} className={"bg-black bg-opacity-80 text-white text-center " + (className)}>
                {children}
            </option>
        </Fragment>
    )
}

export default Option
