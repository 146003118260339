import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProgrammingRequisitionMaster";
const FetchRequestType = "GAAProgrammingRequisitionFetchRequest";

export const GetProgrammingRequisition = async (Ref) => {
  let payload = { GAAProgrammingRequisitionRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveProgrammingRequisition = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetProgrammingRequisitionList = async () => {
  let payload = { GAAProgrammingRequisitionRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.DesignId.localeCompare(b.DesignId));
  return result;
};


export const DeleteProgrammingRequisition = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};