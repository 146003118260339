import React, { Fragment, useEffect, useState } from 'react'
import axiosClient from '../../../Encapsulation/Axios'
import flasher from '@flasher/flasher'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import Button from '../../helpers/Button'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { useStateContext } from '../../../context/ContextProvider'
import { AddCircle, ArrowCircleLeft2, CloseCircle, DirectInbox } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import { PlusCircleIcon, PlusSmallIcon, UserPlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import SaveButton from '../../helpers/SaveButton'
import { ClearInfo } from '../../../Encapsulation/Utils'
import { GetHotelBillViewManagement, SaveHotelBillViewManagement } from '../../../Encapsulation/HotelDomainEntities/HotelBillViewManagement'

function BillViewManagementAddEdit() {
  let formMode = 'New';
  const { id } = useParams()
  if (id) {
    document.title = 'Gladiance - Edit Bill View Management'
    formMode = 'Edit'
  } else {
    document.title = 'Gladiance - Add Bill View Management'
  }
  const Navigation = useNavigate()
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState([])
  const { FRONTEND_URL, BACKEND_URL, IMG_URL } = useStateContext()
  const [formInfo, setFormInfo] = useState({})

  const handleChange = (e) => {
    setFormInfo(formInfo => ({
      ...formInfo,
      [e.target.name]: e.target.value
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let result = await SaveHotelBillViewManagement(id, formInfo, []);
    console.log('result :', result);

    if (result.Successful == false) {
      flasher.warning(result.Message)
    } else {
      flasher.success('Data Added Successfully')
      if (formMode == 'Edit') {
        Navigation('/bill-view-management');
      }
      else {
        setFormInfo(ClearInfo(formInfo))
      }
    }

  }

  const fetchBillView = async () => {
    setFormInfo(await GetHotelBillViewManagement(id));
  }

  useEffect(() => {
    if (id) {
      fetchBillView();
    }
  }, [])

  return (
    <Fragment>
      {loader ? (<Loader />) : (
        <BackComponent title={id ? 'Edit Bill View Management' : 'Add Bill View Management'} button={
          <LinkButton to={'/bill-view-management'}>
            <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
          </LinkButton>
        }>
          <div className='flex items-center justify-center'>
            <Card className={'w-full p-6'}>
              <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                  <div>
                    <Label htmlFor={'RoomNo'} labelText={'Room No.'}  />
                    <Input id={'RoomNo'} type={'text'} name={'RoomNo'} value={formInfo.RoomNo} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.RoomNo} />
                  </div>
                  <div>
                    <Label htmlFor={'RoomRent'} labelText={'Room Rent'}  />
                    <Input id={'RoomRent'} type={'text'} name={'RoomRent'} value={formInfo.RoomRent} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.RoomRent} />
                  </div>
                  
                  <div>
                    <Label htmlFor={'InRoomDining'} labelText={'In Room Dining'}  />
                    <Input id={'InRoomDining'} type={'text'} name={'InRoomDining'} value={formInfo.InRoomDining} onChange={handleChange} />
                    <Validation error={errors.InRoomDining} />
                  </div>
                  <div>
                    <Label htmlFor={'Laundry'} labelText={'Laundry'}  />
                    <Input id={'Laundry'} type={'text'} name={'Laundry'} value={formInfo.Laundry} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                    <Validation error={errors.Laundry} />
                  </div>
                  <div>
                    <Label htmlFor={'XXX'} labelText={'XXX'}  />
                    <Input id={'XXX'} type={'text'} name={'XXX'} value={formInfo.XXX} onChange={handleChange}  />
                    <Validation error={errors.XXX} />
                  </div>
                  <div>
                    <Label htmlFor={'Bill'} labelText={'Bill'}  />
                    <Input id={'Bill'} type={'text'} name={'Bill'} value={formInfo.Bill} onChange={handleChange}  />
                    <Validation error={errors.Bill} />
                  </div>
                  <div>
                    <Label htmlFor={'Advance'} labelText={'Advance'}  />
                    <Input id={'Advance'} type={'text'} name={'Advance'} value={formInfo.Advance} onChange={handleChange}  />
                    <Validation error={errors.Advance} />
                  </div>
                  <div>
                    <Label htmlFor={'Outstanding'} labelText={'Outstanding'}  />
                    <Input id={'Outstanding'} type={'text'} name={'Outstanding'} value={formInfo.Outstanding} onChange={handleChange}  />
                    <Validation error={errors.Outstanding} />
                  </div>
                </div>
                <div className="flex gap-8 justify-center mt-6">
                  <CancelButton to={'/bill-view-management'} />
                  <SaveButton text='Save' />
                </div>
              </form>
            </Card>
          </div>
        </BackComponent>
      )}
    </Fragment>
  )
}




export default BillViewManagementAddEdit
