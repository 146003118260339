import { AllocateSingleId } from "../AllocateId";
import { GetMasterTableEntries } from "../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../Post";

const MasterTableName = "GAAProductOutputDriverChannelDeviceTypes";
const FetchRequestType = "GAAProductOutputDriverChannelDeviceTypeFetchRequest";

export const GetGAAProductOutputDriverChannelDeviceType = async (Ref) => {
  let payload = { GAAProductOutputDriverChannelDeviceTypeRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const SaveGAAProductOutputDriverChannelDeviceType = async (
  ref,
  payload
) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const DeleteGAAProductOutputDriverChannelDeviceType = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProductOutputDriverChannelDeviceTypeList = async () => {
  let payload = { GAAProductOutputDriverChannelDeviceTypeRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  result = result.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
  return result;
};
