import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";


const MasterTableName = "GAAProjectSpaceAreaWifiConfigurations";
const FetchRequestType = "GAAProjectSpaceAreaWifiConfigurationFetchRequest";

export const SaveGAAWifiConfiguration = async (payload) => {
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetGAAWifiConfigurationList = async () => {
  let payload = { GAAWifiConfigurationRefs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const GetGAAWifiConfiguration = async (Ref) => {
  let payload = { GAAWifiConfigurationRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteGAAWifiConfiguration = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAWifiConfigurationListByProjectSpace = async (
  ProjectSpaceRef
) => {
  let payload = { GAAProjectSpaceRefs: [ProjectSpaceRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};
