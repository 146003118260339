import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from '../../../components/AddComponent'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid'
import LinkButton from '../../helpers/LinkButton'
import Table from '../../helpers/Table'
import Loader from '../../Common/Loader'
import { Link } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import ActionButton from '../../helpers/ActionButton'
import Pagination from '../../helpers/Pagination';
import { AddCircle } from 'iconsax-react'
import DataNotFound from '../../helpers/DataNotFound'
import CustomSelect from "../../helpers/CustomSelect";
import Label from "../../helpers/Label";
import Option from "../../helpers/Option";
import { GetGAAProductModesMasterList } from "../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster";
import { DeleteGAAProductVersions, GAAProductVersionByProduct } from "../../../Encapsulation/GladianceDomainEntities/GAAProductVersion";
import Swal from "sweetalert2";
import flasher from "@flasher/flasher";
import { useStateContext } from "../../../context/ContextProvider";

function GAAProductVersion() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Product Version";
  const tableHeading = ["Sr.No", "Design Id",'Hardware Type Name ',"Firmware Id", "Firmware Status Name",'Release Date ',"Action", ];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [productList, setProductList] = useState([]);
  const [productversionList, setProductVersionList] = useState([]);

  const { GAAProductRef, setGAAProductRef } = useStateContext();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



  // fetch input channel List According to product Ref //
  const fetchProductVersionListByProduct = async () => {
    GAAProductRef && setProductVersionList(await GAAProductVersionByProduct(GAAProductRef));
    console.log(productversionList);
  };


  // Fetch Product List For Dropdown //
  const fetchProductList = async () => {
    setProductList(await GetGAAProductModesMasterList());
  };
  const deleteProductVersion = (tableData) => {
    Swal.fire({
      title: 'Are you sure to delete this?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProductVersions(tableData)
        if (response.Successful == false) {
          flasher.warning(response.Message)
        } else {
          flasher.success('Deleted Successfully')
          fetchProductVersionListByProduct();
        }
      }
    })
  }

  useEffect(() => {
    fetchProductVersionListByProduct();
  }, [GAAProductRef]);

 
  useEffect(() => {
    if (GAAProductRef == '' && productList.length > 0) {
      setGAAProductRef(productList[0].Ref);
    }
  }, [productList]);

  useEffect(() => {
    fetchProductList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Product Versions"}
          button={
            <LinkButton to={"/gladiance-product-version/add-gladiance-product-version"} state={GAAProductRef}>
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Version</span>
            </LinkButton>
          }
        >

          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
            <div>
              <Label
                htmlFor={"GAAProductRef"}
                labelText={" Product Name"}
              />
              <CustomSelect
                id={"GAAProductRef"}
                name={"GAAProductRef"}
                value={GAAProductRef}
                onChange={(e) => setGAAProductRef(e.target.value)}
                required={true}
              >
                {productList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option
                        value={data.Ref}
                      >
                        {data.Name}
                      </Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {productversionList && productversionList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {productversionList &&
                  productversionList
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((tableData, tableDataID) => {
                      return (
                        <Fragment key={tableDataID}>
                          <tr className="border-b border-opacity-30 border-white">
                            <td className="px-4 py-3">{tableDataID + 1}</td>
                            <td className="px-4 py-3">{tableData.DesignId}</td>
                            <td className="px-4 py-3">{tableData.HardwareTypeName}</td>
                            <td className="px-4 py-3">{tableData.FirmwareId}</td>
                            <td className="px-4 py-3">{tableData.FirmwareStatusName }</td>
                            <td className="px-4 py-3">{tableData.ReleaseDate }</td>
                            <td className="px-4 py-3">
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/gladiance-product-version/edit-gladiance-product-version/${tableData.Ref}`}
                                  title="Edit"
                                >
                                  <ActionButton
                                    className={
                                      "p-1.5 text-white bg-yellow-400 "
                                    }
                                  >
                                    <PencilSquareIcon className="w-4 h-4" />
                                  </ActionButton>
                                </Link>
                                <ActionButton onClick={() => deleteProductVersion(tableData)} title={'Delete'} className='p-1.5 text-white bg-red-600 rounded-lg cursor-pointer'>
                                  <TrashIcon className='w-4 h-4' />
                                </ActionButton>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Product Version Data Not Found"} />
          )}
          {productversionList && productversionList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={productversionList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProductVersion;
