import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import { ArrowCircleLeft2} from 'iconsax-react'
import { useStateContext } from '../../../context/ContextProvider'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { GetGladianceCountry, SaveGladianceCountry } from '../../../Encapsulation/GladianceDomainEntities/GladianceCountryMaster'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'

function CountryMasterAddEdit() {
    let formMode = 'New';
    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit Country';
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add Country';
        }
    }
    const { FRONTEND_URL, IMG_URL } = useStateContext()
    const [CountryImage, setCountryImage] = useState('')

    const handleImage = (e) => {
        setCountryImage(e.target.files[0]);
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const [imgInfo, setImgInfo] = useState({})
    const [formInfo, setFormInfo] = useState({})

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveGladianceCountry(id, formInfo,
            [['CountryImage', imgInfo.CountryImage]]);
        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/country-master');
            }
            else {
                setFormInfo(ClearInfo(formInfo))
                setImgInfo(ClearInfo(imgInfo))
            }
        }
    };

    const FetchCountry = async () => {
        let Country = await GetGladianceCountry(id);
        setFormInfo(Country);
    };

    useEffect(() => {
        if (id) {
            FetchCountry();
        }
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Country Details' : 'Add Country Details'} button={
                    <LinkButton to={'/country-master'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />

                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'Name'} />
                                        <Input inputFocus={true} id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Currency'} labelText={'Currency'} />
                                        <Input id={'Currency'} type={'text'} name={'Currency'} value={formInfo.Currency} onChange={handleChange} />
                                        <Validation error={errors.Currency} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'CurrencySymbol'} labelText={'Currency Symbol'} />
                                        <Input id={'CurrencySymbol'} type={'text'} name={'CurrencySymbol'} value={formInfo.CurrencySymbol} onChange={handleChange} />
                                        <Validation error={errors.CurrencySymbol} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'CurrencyName'} labelText={'Currency Name'} />
                                        <Input id={'CurrencyName'} type={'text'} name={'CurrencyName'} value={formInfo.CurrencyName} onChange={handleChange} />
                                        <Validation error={errors.CurrencyName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'CountryImage'} labelText={'Country Img'} />
                                        <img src={imgInfo.CountryImage ? ((imgInfo.CountryImage.name) ? URL.createObjectURL(imgInfo.CountryImage) : (IMG_URL + '/assets/images/UserImages/' + imgInfo.CountryImage)) : FRONTEND_URL + "/assets/images/noimage.png"} alt="profile-img" className='w-[10vw] h-[10vw] rounded' />
                                        <Input id={'CountryImage'} type={'file'} name={'CountryImage'} onChange={(e) => setImgInfo({ ...imgInfo, CountryImage: e.target.files[0] })} className={'p-[1px!important] mt-2'} />
                                        <Validation error={errors.CountryImage} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/country-master'} />
                                    <SaveButton text='Save' />
                                </div>
                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}


export default CountryMasterAddEdit
