import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackComponent from '../../../components/BackComponent'
import Label from '../../helpers/Label'
import Card from '../../helpers/Card'
import Input from '../../helpers/Input'
import LinkButton from '../../helpers/LinkButton'
import Loader from '../../Common/Loader'
import Validation from '../../helpers/Validation'
import CustomSelect from '../../helpers/CustomSelect'
import { ArrowCircleLeft2 } from 'iconsax-react'
import CancelButton from '../../helpers/CancelButton'
import SaveButton from '../../helpers/SaveButton'
import { GetGladianceUser, SaveGladianceUser } from '../../../Encapsulation/GladianceDomainEntities/GladianceUser'
import { GetGladianceUserRoleList } from '../../../Encapsulation/GladianceDomainEntities/GladianceUserRole'
import { GetGladianceDepartmentList } from '../../../Encapsulation/GladianceDomainEntities/GladianceDepartment'
import { GetGladianceJobTitleList } from '../../../Encapsulation/GladianceDomainEntities/GladianceJobTitle'
import { ClearInfo } from '../../../Encapsulation/Utils'
import flasher from '@flasher/flasher'
import Option from '../../helpers/Option'

function UserMasterAddEdit() {
    let formMode = 'New';
    let { id } = useParams()
    {
        if (id) {
            document.title = 'Gladiance - Edit User';
            formMode = 'Edit';
        }
        else {
            document.title = 'Gladiance - Add User';
        }
    }
    const [loader, setLoader] = useState(false)
    const [errors, setErrors] = useState([])
    const Navigation = useNavigate()
    const [gladianceDepartmentMasterList, setGladianceDepartmentMasterList] = useState([]);
    const [gladianceJobTitleList, setGladianceJobTitleList] = useState([]);
    const [gladianceUserRoleList, setGladianceUserRoleList] = useState([]);
    const [formInfo, setFormInfo] = useState({})

    const handleChange = (e) => {
        setFormInfo(formInfo => ({
            ...formInfo,
            [e.target.name]: e.target.value
        }))
    }
   

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await SaveGladianceUser(id, formInfo);
        if (result.Successful == false) {
            flasher.warning(result.Message)
        } else {
            flasher.success('Data Added Successfully')
            if (formMode == 'Edit') {
                Navigation('/gladiance-user-master');
            }
            else {
                setFormInfo(ClearInfo(formInfo))
        }
        console.log('result :', result);
    }
    };

    const FetchUser = async () => {
        let User = await GetGladianceUser(id);
        setFormInfo(User);
    };

    const fetchGladianceDepartmentList = async () => {
        let userList = await GetGladianceDepartmentList();
        setGladianceDepartmentMasterList(userList);
    }
    const fetchGladianceJobTitleList = async () => {
        let userList = await GetGladianceJobTitleList();
        setGladianceJobTitleList(userList);
    }
    const fetchGladianceUserRoleList = async () => {
        let userList = await GetGladianceUserRoleList();
        setGladianceUserRoleList(userList);
    }

    useEffect(() => {
        if (id) {
            FetchUser();
        }
        fetchGladianceDepartmentList();
        fetchGladianceJobTitleList();
        fetchGladianceUserRoleList();
    }, [])

    return (
        <Fragment>
            {loader ? (<Loader />) : (
                <BackComponent title={id ? 'Edit Company' : 'Add User'} button={
                    <LinkButton to={'/gladiance-user-master'}>
                        <ArrowCircleLeft2 variant='Bold' className='w-5 h-5 text-default-color' />
                    </LinkButton>
                }>
                    <div className='flex items-center justify-center'>
                        <Card className={'w-full p-6'}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                                    <div>
                                        <Label htmlFor={'GladianceDepartmentRef'} labelText={'Gladiance Department'} />
                                        <CustomSelect inputFocus={true} id={'GladianceDepartmentRef'} name={'GladianceDepartmentRef'} value={formInfo.GladianceDepartmentRef} onChange={handleChange} required={true}>
                                            <Option value={''}>--- Select Department ---</Option>
                                            {gladianceDepartmentMasterList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option
                                                            
                                                            value={data.Ref}
                                                        >
                                                            {data.Name}
                                                        </Option>
                                                    </Fragment>
                                                );
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.GladianceDepartmentRef} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'GladianceJobTitleRef'} labelText={'Gladiance Job Title'} />
                                        <CustomSelect id={'GladianceJobTitleRef'} name={'GladianceJobTitleRef'} value={formInfo.GladianceJobTitleRef} onChange={handleChange} required={true}>
                                            <Option value={''}>--- Select Job Title ---</Option>
                                            {gladianceJobTitleList.map((data, id) => {
                                                return (
                                                    <Fragment key={id}>
                                                        <Option
                                                            
                                                            value={data.Ref}
                                                        >
                                                            {data.Name}
                                                        </Option>
                                                    </Fragment>
                                                );
                                            })}
                                        </CustomSelect>
                                        <Validation error={errors.GladianceJobTitleRef} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'GladianceUserRoleRef '} labelText={'User Role'} />
                                        <div className="flex">
                                            <CustomSelect id={'GladianceUserRoleRef'} name={'GladianceUserRoleRef'} value={formInfo.GladianceUserRoleRef} onChange={handleChange} required={true}>
                                                <Option value={''}>--- Select User Role ---</Option>
                                                {gladianceUserRoleList.map((data, id) => {
                                                    return (
                                                        <Fragment key={id}>
                                                            <Option
                                                                
                                                                value={data.Ref}
                                                            >
                                                                {data.Name}
                                                            </Option>
                                                        </Fragment>
                                                    );
                                                })}
                                            </CustomSelect>
                                        </div>
                                        <Validation error={errors.GladianceUserRoleRef} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Name'} labelText={'User Name'} />
                                        <Input id={'Name'} type={'text'} name={'Name'} value={formInfo.Name} onChange={handleChange} required={true}/>
                                        <Validation error={errors.Name} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'EMailId'} labelText={'Email Id'} />
                                        <Input id={'EMailId'} type={'email'} name={'EMailId'} value={formInfo.EMailId} onChange={handleChange} required={true}/>
                                        <Validation error={errors.EMailId} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'Password'} labelText={'Password'} />
                                        <Input id={'Password'} type={'Password'} name={'Password'} value={formInfo.Password} onChange={handleChange} required={true}/>
                                        <Validation error={errors.Password} />
                                    </div>
                                   
                                    <div>
                                        <Label htmlFor={'FirstName'} labelText={'First Name'} />
                                        <Input id={'FirstName'} type={'text'} name={'FirstName'} value={formInfo.FirstName} onChange={handleChange} onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }} />
                                        <Validation error={errors.FirstName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'MiddleName'} labelText={'Middle Name'} />
                                        <Input id={'MiddleName'} type={'text'} name={'MiddleName'} value={formInfo.MiddleName} onChange={handleChange} required={true}  onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }}/>
                                        <Validation error={errors.MiddleName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'LastName'} labelText={'Last Name'} />
                                        <Input id={'LastName'} type={'text'} name={'LastName'} value={formInfo.LastName} onChange={handleChange} required={true}  onKeyPress={(event) => { if (!/[A-Za-z]|\ /.test(event.key)) { event.preventDefault() } }}/>

                                        <Validation error={errors.LastName} />
                                    </div>
                                    <div>
                                        <Label htmlFor={'PhoneNo'} labelText={'Phone Number'} />
                                        <Input id={'PhoneNo'} type={'text'} name={'PhoneNo'} value={formInfo.PhoneNo} onChange={handleChange}
                                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} minLength={10} maxLength={10}  required={true}/>
                                        <Validation error={errors.PhoneNo} />
                                    </div>
                                </div>
                                <div className="flex gap-8 justify-center mt-6">
                                    <CancelButton to={'/gladiance-user-master'} />
                                    <SaveButton text='Save' />
                                </div>

                            </form>
                        </Card>
                    </div>
                </BackComponent>
            )}
        </Fragment>
    )
}


export default UserMasterAddEdit
