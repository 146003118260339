import { AllocateSingleId } from "../../AllocateId";
import { GetMasterTableEntries } from "../../GetMasterTableEntries";
import { DeleteFromServer, FetchFromServer, SaveToServer } from "../../Post";

const MasterTableName = "GAAProjectSpaceTypePlannedDevices";
const FetchRequestType = "GAAProjectSpaceTypePlannedDeviceFetchRequest";

export const SaveGAAProjectSpaceTypePlannedDevice = async (ref, payload) => {
  payload["Ref"] = await AllocateSingleId(ref);
  return await SaveToServer(payload, MasterTableName, []);
};

export const GetGAAProjectSpaceTypePlannedDeviceList = async () => {
  let payload = { Refs: [] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  //  result = result.sort((a, b) => a.Name.localeCompare(b.Name));
  return result;
};

export const GetGAAProjectSpaceTypePlannedDevice = async (Ref) => {
  let payload = { GAAProjectSpaceTypePlannedDeviceRefs: [Ref] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let resultList = GetMasterTableEntries(td, MasterTableName);
  if (resultList.length > 0) {
    return resultList[0];
  }
  return null;
};

export const DeleteGAAProjectSpaceTypePlannedDevice = async (payload) => {
  return await DeleteFromServer(payload, MasterTableName);
};

export const GetGAAProjectSpaceTypePlannedDeviceByArea = async (
  GAAProjectSpaceTypeAreaRef
) => {
  let payload = { GAAProjectSpaceTypeAreaRefs: [GAAProjectSpaceTypeAreaRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};

export const GetGAAProjectSpaceTypePlannedDeviceBySpaceType = async (
  GAAProjectSpaceTypeRef
) => {
  let payload = { GAAProjectSpaceTypeRefs: [GAAProjectSpaceTypeRef] };
  let td = await FetchFromServer(payload, FetchRequestType, []);
  let result = GetMasterTableEntries(td, MasterTableName);
  return result;
};


