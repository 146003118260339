import React, { Fragment, useEffect, useRef, useState } from "react";
import AddComponent from "../../../components/AddComponent";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import LinkButton from "../../helpers/LinkButton";
import Table from "../../helpers/Table";
import Loader from "../../Common/Loader";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ActionButton from "../../helpers/ActionButton";
import Pagination from "../../helpers/Pagination";
import { AddCircle } from "iconsax-react";
import DataNotFound from "../../helpers/DataNotFound";
import CustomSelect from "../../helpers/CustomSelect";
import Label from "../../helpers/Label";
import Option from "../../helpers/Option";
import {
  DeleteGAAProductInputDriverChannel,
  GAAProductInputDriverChannelListByProduct,
} from "../../../Encapsulation/GladianceDomainEntities/GAAProductInputDriverChannel ";
import { GetGAAProductModesMasterList } from "../../../Encapsulation/GladianceDomainEntities/GAAProductModeMaster";
import flasher from "@flasher/flasher";
import Swal from "sweetalert2";
import { useStateContext } from "../../../context/ContextProvider";

function GAAProductInputDriverChannel() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  document.title = "Gladiance - Product Input Driver Channel";
  const tableHeading = ["Sr.No", "Channel Name", "Button No ", "Action"];
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  const [productList, setProductList] = useState([]);
  const [inputDriverChannelList, setInputDriverChannelList] = useState([]);
  
  const { GAAProductInputRef, setGAAProductInputRef } = useStateContext();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // fetch input channel List According to product Ref //
  const fetchInputDriverChannelListByProduct = async () => {
    GAAProductInputRef &&
      setInputDriverChannelList(
        await GAAProductInputDriverChannelListByProduct(GAAProductInputRef)
      );
  };

  // Fetch Product List For Dropdown //
  const fetchProductList = async () => {
    setProductList(
      (await GetGAAProductModesMasterList()).filter(
        (e) =>
          e.GAAProductOperationMode == 10 ||
          e.GAAProductOperationMode == 30 ||
          e.GAAProductOperationMode == 40
      )
    );
  };

  const deleteProductInputDriverChannel = (tableData) => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await DeleteGAAProductInputDriverChannel(tableData);
        if (response.Successful == false) {
          flasher.warning(response.Message);
        } else {
          flasher.success("Deleted Successfully");
          fetchInputDriverChannelListByProduct();
        }
      }
    });
  };

  useEffect(() => {
    fetchInputDriverChannelListByProduct();
  }, [GAAProductInputRef]);

  useEffect(() => {
    if (GAAProductInputRef == '' && productList.length > 0) {
      setGAAProductInputRef(productList[0].Ref);
    }
  }, [productList]);

  useEffect(() => {
    fetchProductList();
  }, []);

  return (
    <Fragment>
      {loader ? (
        <Loader />
      ) : (
        <AddComponent
          title={"Product Input Channels"}
          button={
            <LinkButton
              to={
                "/product-input-driver-channel-master/add-product-input-driver-channel-master"
              }
              state={GAAProductInputRef}
            >
              <AddCircle variant="Bold" className="w-5 h-5" />
              <span>Add Input Channel</span>
            </LinkButton>
          }
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div>
              <Label htmlFor={"GAAProductInputRef"} labelText={" Product Name"} />
              <CustomSelect
                id={"GAAProductInputRef"}
                name={"GAAProductInputRef"}
                value={GAAProductInputRef}
                onChange={(e) => setGAAProductInputRef(e.target.value)}
                required={true}
              >
                {productList.map((data, id) => {
                  return (
                    <Fragment key={id}>
                      <Option value={data.Ref}>{data.Name}</Option>
                    </Fragment>
                  );
                })}
              </CustomSelect>
            </div>
          </div>
          {inputDriverChannelList && inputDriverChannelList.length > 0 ? (
            <div ref={componentRef} className="m-2">
              <Table tableHeading={tableHeading}>
                {inputDriverChannelList &&
                  inputDriverChannelList
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((tableData, tableDataID) => {
                      return (
                        <Fragment key={tableDataID}>
                          <tr className="border-b border-opacity-30 border-white">
                            <td className="px-4 py-3">{tableDataID + 1}</td>
                            <td className="px-4 py-3">{tableData.Name}</td>
                            <td className="px-4 py-3">
                              {tableData.DisplayOrder}
                            </td>
                            <td className="px-4 py-3">
                              <div className="flex justify-center gap-3">
                                <Link
                                  to={`/product-input-driver-channel-master/edit-product-input-driver-channel-master/${tableData.Ref}`}
                                  title="Edit"
                                >
                                  <ActionButton
                                    className={
                                      "p-1.5 text-white bg-yellow-400 "
                                    }
                                  >
                                    <PencilSquareIcon className="w-4 h-4" />
                                  </ActionButton>
                                </Link>
                                <ActionButton
                                  onClick={() =>
                                    deleteProductInputDriverChannel(tableData)
                                  }
                                  title={"Delete"}
                                  className="p-1.5 text-white bg-red-600 rounded-lg cursor-pointer"
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </ActionButton>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
              </Table>
            </div>
          ) : (
            <DataNotFound labelText={"Sorry! Input Channel Data Not Found"} />
          )}
          {inputDriverChannelList && inputDriverChannelList.length > 7 ? (
            <div className="flex justify-end">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={inputDriverChannelList.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </AddComponent>
      )}
    </Fragment>
  );
}

export default GAAProductInputDriverChannel;
