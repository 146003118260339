import React, { Fragment, useEffect, useRef } from 'react'
import { useStateContext } from '../../context/ContextProvider'

function Input({ type, id, name, value, className, onChange, readOnly, min, inputFocus, required, disabled, onKeyPress, maxLength, placeHolder,minLength, onFocus }) {
    const { theme } = useStateContext();
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputFocus == true) {
            inputRef.current.focus();
        }
    }, []);
    return (
        <Fragment>
            <input ref={inputRef} type={type} name={name} value={value} id={id} min={min} autoComplete="given-name" className={"block w-full rounded-md border-0 text-sm py-2 shadow-sm ring-1 ring-inset bg-transparent ring-gray-400 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#f94327] sm:leading-6 transition ease-in-out delay-150 duration-300 " + (theme ? 'text-default-color ' : 'text-gray-800 ') + (className)} readOnly={readOnly} required={required} disabled={disabled} onFocus={onFocus} onChange={onChange} onKeyPress={onKeyPress} minLength={minLength} maxLength={maxLength} placeholder={placeHolder} />
        </Fragment>
    )
}

export default Input
