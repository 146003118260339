import React, { Fragment } from 'react'
import { useStateContext } from '../../context/ContextProvider'

function Label({ htmlFor, className, labelText }) {
    const { theme } = useStateContext()
    return (
        <Fragment>
            <label htmlFor={htmlFor} className={(theme ? 'text-default-color' : 'text-gray-800 ') + " block text-sm leading-6 ml-1 tracking-wide mb-1 " + (className)}>{labelText}</label>
        </Fragment>
    )
}

export default Label